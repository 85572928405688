import styled from 'styled-components'
import { mainFontColor, black } from '../Theme/ThemeDefinitions/themeMode'
import { fontSizes } from '../Theme'

export const Icon = styled.img`
    height: 3rem;
    width: 3rem;
`
export const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    gap: 1rem;
    margin: 1.5rem 0;
`
export const LogoText = styled.h3`
    display: flex;
    font-weight: 300;
    line-height: calc(${fontSizes.h5Size} * 1.2);
    font-size: ${fontSizes.h5Size};
    color: ${(props) => (props.darkMode ? black : mainFontColor)};
    margin: 0;
    span {
        font-weight: 700;
    }
`
