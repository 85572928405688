import React from 'react'
import styled from 'styled-components'
import { BadgeLink } from './BadgeLink'

const StoreLinksContainer = styled.div`
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: grid;
        grid-template-columns: 1fr;
    }

    ${(props) =>
        props.direction
            ? {
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  flexDirection: props.direction,
                  flexWrap: 'wrap',
              }
            : {}};

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        display: flex;
        text-align: center;
        justify-content: space-around;
        align-items: center;
    }
`

export const StoreLinks = ({ stores, direction }) => {
    if (stores.length <= 0) return null

    return (
        <StoreLinksContainer direction={direction}>
            {stores.map((store, index) => {
                const storedata = store.entity ?? null

                return (
                    <BadgeLink
                        key={index}
                        direction="ltr"
                        link={storedata.fieldPath}
                        title={storedata.fieldPreButtonText?.value ?? ''}
                        afterTitle={storedata.fieldAfterButtonText?.value ?? ''}
                        badge={storedata.fieldLogo}
                    />
                )
            })}
        </StoreLinksContainer>
    )
}
