import React, { useState, useRef, useEffect } from 'react'
import { OffCanvasContainer } from './OffCanvasContainer'
import { OffCanvasTrigger } from './OffCanvasTrigger'
import { useOnClickOutside } from '../../hooks'
import { useTheme } from '../Theme'

export const OffCanvasMenu = ({
    children,
    iconType,
    menuID,
    direction,
    triggerText,
    preheader,
}) => {
    const [open, setOpen] = useState(false)
    const ref = useRef()
    useOnClickOutside(ref, () => setOpen(false))

    const handleClick = () => {
        setOpen(!open)
    }
    useEffect(() => {
        if (typeof window !== 'undefined' && children !== undefined) {
            window.addEventListener('scroll', () => {
                if (window.outerWidth < 768) {
                    return
                }
                let scrollPos = window.pageYOffset
                if (scrollPos > 50) {
                    setOpen(false)
                }
            })
        }
    }, [setOpen, children])
    const themeContext = useTheme()
    return (
        <>
            {children !== undefined ? (
                <div ref={ref}>
                    <OffCanvasTrigger
                        darkMode={themeContext.mode === 'dark'}
                        labelledby={menuID}
                        triggerText={triggerText}
                        iconType={iconType}
                        handleTriggerClick={handleClick}
                        open={open}
                        preheader={preheader}
                    />
                    <OffCanvasContainer
                        status={open}
                        direction={direction}
                        labelledby={menuID}
                    >
                        {children}
                    </OffCanvasContainer>
                </div>
            ) : (
                ''
            )}
        </>
    )
}
