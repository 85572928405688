import styled from 'styled-components'
import { colors, fontSizes } from '../../Theme'
import { Link } from 'gatsby'
import { FaAngleDown } from 'react-icons/fa'

const { white, mainFontColor, darkBgColor, brightFontColor } = colors
const { smallSize, baseFontSize, minimalSmallerSize } = fontSizes

export const SubmenuContainer = styled.div`
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: ${({ collapse }) => (collapse ? 'block' : 'none')};
        transition: all 0.2s linear;
        padding: 1rem 0;

        > a:not(:last-of-type) > div {
            margin: 0 0 20px 0;
        }
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
        background-color: ${white};
        border-radius: 20px;
        box-shadow: 2px 2px 10px 8px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        opacity: 1;
        padding: 4px 10px 10px 20px;
        position: absolute;
        right: -100%;
        text-transform: none;
        transition: all 0.2s 0.3s linear;
        visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
        z-index: 1;

        &:after {
            top: -20px;
            content: '';
            position: absolute;
            right: 50%;
            right: calc(50% - 27.5px);
            border-inline-start: 20px solid transparent;
            border-inline-end: 20px solid transparent;
            border-bottom: 26px solid ${white};
        }

        @supports (clip-path: inset(50%)) {
            &:after {
                top: -17.5px;
                border: none;
                display: block;
                height: 35px;
                width: 35px;
                background-color: inherit;
                clip-path: polygon(0% 0%, 100% 100%, 0% 100%);

                transform: scaleX(0.6) rotate(135deg);
                border-radius: 0 0 0 0.5em;
            }
            &:before {
                content: '';
                position: absolute;
                height: 50px;
                width: 60px;
                right: calc(50% - 40px);
                top: -5px;
                border-radius: 50%;
                background-color: inherit;
            }
        }

        &:hover {
            visibility: visible;
        }
    }

    @media (min-width: ${({ theme }) => theme.destopSmall}) {
        right: 0;

        &:after {
            right: 17.5px;
        }

        @supports (clip-path: inset(50%)) {
            &:after {
            }

            &:before {
                right: 5.5px;
            }
        }
    }
`

export const SubmenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        padding: 0;
        padding-right: 10px;
        width: 340px;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        padding: 0 15px;
    }
`

export const SubmenuLink = styled(Link)`
    text-decoration: none;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-top: 10px;
        &:not(:last-child) {
            border-bottom: 1px solid ${mainFontColor};
            padding-bottom: 10px;
        }
    }
`

export const SubmenuText = styled.div`
    margin-bottom: 0;
    color: ${mainFontColor};

    @media (min-width: ${({ theme }) => theme.tablet}) {
        width: 80%;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        /* padding: 20px 0; */
    }
`

export const SubmenuHeadline = styled.div`
    font-size: ${baseFontSize};
    line-height: 1.25rem;
    font-weight: 400;
    margin-top: 0;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${mainFontColor};
        line-height: 1.625rem;
    }
`
export const SubmenuDescription = styled.div`
    display: none;
    font-size: ${smallSize};
    font-weight: 300;
    line-height: 1.75em;
    margin-top: 0;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: block;
    }
`
export const SubmenuIconContainer = styled.div`
    align-items: center;
    display: flex;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin-inline-start: 10px;
    }
`
export const SubmenuIcon = styled.img`
    max-width: 32px;
`

export const SubmenuTriggerContainer = styled.div`
    display: inline;
    position: relative;
`

export const SubmenuTrigger = styled.span`
    cursor: pointer;
    display: block;
    font-size: ${minimalSmallerSize};
    font-weight: 400;
    padding: 15px;
    text-transform: uppercase;
    color: ${(props) => (props.darkMode ? 'white' : '')};

    @media (min-width: ${({ theme }) => theme.tablet}) {
        white-space: nowrap;
        color: ${(props) => (props.darkMode ? 'white' : mainFontColor)};
        display: inline-block;
        padding: 20px;
        &:hover + ${SubmenuContainer} {
            opacity: 1;
        }
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        background-color: ${({ theme }) => theme.black};
        border-bottom: 1px solid ${darkBgColor};
        display: flex;
        justify-content: space-between;
    }
`

export const SubmenuTriggerIcon = styled(FaAngleDown)`
    position: relative;
    top: 2px;
    left: 2px;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        color: ${brightFontColor};
        transform: ${({ collapse }) =>
            collapse ? 'rotate(-180deg)' : 'rotate(0)'};
        transition: all 0.2s linear;
    }
`
