export {
    Apps,
    Contact,
    Downloads,
    LanguagesAndDialects,
    Partner,
    Safety,
    Start,
    WhoWeAre,
} from './Home'
export {
    AnamnesisStartNew,
    AppTargetGroups,
    ClinicalStudiesNew,
} from './Anamnesis'
export {
    RescueApp,
    RescueFeatures,
    RescueAppInfo,
    RescueAppInsurances,
    RescueAppText,
    RescueAppVideo,
    RescueAppBenefits,
} from './Rescue'
export { ImpressSection } from './Impress'
export { LegalWithDownloads } from './LegalWithDownloads'
export { CancellationSection } from './Cancellation'
export {
    RescueCovid19Start,
    RescueCovid19Download,
    RescueCovid19HelpingHands,
} from './RescueCovid19'
export * from './Vaccinate'
export { HediStart, HediCommunication, HediBenefits, HediContact } from './Hedi'
