import styled from 'styled-components'
import { colors } from '../../../Theme'
import { SectionTitle, ContentImageDesktopOnly } from '../../../index'

const { white } = colors

export const Title = styled(SectionTitle)`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${white};
    }
`

export const ImageDesktopOnly = styled(ContentImageDesktopOnly)`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-top: -4rem;
    }
`
