import styled from 'styled-components'
import { fontSizes, colors } from '../../../Theme'
const { h3Size, h5Size } = fontSizes
const { mainFontColor } = colors
export const SubHeadline = styled.p`
    color: ${mainFontColor};
    font-size: ${h5Size};
    font-weight: 700;
    line-height: 1.4em;
    margin: 0 0 3rem;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h3Size};
        margin: ${(props) => props.margin || '4rem 0'};
    }
`
