import styled from 'styled-components'
import { colors, fontSizes } from '../Theme'

const {
    darkBlueishBgColor,
    white,
    primaryColor,
    brightFontColor,
    hediBlueBgColor,
} = colors
const { h3Size, h4Size } = fontSizes

export const MobileHeaderContainer = styled.div`
    display: flex;
    background-color: ${(props) =>
        props.primary
            ? primaryColor
            : props.isHedi
            ? hediBlueBgColor
            : darkBlueishBgColor};
    color: ${(props) => (props.darkMode ? brightFontColor : white)};
    font-weight: inherit;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 1px;
    padding: 5vw;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: none;
    }

    h2 {
        line-height: 1.3em;
        margin: 0;
        font-size: ${(props) => props.textToUppercase && '1rem'};
        text-transform: ${(props) => props.textToUppercase && 'uppercase'};
        @media (min-width: ${({ theme }) => theme.mobileSmall}) {
            font-size: ${(props) => props.textToUppercase && '5vw'};
        }

        * {
            line-height: 1.375em;
        }

        h1,
        h2 {
            font-size: ${h4Size};
        }
    }
`

export const MobileHeaderNumber = styled.span`
    color: ${(props) =>
        props.primary || props.isHedi
            ? white
            : (props.darkMode && colors.brightFontColor) || white};
    font-size: ${h3Size};
    font-weight: 700;
`
