import styled from 'styled-components'

export const CardsGridContainer = styled.div`
    display: grid;
    margin-top: 220px;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    text-align: left;
`
