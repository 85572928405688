import React from 'react'
import {
    PreheaderContainer,
    PreheaderNavBar,
    PreheaderNavbarItem,
} from './Preheader.styled'
import { LanguageSwitchMenu } from '../LanguageSwitchMenu'
import { AccessibilityMenu } from '../AccessibilityMenu'

export const Preheader = ({ pathname, translations, pageContext }) => {
    return (
        <PreheaderContainer>
            <PreheaderNavBar>
                <PreheaderNavbarItem>
                    {pageContext?.langSwitch === true ? (
                        <LanguageSwitchMenu
                            pathname={pathname}
                            translations={translations}
                            pageContext={pageContext}
                        />
                    ) : (
                        ''
                    )}
                </PreheaderNavbarItem>
                <PreheaderNavbarItem>
                    <AccessibilityMenu
                        translations={translations}
                        pageContext={pageContext}
                    />
                </PreheaderNavbarItem>
            </PreheaderNavBar>
        </PreheaderContainer>
    )
}
