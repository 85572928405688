import styled from 'styled-components'
import { colors, fontSizes } from '../Theme'
import { Link } from 'gatsby'

const { white, black, brightMenuLinkColor, mainFontColor, darkBgColor } = colors
const { minimalSmallerSize } = fontSizes

export const StickyHeader = styled.div`
    background-color: ${white};
    border: 1px none ${black};
    left: 0px;
    object-fit: none;
    opacity: 1;
    overflow: visible;
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 2000;
`

export const NavInner = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0px 5vw;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        padding: 1rem;
    }
`

export const NavMenuWrap = styled.div`
    @media (max-width: ${({ theme }) => theme.tablet}) {
        background-color: ${white};
        color: ${(props) => (props.darkMode ? 'black' : brightMenuLinkColor)};
        left: 0;
        position: absolute;
        top: 4rem;
        height: calc(100vh - 4rem);
        overflow-y: scroll;
        transform: ${({ open }) =>
            open ? 'translateY(0)' : 'translateY(-150%)'};
        transition: all 0.3s linear;
        width: 100%;
        a {
            color: ${(props) =>
                props.darkMode ? 'black' : brightMenuLinkColor};
        }
    }
`

export const Nav = styled.nav`
    display: flex;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        flex-direction: column;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: center;
        > *:last-child {
            padding-right: 0;
        }
    }
    @media (max-width: ${({ theme }) => theme.desktopXS}) {
        flex-wrap: wrap;
        justify-content: flex-end;
    }
`

export const NavLink = styled(Link)`
    color: ${(props) => (props.$darkMode ? 'black' : mainFontColor)};
    cursor: pointer;
    font-size: ${minimalSmallerSize};
    font-weight: 400;
    padding: 15px;
    text-decoration: none;
    text-transform: uppercase;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${(props) => (props.$darkMode ? 'white' : mainFontColor)};
        padding: 20px;
        &:last-child {
            padding: 20px 0 20px 20px;
        }
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        background-color: ${black};
        border-bottom: 1px solid ${darkBgColor};
    }
`
export const ExternalNavLink = styled.a`
    color: ${(props) => (props.$darkMode ? 'black' : mainFontColor)};
    cursor: pointer;
    font-size: ${minimalSmallerSize};
    font-weight: 400;
    padding: 15px;
    text-decoration: none;
    text-transform: uppercase;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${(props) => (props.$darkMode ? 'white' : mainFontColor)};
        padding: 20px;
        &:last-child {
            padding: 20px 0 20px 20px;
        }
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        background-color: ${black};
        border-bottom: 1px solid ${darkBgColor};
    }
`
