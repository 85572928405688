import React from 'react'
import styled from 'styled-components'
import { colors } from '../Theme'
const { brightMenuLinkColor } = colors
const Container = styled.div`
    margin-top: 2rem;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-top: 4rem;
    }
`
const Text = styled.p`
    font-size: 0.7rem;
    margin: 0;
    position: relative;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: 0.8rem;
        margin: 0;
    }
`
const Seperator = styled.hr`
    color: ${brightMenuLinkColor};
    size: 0.5px;
    width: 50%;
    text-align: left;
    display: inline-block;
`

export const LegalTexts = ({ texts }) => {
    if (texts.length <= 0) return null
    return (
        <Container>
            <Seperator />
            {texts.map((text, index) => (
                <Text key={index}>{text}</Text>
            ))}
        </Container>
    )
}
