import React from 'react'
import fetch from 'isomorphic-fetch'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

export const client = new ApolloClient({
    uri: process.env.GATSBY_USE_CUSTOMER_PORTAL
        ? `http://localhost:9998/graphql`
        : `https://portal.aidminutes.com/graphql`, //THIS IS THE URL OF THR GRAPHQL WE WANTO TO QUERY
    //uri: `https:///graphql`, //THIS IS THE URL OF THR GRAPHQL WE WANTO TO QUERY
    fetch: fetch,

    cache: new InMemoryCache(),
})

export const wrapRootElement = ({ element }) => (
    <ApolloProvider client={client}>{element}</ApolloProvider>
)
