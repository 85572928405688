import React from 'react'
import styled from 'styled-components'
import { PageImage } from '../../../basic'
import map from '../../../../images/aidminutes_map.png'
import mapdark from '../../../../images/aidminutes_map_dark.png'
import { useTheme } from '../../../Theme'

const MapContainer = styled.div`
    display: flex;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    @media (min-width: ${({ theme }) => theme.desktopXXL}) {
        height: min-content;
    }
`

const MapImage = styled(PageImage)`
    max-height: 100vh;
    height: 100%;
    width: auto;
    max-width: unset;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        max-height: initial;
    }
    @media (min-width: ${({ theme }) => theme.desktopXXL}) {
        width: 100%;
    }
`

export const StaticMap = () => {
    const themeContext = useTheme()
    return (
        <MapContainer dark={themeContext.mode === 'dark'}>
            <MapImage
                src={themeContext.mode === 'dark' ? mapdark : map}
                alt="Karte mit Standorten der aidminutes Geschäftsräume"
            />
        </MapContainer>
    )
}
