import styled from 'styled-components'
import { colors, fontSizes } from '../../../Theme'

const { mainFontColor, darkFontColorOnLightBackground, white, darkFontColor } =
    colors
const { smallSize } = fontSizes

export const FeatureGridLeft = styled.div`
    width: 100%;
    padding: 0;
    margin: 0;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        width: 65%;
        padding: 0 10vw 0 5vw;
        margin: 15vh 0 5vh;
    }
`

export const FeatureGridRight = styled.div`
    display: none;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        height: 100%;
        width: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        flex-direction: column;
        gap: 2rem;
    }
`
export const FeatureColumns = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`
export const FeaturesList = styled.div`
    background-color: ${white};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        background-color: inherit;
    }
`
export const Feature = styled.div`
    padding: 32px 16px;
    margin: 0;
    border-bottom: 2px dotted ${mainFontColor};
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        padding: 0;
        margin-bottom: 32px;
        border-bottom: initial;
    }
`
export const FeatureImage = styled.div`
    background: url(${(props) => props.image}) no-repeat center;
    background-size: contain;
    flex-shrink: 0;
    height: 72px;
    width: 72px;
    margin-right: 18px;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        height: 65px;
        width: 65px;
        margin-right: 24px;
    }
`
export const AllFeaturesBgImage = styled.div`
    background: url(${(props) => props.image}) no-repeat;
    background-position: ${(props) => props.position};
    background-size: contain;
    width: 100%;
    flex: 1;
`
export const FeatureText = styled.div``
export const FeatureHeadline = styled.h2`
    margin: 8px 0;
    font-size: 1rem;
    line-height: normal;
    color: ${darkFontColor};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${mainFontColor};
        font-size: revert;
        margin: 3px 0;
    }
`
export const FeatureParagraph = styled.p`
    margin: 0;
    font-size: ${smallSize};
    color: ${darkFontColor};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: revert;
        color: ${darkFontColorOnLightBackground};
    }
`
export const LanguageInfo = styled.p`
    margin: 0;
    font-size: ${smallSize};
    padding: 32px 16px 16px 16px;
    color: ${darkFontColor};
    background-color: ${white};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        padding: initial;
        color: ${darkFontColorOnLightBackground};
        background-color: inherit;
    }
`
