import React from 'react'
import {
    HoneyCombContainer,
    HoneyCombCenter,
    HoneyCombLeft,
    HoneyCombRight,
} from './HoneyComb.styled'
import { HoneyCompGridConfig } from './honeyComb.config'

export const HoneyComb = ({ id, children }) => {
    const aidminutesComb = id === 99 ? true : false
    const currentHoneyComb = HoneyCompGridConfig.filter(
        (config) => config.id === id
    )
    const combObject = currentHoneyComb[0]

    if (aidminutesComb) {
        return (
            <HoneyCombContainer hexadata={combObject}>
                {children}
                <HoneyCombLeft />
                <HoneyCombCenter />
                <HoneyCombRight />
            </HoneyCombContainer>
        )
    }
    return (
        <HoneyCombContainer hexadata={combObject} id={`honeycomb-${id}`}>
            {children}
            <HoneyCombLeft />
            <HoneyCombCenter />
            <HoneyCombRight />
        </HoneyCombContainer>
    )
}
