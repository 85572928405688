import React from 'react'
import { Layout, Seo, ImpressSection } from '../../components'
import {
    getPageData,
    getTopmenuData,
    getSubmenuData,
    useFooterData,
} from '../../utils'
import seo_home_de_fb from './../../images/seo_home_de_fb.png'
import seo_home_en_fb from './../../images/seo_home_en_fb.png'

export const Impress = ({ data, location, langCode, pageContext }) => {
    const prefix = pageContext?.prefix ?? ''
    const impressdata = getPageData(data, 'impressQuery')
    const topmenu = getTopmenuData(data)
    const submenu = getSubmenuData(data)
    const { footerdata } = useFooterData(
        data,
        'footerQuery',
        'entities',
        prefix
    )
    // TODO
    const translations = data?.allTranslationsJson ?? ''
    const seoTitle =
        langCode === 'DE'
            ? 'Impressum' ?? pageContext?.seoTitle
            : 'Legal Notice' ?? pageContext?.seoTitle
    const seoDescription = langCode === 'DE' ? 'Impressum' : 'Legal Notice'
    const seoImage =
        langCode === 'DE' ? `${seo_home_de_fb}` : `${seo_home_en_fb}`
    const seoImageAlt = pageContext?.seoTitle
    const seoType = 'website'
    const seoUri =
        langCode === 'DE'
            ? 'https://www.aidminutes.com/impressum'
            : 'https://www.aidminutes.com/en/impressum'

    return (
        <Layout
            path={location.pathname}
            location={location}
            langCode={langCode}
            translations={translations}
            pageContext={pageContext}
            topmenu={topmenu}
            submenu={submenu}
            footerdata={footerdata}
        >
            <Seo
                lang={langCode}
                title={seoTitle}
                type={seoType}
                url={seoUri}
                image={seoImage}
                alt={seoImageAlt}
                description={seoDescription}
            />
            <ImpressSection data={impressdata} />
        </Layout>
    )
}
