import styled from 'styled-components'

export const BackgroundVideoPlayer = styled.video`
    height: auto;
    position: relative;
    top: 0px;
    width: ${(props) => props.hasFixedWidth && '100%'};
    z-index: ${(props) => (props.zIndex ? '-1' : '0')};

    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: ${(props) => (props.displayMobile ? 'block' : 'none')};
    }

    /* @media (min-aspect-ratio: 16/9) {
        height: 150%;
        top: -100%;
    } */

    /* @media (max-aspect-ratio: 16/9) {
        width: 150%;
        left: -100%;
    } */
`

/**
 * @deprecated use `VideoContainer` instead.
 * Grund: The Background Video is not the Background of the whole section anymores
 */
export const BackgroundVideoContentContainer = styled.div`
    display: flex;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: block;
        position: relative;
    }
`

export const BackgroundVideoTransparentOverlay = styled.div`
    position: ${(props) => props.overlayPosition};
    background: ${(props) =>
        props.gradientBackground
            ? 'linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 0.1) 90%, rgb(255, 255, 255) 100%)'
            : 'transparent'};
    z-index: ${(props) => props.darkMode && '0'};
    background: ${(props) => props.darkMode && 'transparent'};

    /*
        ${(props) => {
        if (props.darkMode) {
            return `
                background: purple;
            `
        } else {
            return `
            background: linear-gradient(
                180deg,
                rgba(2, 0, 36, 0) 0%,
                rgba(255, 255, 255, 0.1) ${(props) =>
                    props.gradientStart || '65%'},
                rgb(255, 255, 255) 100%
            );
            `
        }
    }}
    */
`
