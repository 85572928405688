import React, { createContext, useState, useEffect } from 'react'

const initialState = 'de'

export const PageContext = createContext(initialState)

export const PageProvider = ({ children, lang }) => {
    const [langCode, setLangCode] = useState(lang)

    useEffect(() => {
        if (lang) setLangCode(lang)
    }, [lang])

    return (
        <PageContext.Provider value={{ langCode, setLangCode }}>
            {children}
        </PageContext.Provider>
    )
}
