import React from 'react'
import {
    Layout,
    Seo,
    RescueCovid19Start,
    RescueCovid19Download,
    RescueCovid19HelpingHands,
} from '../../components'
import { getPageData, getSubmenuData, getTopmenuData } from '../../utils'

export const RescueCovid19 = ({ data, location, langCode, pageContext }) => {
    const rescueProductMenuData = getPageData(data, 'productMenuQuery')
    const rescueCovid19Downloadsdata = getPageData(data, 'covidDownloadsQuery')
    const rescueCovid19StartData = getPageData(data, 'covidStartQuery')
    const rescueCovid19HelpingHandsData = getPageData(
        data,
        'covidHelpingHandsQuery'
    )
    const footerdata = getPageData(data, 'footerQuery')
    const topmenu = getTopmenuData(data)
    const submenu = getSubmenuData(data)
    // TODO drupal
    const translations = data?.allTranslationsJson ?? ''
    const seoTitle = pageContext?.seoTitle ?? 'Rescue COVID-19'

    return (
        <Layout
            path={location.pathname}
            langCode={langCode}
            productmenuData={rescueProductMenuData}
            translations={translations}
            pageContext={pageContext}
            topmenu={topmenu}
            submenu={submenu}
            footerdata={footerdata}
        >
            <Seo title={seoTitle} lang={langCode} />
            <RescueCovid19Start
                data={rescueCovid19StartData}
                pageContext={pageContext}
            />
            <RescueCovid19Download
                data={rescueCovid19Downloadsdata}
                translations={translations}
            />
            <RescueCovid19HelpingHands data={rescueCovid19HelpingHandsData} />
        </Layout>
    )
}
