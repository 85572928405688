import styled from 'styled-components'

export const VideoWrap = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    margin-top: 0.75em;
`

export const VideoIFrame = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`
