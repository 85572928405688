exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-cancellation-policy-js": () => import("./../../../src/templates/cancellation-policy.js" /* webpackChunkName: "component---src-templates-cancellation-policy-js" */),
  "component---src-templates-hedi-js": () => import("./../../../src/templates/hedi.js" /* webpackChunkName: "component---src-templates-hedi-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-imprint-js": () => import("./../../../src/templates/imprint.js" /* webpackChunkName: "component---src-templates-imprint-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-product-anamnesis-js": () => import("./../../../src/templates/product-anamnesis.js" /* webpackChunkName: "component---src-templates-product-anamnesis-js" */),
  "component---src-templates-product-rescue-js": () => import("./../../../src/templates/product-rescue.js" /* webpackChunkName: "component---src-templates-product-rescue-js" */),
  "component---src-templates-redirect-js": () => import("./../../../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */),
  "component---src-templates-rescue-covid-19-js": () => import("./../../../src/templates/rescue-covid19.js" /* webpackChunkName: "component---src-templates-rescue-covid-19-js" */),
  "component---src-templates-tac-js": () => import("./../../../src/templates/tac.js" /* webpackChunkName: "component---src-templates-tac-js" */)
}

