import React from 'react'
import {
    Centerblock,
    PageNumber,
    HorizontalPageText,
    PaginationStrokeContainer,
    PaginationStroke,
} from './SitePagination.styled'

export const SitePagination = ({
    darkMode,
    pageNumber,
    pageText,
    fontColor,
    breakpoint,
    textTransform,
    isNotDisplayed,
}) => {
    return (
        <Centerblock
            darkMode={darkMode}
            className="direction-dependent-padding__centerblock"
            fontColor={fontColor}
            breakpoint={breakpoint}
        >
            <PageNumber>{pageNumber}</PageNumber>
            <HorizontalPageText textTransform={textTransform}>
                <PaginationStrokeContainer>
                    <PaginationStroke
                        fontColor={fontColor}
                        darkMode={darkMode}
                    />
                </PaginationStrokeContainer>
                <span className="language-dependent-font">{pageText}</span>
            </HorizontalPageText>
        </Centerblock>
    )
}
