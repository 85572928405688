import React from 'react'
import { getDrupalData } from '../../utils'
import { VideoWrap, VideoIFrame } from './VimeoEmbed.styled'

export const VimeoEmbed = ({ data }) => {
    const { vimeoVideoID } = getDrupalData(data)

    const createVimeoEmbedURL = (videoID) => {
        return `https://player.vimeo.com/video/${videoID}?byline=0`
    }

    return vimeoVideoID ? (
        <>
            <VideoWrap>
                <VideoIFrame
                    src={createVimeoEmbedURL(vimeoVideoID)}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                ></VideoIFrame>
            </VideoWrap>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </>
    ) : (
        <></>
    )
}
