import styled from 'styled-components'
import { fontSizes, colors } from '../../../Theme'

const { h5Size, baseFontSize } = fontSizes
const { darkestFontColor, semiTransparentBlack, white } = colors

export const TextContainer = styled.div`
    word-break: break-word;
    flex-direction: column;
    height: fit-content;
    padding: 5vw;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: flex;
        align-items: flex-start;
        margin: 0vh 0 15vh;
        padding: 10vh 1rem 3rem;
    }
`
export const VideoContainer = styled.div`
    flex-direction: column;
    height: fit-content;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: flex-start;
        display: flex;
        height: auto;
    }
`
export const Description = styled.p`
    font-size: 1rem;
    line-height: 2rem;
    color: ${darkestFontColor};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${(props) => (props.darkMode ? white : darkestFontColor)};
        a {
            color: ${(props) => (props.darkMode ? white : darkestFontColor)};
        }
    }
    @media (min-width: ${({ theme }) => theme.desktop}) {
        max-width: 80%;
    }
`
export const PurchaseInfoBox = styled.div`
    margin: 2rem 0 0;
    color: ${semiTransparentBlack};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: 1rem 0 0;
        color: ${(props) => (props.darkMode ? white : semiTransparentBlack)};
    }
`
export const InfoBox = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
`
export const Icon = styled.img`
    height: 3rem;
    width: 3rem;
`
export const PurchaseBoxDescription = styled.p`
    color: ${(props) => (props.color ? props.color : 'inherit')};
    font-weight: ${(props) => (props.isBolder ? 700 : 400)};
    line-height: 1.625rem;
    font-size: ${(props) => (props.isBolder ? h5Size : baseFontSize)};
    letter-spacing: 0.5px;
    text-transform: ${(props) => props.isBolder && 'uppercase'};
    margin: 0;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: initial;
    }
`
export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 2rem 0;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: ${(props) => props.margin};
    }
`
