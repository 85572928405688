import React from 'react'
import { Layout, Seo } from '../../components'
import { getSubmenuData, getTopmenuData, useFooterData } from '../../utils'
import {
    HediStart,
    HediCommunication,
    HediBenefits,
    HediContact,
} from '../../components'
import seo_home_de_fb from './../../images/seo_home_de_fb.png'
import seo_home_en_fb from './../../images/seo_home_en_fb.png'
import { getPageData } from '../../utils'

export const HediPage = ({ data, location, langCode, pageContext }) => {
    const prefix = pageContext?.prefix ?? ''

    const { footerdata } = useFooterData(
        data,
        'footerQuery',
        'entities',
        prefix
    )
    const mobileBannerData = data.drupal.mobileBannerQuery.mobileBanner[1]
    const topmenu = getTopmenuData(data)
    const submenu = getSubmenuData(data)
    const translations = data?.allTranslationsJson ?? ''

    const hediStartData = getPageData(data, 'hediSection01Query')
    const HediCommunicationData = getPageData(data, 'hediSection02Query')
    const HediBenefitsData = getPageData(data, 'hediSection03Query')
    const HediContactData = getPageData(data, 'hediSection04Query')
    const hediProductMenuData = getPageData(data, 'productMenuQuery')

    // TODO drupal
    const seoTitle = pageContext?.seoTitle ?? 'HEDI'
    const seoDescription =
        langCode === 'DE'
            ? 'Die mehrsprachige Plattform zur digitalen Unterstützung der Schwangerenversorgung.'
            : 'The multilingual platform for digital support of antenatal care.' // REPLACE ME with official content when ready
    const seoImage =
        langCode === 'DE' ? `${seo_home_de_fb}` : `${seo_home_en_fb}`
    const seoImageAlt = pageContext?.seoTitle
    const seoType = 'website'
    const seoUri = 'https://www.aidminutes.com/hedi'

    return (
        <>
            <Layout
                path={location.pathname}
                langCode={langCode}
                productmenuData={hediProductMenuData}
                translations={translations}
                pageContext={pageContext}
                topmenu={topmenu}
                submenu={submenu}
                footerdata={footerdata}
                mobileBannerData={mobileBannerData}
            >
                <Seo
                    lang={langCode}
                    title={seoTitle}
                    type={seoType}
                    url={seoUri}
                    image={seoImage}
                    alt={seoImageAlt}
                    description={seoDescription}
                />
                <HediStart
                    data={hediStartData}
                    langCode={langCode}
                    translations={translations}
                />
                <HediCommunication
                    data={HediCommunicationData}
                    langCode={langCode}
                    translations={translations}
                />
                <HediBenefits
                    data={HediBenefitsData}
                    langCode={langCode}
                    translations={translations}
                />
                <HediContact
                    data={HediContactData}
                    langCode={langCode}
                    translations={translations}
                />
            </Layout>
        </>
    )
}
