import React from 'react'
import { Wysiwyg } from '../../../../basic'
import {
    SafetyItemTitle,
    SafetyItemHeadlineContainer,
    SafetyItemContainer,
} from './SafetyItem.styled'
import { getDrupalData } from '../../../../../utils'
export const SafetyItem = ({ data }) => {
    const { headline, text } = getDrupalData(data)

    return (
        <SafetyItemContainer>
            <SafetyItemHeadlineContainer>
                <SafetyItemTitle>{headline}</SafetyItemTitle>
            </SafetyItemHeadlineContainer>
            <Wysiwyg text={text} />
        </SafetyItemContainer>
    )
}
