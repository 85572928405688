const getPath = (data) => {
    return data?.fieldPath ?? ''
}
const getText = (data) => {
    return data?.fieldText ?? ''
}

export const moreinfoData = (data) => {
    const text = getText(data)
    const path = getPath(data)

    const obj = {
        text: text,
        path: path,
    }

    return obj
}
