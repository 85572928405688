import React from 'react'
import {
    SectionTitleAnamnesis,
    SectionBackgroundOnBreakpoint,
} from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import {
    TextContainer,
    ImageContainer,
    Image,
    Description,
    Grid,
    Header,
    ButtonWrap,
    StudyInfoList,
    StudyInfoListedItem,
    StudyInfoWrapper,
} from './ClinicalStudiesNew.styled'
import { MobileHeadline } from '../../../MobileHeadline'
import { DownloadButton } from '../../../DownloadButton'
import broschuere_kliniken from '/static/anamnesis_broschuere_krankenhaeuser.pdf'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '../../../Theme'
import { colors } from '../../../Theme/ThemeDefinitions/themeMode'
import { aidminutesColors } from '../../../Theme/ThemeDefinitions/aidminutesStyle'

const hcPaginationNumber = '03'
const hcPaginationText = 'Klinische Studien'
const hcSectionTitle = 'Wissenschaftliche Evaluation'
const hcReference = 'DRKS00013076 // DRKS00026659'
const studies = [
    {
        title: 'DASI-Studie',
        date: ' (2020 – 2022):',
        reference: 'DRKS00013076',
        description:
            'DASI – „Digital assistierte Anamnese vor der Sprechstunde“ \nFörderkennzeichen 01VSF19050',
        info: [
            'Gefördert vom Gemeinsamen Bundesausschuss (G-BA)',
            'Mehrstufige Kontroll- und Interventionsstudie',
            'Ort: Bereitschaftpraxen der Kassenärztlichen Vereinigung Niedersachsen',
        ],
    },
    {
        title: 'Friedland-Studie',
        date: ' (2017 – 2019):',
        reference: 'DRKS00026659',
        info: [
            'Weltweit erste Studie zur multilingualen digitalen Anamneseerfassung',
            'Ort: Grenzdurchgangslager Friedland',
            'Anamnese in 13 Sprachen',
            'Alter der Proband:innen 1 bis 74 Jahre',
            'Ergebnis: Bessere Vermittlung von Konsultationsanlässen und Krankengeschichte',
        ],
    },
]

const MobileGradientBackground =
    'radial-gradient(90.1% 284.77% at 15.89% 29.44%, rgba(254, 254, 255, 0.392) 0%, rgba(223, 229, 237, 0.348831) 9.57%, rgba(86, 104, 126, 0.197562) 47.38%, rgba(73, 89, 109, 0.157482) 59.8%, rgba(160, 192, 235, 0.0644889) 94.19%, rgba(64, 91, 126, 0) 100%)'

export const ClinicalStudiesNew = () => {
    const buttonDataDownloadFile = {
        src: broschuere_kliniken,
        description: 'aidminutes.anamnesis',
        text: 'Broschüre Kliniken (pdf)',
        color: 'transparent',
        type: 'instructions',
    }

    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    const themeContext = useTheme()

    return (
        <SectionBackgroundOnBreakpoint
            backgroundMobile={MobileGradientBackground}
            backgroundDesktop="#DFE2E6"
            id="anamnesis-clinical-studies-new"
        >
            <MobileHeadline
                paginationNumber={hcPaginationNumber}
                text={hcSectionTitle}
                primary
                textToUppercase
            />
            <SitePagination
                pageNumber={hcPaginationNumber}
                pageText={hcPaginationText}
                textTransform="capitalize"
                darkMode={themeContext.mode === 'dark'}
            />
            <Grid>
                <TextContainer
                    className="not-in-view"
                    ref={ref}
                    darkMode={themeContext.mode === 'dark'}
                >
                    <Header>
                        <SectionTitleAnamnesis
                            fontColorMobile={
                                themeContext.mode === 'dark'
                                    ? colors.black
                                    : aidminutesColors.aidminutesDarker
                            }
                            fontColorDesktop={
                                themeContext.mode === 'dark'
                                    ? colors.white
                                    : aidminutesColors.aidminutesDarker
                            }
                            textTransform="uppercase"
                            marginTop="0.44em"
                            marginBottom="0.5rem"
                        >
                            {hcSectionTitle}
                        </SectionTitleAnamnesis>
                        <Description>{hcReference}</Description>
                    </Header>
                    <Description margin="2.5rem 0 0">
                        <b>aid</b>minutes-Produkte werden in enger Kooperation
                        mit medizinischen Expert:innen entwickelt. Gemeinsam mit
                        der Universitätsmedizin Göttingen evaluieren wir im
                        Rahmen klinischer Studien die Effizienz und
                        Nutzer-Perzeption.
                    </Description>
                    {studies.map((study, index) => {
                        return (
                            <StudyInfoWrapper
                                key={index}
                                darkMode={themeContext.mode === 'dark'}
                            >
                                <Description margin="0">
                                    <span>{study.title}</span>
                                    {study.date}
                                </Description>
                                <Description margin="0">
                                    {study.description}
                                </Description>
                                <StudyInfoList>
                                    {study.info.map((info, index) => {
                                        return (
                                            <StudyInfoListedItem key={index}>
                                                {info}
                                            </StudyInfoListedItem>
                                        )
                                    })}
                                </StudyInfoList>
                            </StudyInfoWrapper>
                        )
                    })}
                </TextContainer>
                <ImageContainer>
                    <Image />
                    <ButtonWrap className="centered">
                        <DownloadButton
                            data={buttonDataDownloadFile}
                            maxWidth="85%"
                            textColorMobile={colors.darkerFontColor}
                            borderColorMobile={colors.darkestFontColor}
                            textColorDesktop={
                                themeContext.mode === 'dark'
                                    ? colors.white
                                    : colors.darkerFontColor
                            }
                            borderColorDesktop={
                                themeContext.mode === 'dark'
                                    ? colors.white
                                    : colors.darkestFontColor
                            }
                        />
                    </ButtonWrap>
                </ImageContainer>
            </Grid>
        </SectionBackgroundOnBreakpoint>
    )
}
