import React from 'react'
import { FeatureWrap, SectionBackgroundDesktopOnly } from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import {
    ContentWrap,
    RescueCovid19H1,
    AdditionalText,
} from './RescueCovid19Start.styled'
import { MobileHeadline } from '../../../MobileHeadline'
import bgImage from '../../../../images/bg_rescue_covid19_start.jpg'
import { getDrupalData } from '../../../../utils'
import { Breakpoint } from 'react-socks'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '../../../Theme'
import { VimeoEmbed } from '../../../VimeoEmbed'

export const RescueCovid19Start = ({ data, pageContext }) => {
    const {
        paginationNumber,
        paginationText,
        mainHeadline,
        preHeadline,
        subHeadline,
    } = getDrupalData(data)
    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }
    const themeContext = useTheme()
    return (
        <SectionBackgroundDesktopOnly
            id="emergency-communication"
            background={`url(${bgImage}) no-repeat`}
            backgroundSize="cover"
            backgroundPostionY="center"
            backgroundPostionX="center"
        >
            <MobileHeadline
                h1
                paginationNumber={paginationNumber}
                text={mainHeadline}
            />
            <SitePagination
                darkMode={themeContext.mode === 'dark'}
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <Breakpoint customQuery="(max-width: 800px)">
                <VimeoEmbed data={data} />
            </Breakpoint>
            <FeatureWrap
                justify="center"
                justifyMobile="center"
                margin="7vh 0 5vh"
                className="not-in-view"
                ref={ref}
            >
                <ContentWrap>
                    <AdditionalText fontWeight="300">
                        {preHeadline}
                    </AdditionalText>
                    <Breakpoint
                        style={{ position: 'relative', width: '100%' }}
                        customQuery="(min-width: 801px)"
                    >
                        <VimeoEmbed data={data} />
                    </Breakpoint>

                    <RescueCovid19H1
                        className="language-dependent-font"
                        displayMobile="none"
                    >
                        {mainHeadline}
                    </RescueCovid19H1>
                    <AdditionalText
                        className="language-dependent-font"
                        direction={pageContext.direction}
                        fontSize="2.571rem"
                    >
                        {subHeadline}
                    </AdditionalText>
                </ContentWrap>
            </FeatureWrap>
        </SectionBackgroundDesktopOnly>
    )
}
