import React, { useState } from 'react'
import {
    getDrupalData,
    getTranslations,
    softwareDownloadData,
    downloadButtonData,
} from '../../../../utils'
import {
    MobileHeadline,
    Section,
    Wysiwyg,
    AsideDownload,
    SitePagination,
    ConditionalDownload,
    Modal,
    BigSubHeadlineParagraph,
    DownloadButton,
} from '../../../index'
import {
    Grid,
    Title,
    AsideContainer,
    ContentContainer,
} from './VaccinateDownload.styled'
export const VaccinateDownload = ({ data, translations }) => {
    const { langCode, androidlegal, alternativeDownload } =
        getTranslations(translations)
    const storeLinks = data?.fieldStoreLink ?? ''

    const [visible, setVisible] = useState(false)

    const handleClose = () => {
        setVisible(false)
    }

    const {
        mainHeadline,
        subHeadline,
        text,
        paginationNumber,
        paginationText,
        addHeadline,
        addInfo,
    } = getDrupalData(data)
    // TODO
    const androidData = softwareDownloadData(
        data.fieldSoftwareDownloads[0].entity
    )
    androidData.deviceId = 'apk'

    const buttondata = downloadButtonData(data.fieldDownloadButton.entity)

    return (
        <Section id="vaccinate-awareness">
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={paginationText}
                primary
            />
            <SitePagination
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <Grid columns="70% 25%" gap="5%" margin="22vh 0 10vh">
                <Title textTransform="uppercase" marginBottom="6rem">
                    {mainHeadline}
                </Title>
                <ContentContainer>
                    <BigSubHeadlineParagraph marginTop="0" marginBottom="3rem">
                        {subHeadline}
                    </BigSubHeadlineParagraph>
                    <Wysiwyg mobileMargin="3rem 0" text={text} />
                    <DownloadButton
                        data={buttondata}
                        customMargin="3rem 0 0"
                        maxWidth="50%"
                    />
                </ContentContainer>
                <AsideContainer>
                    <AsideDownload
                        addInfo={addInfo}
                        headline={addHeadline}
                        storelinks={storeLinks}
                        apksHeadline={alternativeDownload}
                        setVisible={setVisible}
                        androidlegal={androidlegal}
                    />
                </AsideContainer>
            </Grid>
            <Modal
                show={visible}
                handleClose={handleClose}
                translations={translations}
            >
                <ConditionalDownload
                    data={androidData}
                    langCode={langCode}
                    handleClose={handleClose}
                />
            </Modal>
        </Section>
    )
}
