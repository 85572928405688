import styled from 'styled-components'
import { colors, fontSizes } from '../Theme'

const { white, brightButtonBgColor, primaryColor } = colors
const { xSmallSize } = fontSizes

export const Form = styled.form`
    display: flex;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        max-width: ${(props) => props.maxWidth};
    }
    @media (min-width: ${({ theme }) => theme.desktopXL}) {
        max-width: 25rem;
    }
`
export const DownloadButtonWrap = styled.button`
    display: inline-block;
    border-radius: 8px;
    border: 1px solid;
    border-color: ${(props) => props.borderColorMobile || primaryColor};
    background-color: ${(props) => props.bgColor || primaryColor};
    background-color: ${(props) => (props.darkMode ? brightButtonBgColor : '')};
    line-height: 1.6em;
    margin: ${(props) => props.customMargin || '0'};
    padding: 0.5rem 1rem;
    width: 100%;
    min-height: 4.5rem;
    p {
        color: ${(props) => props.textColorMobile || white};
    }
    @media (min-width: ${({ theme }) => theme.mobile}) {
        margin: 1rem 0;
        width: 100%;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        border-color: ${(props) =>
            props.borderColorDesktop || props.borderColorMobile};
        p {
            color: ${(props) =>
                props.textColorDesktop || props.textColorMobile};
        }
    }
`

export const DownloadButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const DownloadButtonTextContainer = styled.div`
    text-align: left;
`

const DownloadButtonText = styled.p`
    margin: 0;
`

export const DownloadButtonTextFirst = styled(DownloadButtonText)`
    font-weight: 700;
`
export const DownloadButtonTextSecond = styled(DownloadButtonText)`
    font-size: ${xSmallSize};
`

export const DownloadIconContainer = styled.div`
    display: flex;
    align-items: center;
    & > svg {
        color: ${(props) => props.iconColor || white};
        height: 28px;
        width: 28px;
    }
`
