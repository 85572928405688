import React from 'react'
import {
    TwoColumnsParagraph,
    ContentNativeImage,
    SectionTitle,
    FeatureWrapNoFlex,
    SectionBackground,
} from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import { ContactFlag } from '../../../ContactFlag'
import { DialectsImageContainer } from './LanguagesAndDialect.styled'
import { MobileHeadline } from '../../../MobileHeadline'
import { getDrupalData } from '../../../../utils'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '../../../Theme'

export const LanguagesAndDialects = ({ data, translations }) => {
    const {
        mainHeadline,
        textLong,
        altText,
        paginationText,
        paginationNumber,
        fluidImage,
    } = getDrupalData(data)
    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }
    const themeContext = useTheme()
    const { src, srcSet, sizes } = fluidImage

    return (
        <SectionBackground background={({ theme }) => theme.backgroundGradient}>
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={mainHeadline}
            />
            <SitePagination
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <FeatureWrapNoFlex>
                <div ref={ref} className="not-in-view">
                    <DialectsImageContainer>
                        <picture>
                            <source srcSet={srcSet} sizes={sizes}></source>
                            <ContentNativeImage
                                src={src}
                                srcSet={srcSet}
                                sizes={sizes}
                                alt={altText}
                            />
                        </picture>
                    </DialectsImageContainer>
                    <SectionTitle
                        textTransform="uppercase"
                        dark
                        displayMobile="none"
                    >
                        {mainHeadline}
                    </SectionTitle>
                    <TwoColumnsParagraph
                        darkMode={themeContext.mode === 'dark' ? 'white' : ''}
                    >
                        {textLong}
                    </TwoColumnsParagraph>
                </div>
            </FeatureWrapNoFlex>
            <ContactFlag
                darkMode={themeContext.mode === 'dark' ? 'white' : ''}
                translations={translations}
            />
        </SectionBackground>
    )
}
