import styled from 'styled-components'
import { SectionTitle } from '../../../basic'

export const WhoWeAreSectionTitle = styled(SectionTitle)`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-column: span 2;
    }
`
export const WhoWeAreImage = styled.img`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transition-delay: 500ms;

    margin: 0 auto;
    max-width: 200px;
    img {
        height: auto !important;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-row: span 2;
        max-width: none;
    }
`
