import React from 'react'
import { CustomIcon } from '../../CustomIcon'
import { ToggleContainer } from './ThemeToggle.styled'

export const ThemeToggle = ({
    iconType,
    // text = '',
    clickHandle,
    attribute,
    itemWidth,
    iconWidth,
    title,
}) => {
    return (
        <ToggleContainer
            href="#"
            title={title}
            itemWidth={itemWidth}
            onClick={() => clickHandle()}
        >
            <CustomIcon
                type={iconType}
                attribute={attribute}
                width={iconWidth}
            />
            {/* <span>{text}</span> */}
        </ToggleContainer>
    )
}
