import styled from 'styled-components'
import { colors } from '../../../Theme'
import { fontSizes } from '../../../Theme'
import { Section } from '../../../basic/'

const { minimalSmallerSize } = fontSizes

const { mainFontColor, darkBgColor } = colors

export const SectionWrapper = styled(Section)`
    display: flex;
    flex-direction: column;
    min-height: unset;
    background-color: #7676801f;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        flex-direction: row;
        min-height: fit-content;
    }
    @media (min-width: ${({ theme }) => theme.desktop}) {
        min-height: 100vh;
    }
`

export const ContentContainer = styled.div`
    padding: 0;
    margin: 0;
    color: ${mainFontColor};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        padding: 0 10vw 0 5vw;
        margin: 15vh 0 5vh;
    }
`

export const ToggleContainer = styled.div`
    display: flex;
    margin: 2rem 0.5rem 0 0.5rem;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: 2rem 1rem 0 1rem;
    }
    @media (min-width: ${({ theme }) => theme.desktop}) {
        margin: 4rem 1rem 0 1rem;
        max-width: 50%; // to be modified if there are more than two buttons
    }
`
export const Toggle = styled.button`
    display: flex;
    border: none;
    width: 50%; // to be modified if there are more than two buttons
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    font-size: ${minimalSmallerSize};
    line-height: calc(${minimalSmallerSize} * 1.4);
    font-weight: 900;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background-color: inherit;
    &:hover {
        cursor: pointer;
    }
`

export const FeatureImage = styled.img`
    max-width: 100%;
    border-top: 1px solid ${darkBgColor};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        border-radius: 20px;
        border: 1px solid ${darkBgColor};
    }
`

// unused styled - nice to have in stock - please do not delete

export const RoundedToggleContainer = styled.div`
    display: flex;
    margin: 2rem;
    border-radius: 56px;
    border: 1px solid ${darkBgColor};
    padding: 0.1rem;
    @media (min-width: ${({ theme }) => theme.desktop}) {
        max-width: 50%;
        padding-left: 0;
        margin: 2rem 0;
    }
`
export const RoundedToggle = styled.button`
    border-radius: 14px;
    border: none;
    width: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.7rem;
    font-size: ${minimalSmallerSize};
    line-height: calc(${minimalSmallerSize} * 1.4);
    font-weight: normal;
    letter-spacing: 0.75px;
    text-align: center;
    text-transform: uppercase;
    &:hover {
        cursor: pointer;
    }
`
