import React from 'react'
import { StoreLinkContainer, ImageContainer } from './StoreLink.styled'
import { Wysiwyg } from '../basic'
import { AlternativeApkDownload } from '../AlternativeApkDownload'
import { Breakpoint } from 'react-socks'

export const StoreLink = ({
    link,
    title,
    direction,
    badge,
    langCode,
    setVisible,
}) => {
    const { extension, publicURL } = badge.imageFile

    return (
        <StoreLinkContainer>
            <Wysiwyg text={title} direction={direction} />
            <a href={link} target="_blank" rel="noopener noreferrer">
                <ImageContainer extension={extension}>
                    <img src={publicURL} alt={badge.alt} />
                </ImageContainer>
            </a>
            <Breakpoint customQuery="(min-width: 768px)">
                {extension !== 'svg' ? (
                    <AlternativeApkDownload
                        langCode={langCode}
                        setVisible={setVisible}
                    />
                ) : (
                    ''
                )}
            </Breakpoint>
        </StoreLinkContainer>
    )
}
