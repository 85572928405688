import React from 'react'
import { ContentGrid, Section, SectionTitle, Wysiwyg } from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import { ContactFlag } from '../../../ContactFlag'
import {
    RescueAppGridRight,
    RescueAppInfoGridContainer,
} from './RescueAppInfo.styled'
import { MobileHeadline } from '../../../MobileHeadline'
import Svg from '../../../../svg/infografik_rescue.inline.svg'
import { getDrupalData } from '../../../../utils'
import { useInView } from 'react-intersection-observer'

export const RescueAppInfo = ({ data, translations }) => {
    const { mainHeadline, text, paginationText } = getDrupalData(data) // TODO add 'paginationNumber' to fetch pagination data number from Drupal (now it is hardcoded)
    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })

    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    return (
        <Section id="rescue-service">
            <MobileHeadline
                paginationNumber="04" //TODO: modify on drupal and replace it with {paginationNumber}
                text={mainHeadline}
            />
            <SitePagination
                pageNumber="04" //TODO: modify on drupal and replace it with {paginationNumber}
                pageText={paginationText}
            />
            <ContentGrid
                columns="50% 50%"
                margin="22vh 0 15vh"
                className="not-in-view"
                ref={ref}
            >
                <RescueAppInfoGridContainer>
                    <SectionTitle dark displayMobile="none">
                        <Wysiwyg text={mainHeadline} />
                    </SectionTitle>
                    <div>
                        <Wysiwyg text={text} />
                    </div>
                </RescueAppInfoGridContainer>
                <RescueAppGridRight>
                    <Svg />
                </RescueAppGridRight>
            </ContentGrid>
            <ContactFlag translations={translations} />
        </Section>
    )
}
