import React from 'react'
import A11Y from '../../svg/icons/icon_barrierefreiheit.inline.svg'
import Earth from '../../svg/icons/icon_earth--filled.inline.svg'
import MailAll from '../../svg/icons/icon_newsletter.inline.svg'
import OpenBook from '../../svg/icons/icon_open_book_grey.inline.svg'
import OpenBookWhite from '../../svg/icons/icon_open_book_white.inline.svg'

// import CONTRAST from '../../svg/icons/icon_contrast.inline.svg'
// import CONTRASTNEW from '../../svg/icons/icon_contrast_new.inline.svg'
import Textsize from '../../svg/icons/icon_font_size.inline.svg'
import TextsizeDarkmode from '../../svg/icons/icon_font_size-white.inline.svg'
import { useTheme } from '../Theme'
import styled from 'styled-components'
import { FiMoon, FiSun } from 'react-icons/fi'

const IconContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    transform: scale(${(props) => (props.larger ? '1.3' : '')});

    > svg {
        margin: ${(props) =>
            props.margin ||
            '0 0.5rem'}; // als fallback margin für alle andere, wo margin nicht specified ist
        width: ${(props) =>
            props.position === 'offcanvas'
                ? '32px'
                : props.width !== ''
                ? props.width
                : 'inherit'};
        height: ${(props) =>
            props.position === 'offcanvas'
                ? '32px'
                : props.width !== ''
                ? 'auto'
                : '24px'};
    }
    > svg#earth {
        path {
            fill: ${(props) => (props.dark === true ? 'inherit' : 'white')};
        }

        @media (min-width: ${({ theme }) => theme.tablet}) {
            path {
                fill: ${(props) => (props.dark === true ? 'white' : 'inherit')};
            }
            line.st3 {
                stroke: ${(props) =>
                    props.dark === true ? 'white' : 'inherit'};
            }
        }
    }
    > svg#accessibility {
        path {
            stroke: ${(props) => (props.dark === true ? '#1D1D1E' : 'white')};
        }
        line.st3 {
            stroke: ${(props) => (props.dark === true ? '#1D1D1E' : 'white')};
        }
        @media (min-width: ${({ theme }) => theme.tablet}) {
            path {
                stroke: ${(props) =>
                    props.dark === true ? 'white' : '#1D1D1E'};
            }
            line.st3 {
                stroke: ${(props) =>
                    props.dark === true ? 'white' : '#1D1D1E'};
            }
        }
    }

    #contrast {
        #vertical {
            opacity: ${(props) => (props.attribute === 'more' ? '1' : '0')};
        }
    }
`

export const CustomIcon = ({
    type = 'earth',
    attribute,
    position,
    width,
    margin,
}) => {
    const themeContext = useTheme()

    return (
        <IconContainer
            attribute={attribute}
            position={position}
            width={width}
            margin={margin}
            larger={type === 'textsize' ? true : undefined}
            dark={themeContext.mode === 'dark' ? true : false}
        >
            {(() => {
                switch (type) {
                    case 'earth':
                        return <Earth />
                    case 'a11y':
                        return <A11Y />
                    // contrast has been removed in favour of textsize
                    // case 'contrast':
                    //     return <CONTRASTNEW />
                    case 'textsize':
                        return themeContext.mode === 'dark' ? (
                            <TextsizeDarkmode />
                        ) : (
                            <Textsize />
                        )
                    case 'moon':
                        return <FiMoon />
                    case 'sun':
                        return <FiSun />
                    case 'newsletter':
                        return <MailAll />
                    case 'instructions':
                        return <OpenBook />
                    case 'instructionsWhite':
                        return <OpenBookWhite />
                    default:
                        return <Earth />
                }
            })()}
        </IconContainer>
    )
}
