import React from 'react'
import {
    ContentGrid,
    Wysiwyg,
    BackroundVideoSection,
    SubHeadlineAnamnesis,
    SectionTitleAnamnesis,
    SectionBackgroundDesktopOnly,
} from '../../../basic'
import { DownloadButton } from '../../../DownloadButton'
import { SitePagination } from '../../../SitePagination'
import {
    TextContainer,
    VideoContainer,
    PurchaseInfoBox,
    ButtonContainer,
    PurchaseBoxDescription,
    Description,
} from './AnamnesisStartNew.styled'
import { useTheme, colors } from '../../../Theme'
import { MobileHeadline } from '../../../MobileHeadline'
import { useInView } from 'react-intersection-observer'
import { BackgroundVideo } from '../../../BackgroundVideo'
import broschuere_arzt_praxen from '/static/anamnesis_broschuere_Hausarzt_20221116.pdf'
import AnamnesisOnTablet from '../../../../images/anamnesis_01_video_cut.mp4'
import AnamnesisOnTabletPoster from '../../../../images/anamnesis_01_cover_image.png'

const hcPaginationNumber = '01'
const hcPaginationText = 'Für Arztpraxen'
const hcSectionTitle = 'Anamnese leicht gemacht – in über 20 Sprachen'
const hcMainHeadline = '<strong>aid</strong>minutes.<strong>anamnesis</strong>'
const hcIntroText =
    'Behandlung optimal vorbereiten und gleichzeitig den Praxis-Alltag erleichtern: Mit der mehrsprachigen digitalen Anamnese von aidminutes.anamnesis machen Ihre Patient:innen die Anamnese selbst – in der Sprache, die sie verstehen. Als Ergebnis erhalten Sie eine aussagekräftige Zusammenfassung aller maßgeblichen Informationen in Deutsch. So spart Ihr Team nicht nur Zeit. Sie können auch gezielter in die Therapie einsteigen.'

const hcContactInfo =
    'Für Lizenzanfragen von Krankenhäusern und Versicherungen kontaktieren Sie uns bitte per E-Mail: '
const hcContactInfoAddress = 'business.anamnesis@aidminutes.com'
const hcPurchaseBoxDescription = '30 Tage testen - kostenlos und ohne Risiko'

export const AnamnesisStartNew = ({ data }) => {
    // TODO import from Drupal
    // const { mainHeadline, text, paginationNumber, paginationText } =
    //     getDrupalData(data)

    // TODO remove this when file can be uploaded to drupal
    const videodata = [
        {
            downloadFile: {
                publicURL: AnamnesisOnTablet,
            },
            entity: {
                filemime: 'video/mp4',
            },
        },
    ]
    // TODO put me back after Drupal Umsetzung
    // const videodata = (data && data.fieldVideos) || ''
    // const poster = data?.fieldContentImage?.imagefile?.publicURL || ''

    const poster = AnamnesisOnTabletPoster

    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    const buttonDataDownloadFile = {
        description: 'aidminutes.anamnesis',
        text: 'Broschüre Arztpraxen (pdf)',
        color: colors.cloudyGrey,
        src: broschuere_arzt_praxen,
        type: 'instructionsWhite',
    }

    const themeContext = useTheme()

    return (
        <SectionBackgroundDesktopOnly background="#f7f9f9" id="anamnesis-start">
            <MobileHeadline
                paginationNumber={hcPaginationNumber}
                text={hcSectionTitle}
                primary
                textToUppercase
            />
            <SitePagination
                darkMode={themeContext.mode === 'dark'}
                pageNumber={hcPaginationNumber}
                pageText={hcPaginationText}
                textTransform="capitalize"
            />
            <ContentGrid
                columns="45% 55%"
                margin="0"
                padding="0 0 0 2vw"
                mobilePadding="0"
                alignItems="start"
            >
                <TextContainer ref={ref} className="fadeInUp">
                    <SectionTitleAnamnesis
                        textTransform="uppercase"
                        marginTop="0.44em"
                        darkMode={themeContext.mode === 'dark'}
                    >
                        {hcSectionTitle}
                    </SectionTitleAnamnesis>
                    <SubHeadlineAnamnesis
                        desktopWidth="70vw"
                        darkMode={themeContext.mode === 'dark'}
                        fontWeight="unset"
                    >
                        <Wysiwyg text={hcMainHeadline} />
                    </SubHeadlineAnamnesis>
                    <Description darkMode={themeContext.mode === 'dark'}>
                        {/* //TODO refactor to `wysiwyg` after Drupal Umsetzung */}
                        {hcIntroText}
                    </Description>
                    <PurchaseInfoBox darkMode={themeContext.mode === 'dark'}>
                        <PurchaseBoxDescription isBolder={true}>
                            {hcPurchaseBoxDescription}
                        </PurchaseBoxDescription>
                    </PurchaseInfoBox>
                    <Description darkMode={themeContext.mode === 'dark'}>
                        {/*  TODO refactor to WYSIWYG */}
                        {hcContactInfo}
                        <a href="mailto:business.anamnesis@aidminutes.com">
                            {hcContactInfoAddress}
                        </a>
                    </Description>
                    <ButtonContainer margin="3rem 0 1rem">
                        <DownloadButton
                            data={buttonDataDownloadFile}
                            textColorMobile={
                                themeContext.mode === 'dark'
                                    ? colors.black
                                    : colors.white
                            }
                            borderColorMobile={
                                themeContext.mode === 'dark'
                                    ? colors.black
                                    : colors.darkestFontColor
                            }
                            textColorDesktop={
                                themeContext.mode === 'dark'
                                    ? colors.black
                                    : colors.white
                            }
                            borderColorDesktop={colors.mainFontColor}
                            maxWidth="85%"
                        />
                    </ButtonContainer>
                </TextContainer>
                <VideoContainer>
                    <BackroundVideoSection>
                        <BackgroundVideo
                            playbackRate={0.75}
                            data={videodata}
                            poster={poster}
                            displayMobile
                            hasFixedWidth
                            overlayPosition="relative"
                        />
                    </BackroundVideoSection>
                </VideoContainer>
            </ContentGrid>
        </SectionBackgroundDesktopOnly>
    )
}
