const getButtonSubText = (data) => {
    return data?.fieldButtonSubText?.value ?? ''
}
const getDownloadButtonText = (data) => {
    return data?.fieldDownloadButtonText ?? ''
}
const getDownloadLink = (data) => {
    return data?.fieldDownloadLink ?? ''
}
const getModalTriggerButtonText = (data) => {
    return data?.fieldModalTriggerButtonText ?? ''
}
const getPreButtonText = (data) => {
    return data?.fieldPreButtonText?.value ?? ''
}
const getCancellationPolicyText = (data) => {
    return data?.fieldTextCancellationPolicy?.value ?? ''
}
const getEulaText = (data) => {
    return data?.fieldTextEula?.value ?? ''
}
const getCounterUrl = (data) => {
    return data?.fieldCounterUrl ?? ''
}
export const softwareDownloadData = (data) => {
    const downloadButtonText = getDownloadButtonText(data)
    const buttonSubText = getButtonSubText(data)
    const downloadLink = getDownloadLink(data)
    const triggerText = getModalTriggerButtonText(data)
    const preButtonText = getPreButtonText(data)
    const cancellationText = getCancellationPolicyText(data)
    const eulaText = getEulaText(data)
    const counterUrl = getCounterUrl(data)

    const obj = {
        buttonSubText: buttonSubText,
        downloadButtonText: downloadButtonText,
        downloadLink: downloadLink,
        triggerText: triggerText,
        preButtonText: preButtonText,
        cancellationText: cancellationText,
        eulaText: eulaText,
        counterUrl: counterUrl,
    }

    return obj
}
