import styled from 'styled-components'
import { colors } from '../Theme'

const { mainFontColor, redCoralBgColor } = colors

export const RoundedButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    min-height: 3rem;
`
export const RoundedButtonContainer = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background-color: ${redCoralBgColor};
    background-color: ${(props) => props.mailTo && mainFontColor};
    width: auto;
    border-radius: 32px;
    padding: 8px 20px;
    filter: drop-shadow(1px 3px 4px rgba(0, 0, 0, 0.2));
    text-decoration: none;
    &:hover {
        cursor: pointer;
    }
`
export const Icon = styled.img`
    height: 1rem;
    width: 1rem;
`
export const ButtonText = styled.p`
    margin: 0;
    font-size: 0.9rem;
    font-weight: 700;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.75px;
`
