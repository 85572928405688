import React from 'react'
import {
    OffCanvasMobileTriggerIcon,
    TriggerButton,
} from './OffCanvasTrigger.styled'
import { CustomIcon } from '../../CustomIcon'

export const OffCanvasTrigger = ({
    darkMode,
    handleTriggerClick,
    open,
    labelledby,
    triggerText,
    iconType,
    preheader,
}) => {
    return (
        <TriggerButton
            darkMode={darkMode}
            id={labelledby}
            aria-label="Menu"
            aria-expanded={open}
            onClick={handleTriggerClick}
            open={open}
            className="language-dependent-font"
            preheader={preheader}
        >
            <span>
                <CustomIcon type={iconType} />
                {triggerText}
            </span>
            <OffCanvasMobileTriggerIcon collapse={open ? 1 : 0} />
        </TriggerButton>
    )
}
