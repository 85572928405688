const getProductsTranslation = (data) => {
    return data?.products ?? ''
}
const getLanguageTranslation = (data) => {
    return data?.language ?? ''
}
const getLangcode = (data) => {
    return data?.langCode ?? ''
}
const getContact = (data) => {
    return data?.contact ?? ''
}
const getSocial = (data) => {
    return data?.social ?? ''
}
const getClose = (data) => {
    return data?.close ?? ''
}
const getAccessibility = (data) => {
    return data?.accessibility ?? ''
}
const getAndroidLegal = (data) => {
    return data?.androidLegal ?? 'Android™ is a trademark of Google LLC.'
}
const getAlternativeDownload = (data) => {
    return data?.alternativeDownload ?? 'Alternative Download'
}
export const getTranslations = (data) => {
    const translationData = data?.edges[0]?.node ?? ''

    const products = getProductsTranslation(translationData)
    const language = getLanguageTranslation(translationData)
    const langCode = getLangcode(translationData)
    const contact = getContact(translationData)
    const social = getSocial(translationData)
    const close = getClose(translationData)
    const accessibility = getAccessibility(translationData)
    const androidlegal = getAndroidLegal(translationData)
    const alternativeDownload = getAlternativeDownload(translationData)

    return {
        products: products,
        language: language,
        langCode: langCode,
        contact: contact,
        social: social,
        close: close,
        accessibility: accessibility,
        androidlegal: androidlegal,
        alternativeDownload: alternativeDownload,
    }
}
