import React from 'react'
import {
    Layout,
    RescueApp,
    RescueAppInfo,
    RescueAppInsurances,
    RescueAppText,
    RescueFeatures,
    RescueAppVideo,
    RescueAppBenefits,
    Seo,
} from '../../components'
import {
    getPageData,
    getSubmenuData,
    getTopmenuData,
    useFooterData,
} from '../../utils'
import seo_rescue_fb from './../../images/seo_rescue_fb.png'

export const Rescue = ({ data, location, langCode, pageContext }) => {
    // TODO CC
    const prefix = pageContext?.prefix ?? ''
    const rescueAppData = getPageData(data, 'rescueAppQuery')

    //TODO store links (Google, Apple) in query
    const rescueAppInfoData = getPageData(data, 'rescueAppInfoQuery')
    //TODO add actual data
    const rescueFeaturesData = rescueAppData //getPageData(data, 'rescueFeaturesQuery')

    const rescueBenefitsData = getPageData(data, 'rescueAppBenefitsQuery')

    const rescueAppInsurancesData = getPageData(data, 'rescueAppVideoQuery')
    const rescueAppTextData = getPageData(data, 'rescueAppTextQuery')
    const rescueProductMenuData = getPageData(data, 'productMenuQuery')
    const { footerdata } = useFooterData(
        data,
        'footerQuery',
        'entities',
        prefix
    )
    const topmenu = getTopmenuData(data)
    const submenu = getSubmenuData(data)
    // TODO drupal
    const translations = data?.allTranslationsJson ?? ''
    const seoTitle = pageContext?.seoTitle ?? 'app.rescue'
    const seoDescription =
        langCode === 'DE'
            ? 'Die mehrsprachige App zur sofortigen Erfassung des Gesundheitszustands von Notfallpatient:innen'
            : 'The reliable companion for ambulance services'
    const seoImage = `${seo_rescue_fb}`
    const seoImageAlt = pageContext?.seoTitle
    const seoType = 'website'
    const seoUri =
        langCode === 'DE'
            ? 'https://www.aidminutes.com/product-rescue'
            : 'https://www.aidminutes.com/en/product-rescue'

    return (
        <Layout
            path={location.pathname}
            location={location}
            langCode={langCode}
            productmenuData={rescueProductMenuData}
            translations={translations}
            pageContext={pageContext}
            topmenu={topmenu}
            submenu={submenu}
            footerdata={footerdata}
        >
            <Seo
                lang={langCode}
                title={seoTitle}
                type={seoType}
                url={seoUri}
                image={seoImage}
                alt={seoImageAlt}
                description={seoDescription}
            />
            <RescueApp
                data={rescueAppData}
                translations={translations}
                langCode={langCode}
            />
            <RescueFeatures
                data={rescueFeaturesData}
                translations={translations}
                langCode={langCode}
            />
            <RescueAppBenefits
                data={rescueBenefitsData}
                translations={translations}
                langCode={langCode}
            />
            <RescueAppInfo
                data={rescueAppInfoData}
                translations={translations}
                langCode={langCode}
            />
            <RescueAppVideo
                data={rescueAppInfoData}
                translations={translations}
                langCode={langCode}
            />
            <RescueAppInsurances
                data={rescueAppInsurancesData}
                translations={translations}
                langCode={langCode}
            />
            <RescueAppText
                data={rescueAppTextData}
                translations={translations}
                langCode={langCode}
            />
        </Layout>
    )
}
