const hardCodedData = {
    allPricesInfo: [
        {
            description: 'Im Notfall nicht sprachlos',
            features: [
                'Lizenz für professionellen Einsatz',
                'Gesprächsprotokoll und Export-Feature',
                'Offline-Fähigkeit',
                'ab 5 Lizenzen: übersichtliche Nutzer:innen-Verwaltung',
            ],
            id: 'price_1JhDrFAq9nrUIRDW0JCrwTgM',
            interval: 'month',
            name: 'aidminutes.rescue',
            tiers: [
                {
                    __typename: 'PriceInfoTier',
                    max: 4,
                    min: 1,
                    unitAmount: 325,
                },
                {
                    __typename: 'PriceInfoTier',
                    max: 9,
                    min: 5,
                    unitAmount: 300,
                },
                {
                    __typename: 'PriceInfoTier',
                    max: 99,
                    min: 10,
                    unitAmount: 290,
                },
                {
                    __typename: 'PriceInfoTier',
                    max: 249,
                    min: 100,
                    unitAmount: 275,
                },
                {
                    __typename: 'PriceInfoTier',
                    max: 499,
                    min: 250,
                    unitAmount: 270,
                },
                {
                    __typename: 'PriceInfoTier',
                    max: 999,
                    min: 500,
                    unitAmount: 267,
                },
                {
                    __typename: 'PriceInfoTier',
                    max: 2499,
                    min: 1000,
                    unitAmount: 250,
                },
                {
                    __typename: 'PriceInfoTier',
                    max: 4999,
                    min: 2500,
                    unitAmount: 245,
                },
                {
                    __typename: 'PriceInfoTier',
                    max: null,
                    min: 5000,
                    unitAmount: 239,
                },
            ],
            unitLabel: 'Lizenzen',
            __typename: 'PriceInfo',
        },
        // {
        //     description: 'Im Notfall nicht sprachlos',
        //     features: [
        //         'Lizenz für professionellen Einsatz',
        //         'Gesprächsprotokoll und Export-Feature',
        //         'Offline-Fähigkeit',
        //         'ab 5 Lizenzen: übersichtliche Nutzer:innen-Verwaltung',
        //     ],
        //     id: 'price_1JhDwkAq9nrUIRDWVHWP3FXr',
        //     interval: 'year',
        //     name: 'aidminutes.rescue',
        //     tiers: [
        //         {
        //             __typename: 'PriceInfoTier',
        //             max: 4,
        //             min: 1,
        //             unitAmount: 3783,
        //         },
        //         {
        //             __typename: 'PriceInfoTier',
        //             max: 9,
        //             min: 5,
        //             unitAmount: 3492,
        //         },
        //         {
        //             __typename: 'PriceInfoTier',
        //             max: 99,
        //             min: 10,
        //             unitAmount: 3375.6,
        //         },
        //         {
        //             __typename: 'PriceInfoTier',
        //             max: 249,
        //             min: 100,
        //             unitAmount: 3201,
        //         },
        //         {
        //             __typename: 'PriceInfoTier',
        //             max: 499,
        //             min: 250,
        //             unitAmount: 3142.8,
        //         },
        //         {
        //             __typename: 'PriceInfoTier',
        //             max: 999,
        //             min: 500,
        //             unitAmount: 3107.88,
        //         },
        //         {
        //             __typename: 'PriceInfoTier',
        //             max: 2499,
        //             min: 1000,
        //             unitAmount: 2910,
        //         },
        //         {
        //             __typename: 'PriceInfoTier',
        //             max: 4999,
        //             min: 2500,
        //             unitAmount: 2851.8,
        //         },
        //         {
        //             __typename: 'PriceInfoTier',
        //             max: null,
        //             min: 5000,
        //             unitAmount: 2781.96,
        //         },
        //     ],
        //     unitLabel: 'Lizenzen',
        //     __typename: 'PriceInfo',
        // },
    ],
}

export default hardCodedData
