import React from 'react'
import Helmet from 'react-helmet'

/**
 * Creates an URL for redirects based on the input object, also does some checking
 * @param {Object} pageContext page context object from graphql response
 * @param {String} pageContext.publicFileURL file on the server
 * @param {String} pageContext.publicLongURL custom url instead of file
 * @param {Boolean} pageContext.relative whether it's a relative `publicLongURL`
 * @param {String} pageContext.languageID the languageID, interesting for `relative` URLs
 */
const createRedirectUrl = ({
    publicFileURL,
    publicLongURL,
    relative,
    languageID,
}) => {
    const fallbackURL = 'https://aidminutes.com'

    if (publicLongURL) {
        if (relative) {
            // skip URL checks for relative URLS (eg. /foo), also possibly add language id
            return languageID === 'de'
                ? publicLongURL
                : `/${languageID}${publicLongURL}`
        } else {
            // parse absolute URL (eg https://example.com/)
            let parsedLongURL
            try {
                parsedLongURL = new URL(publicLongURL).toString()
            } catch (e) {
                // no valid absolute URL found, so use fallback
                return fallbackURL
            }
            return parsedLongURL
        }
    } else if (publicFileURL !== undefined) {
        return publicFileURL.substr(0, 1) === '/'
            ? publicFileURL
            : `/${publicFileURL}`
    } else {
        return fallbackURL
    }
}

const Redirect = ({ data, location, pageContext }) => {
    return (
        <>
            <Helmet>
                {
                    <meta
                        http-equiv="refresh"
                        content={`0;url=${createRedirectUrl(pageContext)}`}
                    />
                }
            </Helmet>
            <p>
                Redirecting you to{' '}
                <a href="{createRedirectUrl(pageContext)}">
                    {createRedirectUrl(pageContext)}
                </a>
                ...
            </p>
        </>
    )
}

export default Redirect
