import React, { useContext, createContext, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../ThemeDefinitions'
import { getConfig } from '../../../config'

const ThemeToggleContext = createContext()
export const useTheme = () => useContext(ThemeToggleContext)

export const ThemeContext = ({ children }) => {
    let userTheme

    if (typeof window !== 'undefined') {
        const localTheme = localStorage.getItem('theme')
        userTheme = localTheme
            ? JSON.parse(localTheme)
            : { mode: 'light', size: 'normal' }
    } else {
        userTheme = { mode: 'light', size: 'normal' }
    }

    const [themeState, setThemeState] = useState({
        mode: userTheme.mode,
        size: userTheme.size,
    })

    const [lastMode, setLastMode] = useState()

    // const toggleMode = () => {
    //     const mode = themeState.mode === 'light' ? `highcontrast` : `light`
    //     setThemeState({ ...themeState, mode: mode })
    //     localStorage.setItem(
    //         'theme',
    //         JSON.stringify({ ...themeState, mode: mode })
    //     )
    // }
    // const toggleFontSize = () => {
    //     const size = themeState.size === 'normal' ? `bigger` : `normal`
    //     setThemeState({ ...themeState, size: size })
    //     localStorage.setItem(
    //         'theme',
    //         JSON.stringify({ ...themeState, size: size })
    //     )
    // }

    const highContrastToggle = () => {
        const size = themeState.size === 'normal' ? `bigger` : `normal`
        setThemeState({ ...themeState, size: size })
        if (typeof window !== 'undefined') {
            localStorage.setItem(
                'theme',
                JSON.stringify({ ...themeState, size: size })
            )
        }
    }

    const darkModeToggle = () => {
        setLastMode(themeState.mode)
        const mode = themeState.mode !== 'dark' ? 'dark' : lastMode || 'light'
        setThemeState({ ...themeState, mode: mode })
        if (typeof window !== 'undefined') {
            localStorage.setItem(
                'theme',
                JSON.stringify({ ...themeState, mode: mode })
            )
        }
    }

    const { preheader } = getConfig()

    return (
        <ThemeToggleContext.Provider
            value={{
                highContrastToggle: highContrastToggle,
                darkModeToggle: darkModeToggle,
                mode: themeState.mode,
                preheader: preheader,
                size: themeState.size,
            }}
        >
            <ThemeProvider theme={theme}>
                <ThemeProvider
                    theme={{
                        mode: themeState.mode,
                        size: themeState.size,
                    }}
                >
                    {children}
                </ThemeProvider>
            </ThemeProvider>
        </ThemeToggleContext.Provider>
    )
}
