import styled from 'styled-components'
import { PageImage } from '../../../../basic'
import { colors } from '../../../../Theme'

const { mainFontColor } = colors

export const SafetyItemContainer = styled.div`
    p {
        margin-top: 0;
        margin-bottom: 0;
    }
`

export const SafetyItemTitle = styled.span`
    color: ${mainFontColor};
    font-weight: 700;
    line-height: 1.375em;
`

export const SafetyItemImage = styled(PageImage)`
    max-height: 35px;
    margin-inline-end: 5px;
`

export const SafetyItemHeadlineContainer = styled.h3`
    align-items: baseline;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        min-height: 55px;
    }
`
