import React from 'react'
import { Section, SectionTitle, Wysiwyg } from '../../../basic'
import { SafetyItem } from './SafetyItem'
import {
    SafetySubheadline,
    SafetyWrap,
    SafetyGrid,
    SafetyText,
    SafetyTextContainer,
} from './Safety.styled'
import { MobileHeadline } from '../../../MobileHeadline'
import { getDrupalData } from '../../../../utils'
import { useInView } from 'react-intersection-observer'

export const Safety = ({ data }) => {
    const { mainHeadline, headline, subHeadline, text } = getDrupalData(data)

    const safetyItems = (data && data.fieldItems) || ''
    // const count = safetyItems.length || ''

    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    return (
        <Section id="safety">
            <MobileHeadline text={mainHeadline} />
            <SafetyWrap
                width="100%"
                margin="15vh 0 10vh"
                ref={ref}
                className="not-in-view"
            >
                <SectionTitle dark displayMobile="none" marginBottom="0">
                    {headline}
                </SectionTitle>
                <SafetyTextContainer>
                    <SafetySubheadline>{subHeadline}</SafetySubheadline>
                    <SafetyText>
                        <Wysiwyg text={text} />
                    </SafetyText>
                </SafetyTextContainer>
                <SafetyGrid
                    columns={`repeat(2, 2fr)`}
                    gap="5vw"
                    width="100%"
                    margin="1rem 0 5vh"
                >
                    {safetyItems.map((item, index) => {
                        return <SafetyItem key={index} data={item.entity} />
                    })}
                </SafetyGrid>
            </SafetyWrap>
        </Section>
    )
}
