import React from 'react'
import {
    ProductMobileMenuNavLink,
    ProductMobileMenuContainer,
} from './ProductMenuMobile.styled'
import { getDrupalData } from '../../../../utils'
import { MobileMenuHeader } from '../../MobileMenuHeader'

export const ProductMenuMobile = ({
    productmenuData,
    pathname,
    handleMenuItemClick,
}) => {
    const { headline, mainHeadline } = getDrupalData(productmenuData)

    const menuItems = productmenuData.fieldMenuitem
    return (
        <ProductMobileMenuContainer>
            <MobileMenuHeader product={headline} productText={mainHeadline} />
            {menuItems &&
                menuItems.map((menuitem) => {
                    return (
                        <ProductMobileMenuNavLink
                            key={menuitem.entity.title}
                            to={`${pathname}${menuitem.entity.fieldPath}`}
                            onClick={handleMenuItemClick}
                        >
                            {menuitem.entity.title}
                        </ProductMobileMenuNavLink>
                    )
                })}
        </ProductMobileMenuContainer>
    )
}
