import React from 'react'
import {
    LocationContainer,
    LocationNumber,
    LocationHead,
    LocationTextContainer,
} from './Location.styled'

export const Location = ({ locationdata, darkMode }) => {
    // TODO Conditional Chaining
    const name = (locationdata && locationdata.fieldName) || ''
    const street = (locationdata && locationdata.fieldStreet) || ''
    const city = (locationdata && locationdata.fieldCity) || ''
    const mobile = (locationdata && locationdata.fieldMobile) || ''
    const phone = (locationdata && locationdata.fieldTelefon) || ''
    const email = (locationdata && locationdata.fieldemail) || ''

    return (
        <LocationContainer>
            <LocationHead>
                <LocationNumber>
                    {locationdata.fieldLocationNumber}
                </LocationNumber>
                <div>{locationdata.title}</div>
            </LocationHead>
            <LocationTextContainer darkMode={darkMode}>
                <div>{name}</div>
                <div>{street}</div>
                <div>{city}</div>
                <div>{mobile}</div>
                <div>{phone}</div>
                <div>{email}</div>
            </LocationTextContainer>
        </LocationContainer>
    )
}
