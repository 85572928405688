import PropTypes from 'prop-types'
import React from 'react'
import { Location } from '@reach/router'
import { Menu } from '../../Menu'
import { Preheader } from '../../Preheader'

if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]', {
        offset: 60,
        speed: 800,
        easing: 'easeInOutCubic',
    })
}

export const Header = ({
    productmenuData,
    translations,
    pageContext,
    topmenu,
    submenu,
    mobileBannerData,
}) => {
    return (
        <Location>
            {({ location }) => {
                return (
                    <>
                        <Preheader
                            pathname={location.pathname}
                            translations={translations}
                            pageContext={pageContext}
                        />

                        <Menu
                            pathname={location.pathname}
                            productmenuData={productmenuData}
                            translations={translations}
                            pageContext={pageContext}
                            topmenu={topmenu}
                            submenu={submenu}
                            mobileBannerData={mobileBannerData}
                        />
                    </>
                )
            }}
        </Location>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}
