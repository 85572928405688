import React from 'react'
import { StyledBurger } from './Burger.styled'
import { bool, func } from 'prop-types'
import { useTheme } from '../../Theme'

export const Burger = ({ open, setOpen }) => {
    const themeContext = useTheme()
    return (
        <StyledBurger
            darkMode={themeContext.mode === 'dark'}
            open={open}
            onClick={() => setOpen(!open)}
            name="mobile menu"
        >
            <div></div>
            <div></div>
            <div></div>
        </StyledBurger>
    )
}

Burger.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
}
