import React from 'react'
import {
    Wysiwyg,
    SectionTitleH1,
    Section,
    FeatureWrapNoFlex,
} from '../../basic'
import { getDrupalData } from '../../../utils'
import styled from 'styled-components'

const DownloadList = styled.ul`
    display: flex;
    flex-direction: column;
`

const DownliadListItem = styled.li`
    display: list-item;
    list-style-type: circle;
    > div {
        display: flex;
        gap: 0.5rem;
    }
`

export const LegalWithDownloads = ({ data }) => {
    // console.log({ data })
    const { headline, text, rechtlicheDownloads } = getDrupalData(data)

    // console.log({ rechtlicheDownloads })

    return (
        <Section>
            <FeatureWrapNoFlex>
                <SectionTitleH1>{headline}</SectionTitleH1>
                <Wysiwyg text={text} />
                <DownloadList>
                    {rechtlicheDownloads.map((item, index) => {
                        return (
                            <DownliadListItem key={index}>
                                <div>
                                    <Wysiwyg
                                        text={item.entity.fieldWysiwyg.value}
                                    />
                                    <a
                                        href={
                                            item.entity.fieldDownloadFile[0]
                                                .entity.url
                                        }
                                    >
                                        {'Download'}
                                    </a>
                                </div>
                            </DownliadListItem>
                        )
                    })}
                </DownloadList>
            </FeatureWrapNoFlex>
        </Section>
    )
}
