import React from 'react'
import {
    Wysiwyg,
    SectionTitleH1,
    Section,
    FeatureWrapNoFlex,
} from '../../basic'
import { getDrupalData } from '../../../utils'

export const ImpressSection = ({ data }) => {
    const { headline, text } = getDrupalData(data)

    return (
        <Section>
            <FeatureWrapNoFlex>
                <SectionTitleH1>{headline}</SectionTitleH1>
                <Wysiwyg text={text} />
            </FeatureWrapNoFlex>
        </Section>
    )
}
