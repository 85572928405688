import styled from 'styled-components'
import { colors, fontSizes } from '../Theme'

const { mainFontColor, white, brightFontColor } = colors
const { h1Size, techSize, subHeadlineSize, h3Size, h5Size } = fontSizes

export const Paragraph = styled.p`
    color: ${(props) => (props.darkMode ? 'white' : mainFontColor)};
    line-height: 1.667rem;
    margin-bottom: 10px;
    margin-top: ${(props) => props.marginTop || '1em'};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        text-align: ${(props) => props.align || 'unset'};
    }
    /* Setzt die Farbe ab Tablet-Größe */
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${(props) => props.colorOnBreakpointTablet};
        a {
            color: ${(props) => props.colorOnBreakpointTablet};
        }
    }
    /* Setzt die Farbe ab Desktop-Größe */
    @media (min-width: ${({ theme }) => theme.desktop}) {
        color: ${(props) => props.colorOnBreakpointDesktop};
        a {
            color: ${(props) => props.colorOnBreakpointDesktop};
        }
    }
`

export const StudyNumber = styled(Paragraph)`
    color: ${(props) => (props.darkMode ? 'black' : mainFontColor)};
    font-size: ${techSize};
    line-height: 1em;
    margin-top: 0;
    margin-bottom: 2rem;
`

export const SubHeadlineParagraph = styled.p`
    color: ${mainFontColor};
    font-size: ${h1Size};
    line-height: 1.3em;
    text-transform: uppercase;
    margin: 0;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        /* TODO: !! */
        font-size: 1.3em;
        margin: 1rem 0;
    }
`

export const TwoColumnsParagraph = styled(Paragraph)`
    color: ${(props) => (props.darkMode ? 'black' : '')};
    color: ${(props) => (props.darkMode === 'white' ? 'white' : '')};

    @media (min-width: ${({ theme }) => theme.desktop}) {
        columns: 2;
        column-gap: 5vw;
    }
`

export const SmallSubHeadlineParagraph = styled.p`
    color: ${(props) => (props.color === 'white' ? white : mainFontColor)};
    font-size: ${subHeadlineSize};
    font-weight: 700;
    line-height: 1.3em;
    margin: 0;
    text-transform: ${(props) => props.textTransform || 'inherit'};

    @media (max-width: ${({ theme }) => theme.tablet}) {
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-bottom: ${(props) => props.marginBottom || '0'};
        margin-top: ${(props) => props.marginTop || '1em'};
        max-width: ${(props) => props.width || '100%'};
    }
`
export const BigSubHeadlineParagraph = styled.p`
    color: ${(props) =>
        props.color === 'normal' ? brightFontColor : mainFontColor};
    font-size: ${h5Size};
    font-weight: 700;
    line-height: 1.6em;
    margin: 0;
    margin-bottom: 1em;
    text-transform: ${(props) => props.textTransform || 'inherit'};
    word-break: break-word;

    @media (max-width: ${({ theme }) => theme.tablet}) {
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h3Size};
        line-height: 1.3em;
        margin-bottom: ${(props) => props.marginBottom || '0'};
        margin-top: ${(props) => props.marginTop || '1em'};
        max-width: ${(props) => props.width || '100%'};
        text-transform: ${(props) => props.textTransformDesktop || 'inherit'};
    }
`
export const SubHeadline = styled.h2`
    // currently used in Hedi Page
    display: ${(props) => (props.displayMobile ? 'block' : 'none')};
    color: ${mainFontColor};
    font-size: ${h5Size};
    line-height: 1.4em;
    letter-spacing: 1px;
    font-weight: ${(props) => props.fontWeight || '700'};
    margin: 2rem 0 2rem 0;
    padding: ${(props) => props.paddingMobile || '1rem'};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: ${(props) => (props.displayDesktop ? 'block' : 'none')};
        font-size: 1.85rem;
        line-height: 3rem;
        margin: ${(props) => props.margin || 'inherit'};
        padding: 0;
    }
`
