import React from 'react'
import { graphql } from 'gatsby'

import { Tac as TacTemplate } from '../pageTemplates'

const Tac = ({ data, location, pageContext }) => {
    return (
        <>
            <TacTemplate
                data={data}
                location={location}
                langCode={pageContext.language}
                pageContext={pageContext}
            />
        </>
    )
}

export const query = graphql`
    query tacquery($language: Drupal_LanguageId!, $langCode: String!) {
        drupal {
            tacQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "tac" } }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeTac {
                        fieldWysiwyg {
                            value
                        }
                        title
                    }
                }
            }
            topMenuQuery: nodeQuery(
                filter: {
                    conditions: { field: "type", value: "__menu" }
                    conjunction: AND
                    groups: { conditions: { field: "title", value: "Topmenu" } }
                }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeMenu {
                        title
                        fieldAdditionalHeadline {
                            value
                        }
                        fieldMainHeadline {
                            value
                        }
                        fieldMenuitem {
                            entity {
                                ... on Drupal_NodeMenuitem {
                                    fieldPath
                                    fieldMainHeadline {
                                        value
                                    }
                                    fieldIsExternalLink
                                    fieldWysiwyg {
                                        value
                                    }
                                    title
                                }
                            }
                        }
                    }
                }
            }
            submenuQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "product_menu" } }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeProductMenu {
                        fieldMenues {
                            entity {
                                ... on Drupal_NodeMenu {
                                    fieldPath
                                    fieldMainHeadline {
                                        value
                                    }
                                    fieldAdditionalHeadline {
                                        value
                                    }
                                    title
                                    fieldIcon {
                                        url
                                        alt
                                        imageFile {
                                            childImageSharp {
                                                fluid(
                                                    maxWidth: 64
                                                    quality: 80
                                                ) {
                                                    ...GatsbyImageSharpFluid
                                                    src
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            footerQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "footer" } }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeFooter {
                        title
                        fieldTextRescue {
                            value
                        }
                        fieldMenues {
                            entity {
                                ... on Drupal_NodeMenu {
                                    title
                                    fieldAdditionalHeadline {
                                        value
                                    }
                                    fieldMainHeadline {
                                        value
                                    }
                                    fieldMenuitem {
                                        entity {
                                            ... on Drupal_NodeMenuitem {
                                                title
                                                vid
                                                fieldPath
                                                fieldMainHeadline {
                                                    value
                                                }
                                                fieldWysiwyg {
                                                    value
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allTranslationsJson(filter: { langCode: { eq: $langCode } }) {
            edges {
                node {
                    contact
                    language
                    langCode
                    products
                    social
                    accessibility
                }
            }
        }
    }
`

export default Tac
