import React from 'react'
import { Logo, LogoBlock, GridContainer } from './PartnerLogos.styled'

export const PartnerLogos = ({ logos, mobileWidth = '220px', darkMode }) => {
    const items = logos?.length || 0
    const columns = items > 4 ? 4 : items
    const rows = items > 4 ? Math.ceil(items / 4) : 1

    return (
        <GridContainer columns={columns} rows={rows}>
            {logos.map((logo, index) => {
                const { src, srcSet, sizes } =
                    logo?.imageFile?.childImageSharp?.fluid
                const alt = logo?.imageFile?.alt ?? ''
                return (
                    <LogoBlock id={index} key={index} mobileWidth={mobileWidth}>
                        <picture>
                            <source srcSet={srcSet} sizes={sizes}></source>
                            <Logo
                                src={src}
                                srcSet={srcSet}
                                sizes={sizes}
                                alt={alt}
                            />
                        </picture>
                    </LogoBlock>
                )
            })}
        </GridContainer>
    )
}
