/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/styles/css/normalize.css'
import './src/styles/css/typography.css'
import './src/styles/css/utils.css'
// import "./src/styles/css/webflow.css"
// import "./src/styles/css/aidminutes-web.webflow.css"
// import "./src/styles/css/aidminutes-custom.css"
export { wrapRootElement } from './src/components/Pricing/apolloProvier.js'
