import React, { useState, useEffect } from 'react'
import {
    Checkbox,
    Label,
    CheckboxWrap,
    ConditionalDownloadWrap,
    DownloadForm,
    CheckboxContainer,
    TextContainer,
    NoteWYSIWYG,
} from './ConditionalDownload.styled'
import { Wysiwyg } from '../basic'
import { Button } from '../Button'

export const ConditionalDownload = ({ data, langCode, handleClose }) => {
    // TODO drupal data
    const { counterUrl } = data
    const [activeBoxes, setActiveBoxes] = useState(null)
    const [linkVisible, setLinkVisible] = useState(false)
    const boxes = 2

    const handleSelection = (e) => {
        if (e.target.checked) {
            setActiveBoxes(activeBoxes + 1)
        } else {
            setActiveBoxes(activeBoxes - 1)
        }
    }
    useEffect(() => {
        if (boxes === activeBoxes) {
            setLinkVisible(true)
        } else {
            setLinkVisible(false)
        }
    }, [activeBoxes, boxes])

    const downloadSrc = linkVisible === true ? data.downloadLink : ''
    const buttonColor = linkVisible === true ? 'red' : 'redlight'

    const handleDownloadClick = (event) => {
        if (!linkVisible) {
            event.preventDefault()
        } else {
            triggerCounter(counterUrl)
            handleClose()
        }
    }

    const triggerCounter = async (url) => {
        await fetch(url)
    }
    return (
        <ConditionalDownloadWrap>
            <CheckboxWrap>
                <CheckboxContainer>
                    <Checkbox
                        type="checkbox"
                        name={`cancellation-${data.deviceId}-${langCode || ''}`}
                        id={`cancellation-${data.deviceId}-${langCode || ''}`}
                        onClick={handleSelection}
                    />
                    <Label
                        htmlFor={`cancellation-${data.deviceId}-${
                            langCode || ''
                        }`}
                    >
                        <Wysiwyg fontdependable text={data.cancellationText} />
                    </Label>
                </CheckboxContainer>
                <CheckboxContainer>
                    <Checkbox
                        type="checkbox"
                        name={`eula-${data.deviceId}-${langCode || ''}`}
                        id={`eula-${data.deviceId}-${langCode || ''}`}
                        onClick={handleSelection}
                    />
                    <Label htmlFor={`eula-${data.deviceId}-${langCode || ''}`}>
                        <Wysiwyg fontdependable text={data.eulaText} />
                    </Label>
                </CheckboxContainer>
            </CheckboxWrap>
            <DownloadForm
                method="get"
                action={downloadSrc}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Button
                    margin="0"
                    enabled={linkVisible}
                    buttonWidth="100%"
                    text={data.downloadButtonText}
                    additionalText='Android("apk)'
                    onClick={handleDownloadClick}
                    color="white"
                    bgColor={buttonColor}
                    type="submit"
                />
            </DownloadForm>
            <TextContainer>
                <NoteWYSIWYG text={data.buttonSubText} />
            </TextContainer>
        </ConditionalDownloadWrap>
    )
}
