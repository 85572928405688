import React from 'react'
import {
    Layout,
    Partner,
    Start,
    Contact,
    Downloads,
    WhoWeAre,
    LanguagesAndDialects,
    Apps,
    Safety,
    Seo,
} from '../../components'
import {
    getPageData,
    useFooterData,
    getSubmenuData,
    getTopmenuData,
} from '../../utils'
import seo_home_de_fb from './../../images/seo_home_de_fb.png'
import seo_home_en_fb from './../../images/seo_home_en_fb.png'

export const Home = ({ data, location, langCode, pageContext }) => {
    const direction = pageContext?.direction ?? ''
    const prefix = pageContext?.prefix ?? ''
    const startData = getPageData(data, 'homeQuery', 'home')
    const safetyData = getPageData(data, 'safetyQuery')
    const whoWeAreData = getPageData(data, 'whoWeAreQuery')
    const appsData = getPageData(data, 'appsQuery')
    const partnerData = getPageData(data, 'partnerQuery', 'partner')
    const languagesAndDialectsData = getPageData(data, 'dialectQuery')
    const contactData = getPageData(data, 'contactQuery', 'contact')
    const downloadsData = getPageData(data, 'downloadQuery', 'downloads')
    const { footerdata } = useFooterData(
        data,
        'footerQuery',
        'entities',
        prefix
    )
    const mobileBannerData = data.drupal.mobileBannerQuery.mobileBanner[0]
    const topmenu = getTopmenuData(data)
    const submenu = getSubmenuData(data)
    // TODO drupal
    const translations = data?.allTranslationsJson ?? ''
    const seoTitle = 'aidminutes' ?? pageContext?.seoTitle
    const seoDescription =
        langCode === 'DE'
            ? 'Wir heben medizinische Kommunikation auf eine neue Ebene'
            : 'We take medical communication to a new level'
    const seoImage =
        langCode === 'DE' ? `${seo_home_de_fb}` : `${seo_home_en_fb}`
    const seoImageAlt = pageContext?.seoTitle
    const seoType = 'website'
    const seoUri =
        langCode === 'DE'
            ? 'https://www.aidminutes.com'
            : 'https://www.aidminutes.com/en/'

    return (
        <Layout
            path={location.pathname}
            location={location}
            langCode={langCode}
            translations={translations}
            pageContext={pageContext}
            topmenu={topmenu}
            submenu={submenu}
            footerdata={footerdata}
            mobileBannerData={mobileBannerData}
        >
            <Seo
                lang={langCode}
                title={seoTitle}
                type={seoType}
                url={seoUri}
                image={seoImage}
                alt={seoImageAlt}
                description={seoDescription}
            />
            <Start
                data={startData}
                direction={direction}
                translations={translations}
            />
            <WhoWeAre data={whoWeAreData} translations={translations} />
            <Apps data={appsData} translations={translations} />
            <Partner data={partnerData} translations={translations} />
            <LanguagesAndDialects
                data={languagesAndDialectsData}
                translations={translations}
            />
            <Downloads data={downloadsData} />
            <Safety data={safetyData} />
            <Contact contactdata={contactData} />
        </Layout>
    )
}
