import React from 'react'
import { Layout, Seo } from '../../components'
import {
    getPageData,
    getSubmenuData,
    getTopmenuData,
    useFooterData,
} from '../../utils'
import {
    VaccinateStart,
    VaccinateDownload,
    VaccinateAdditionalInfos,
    VaccinateFeatures,
    VaccinateSignlanguage,
} from '../../components'
import seo_home_de_fb from './../../images/seo_home_de_fb.png'
import seo_home_en_fb from './../../images/seo_home_en_fb.png'

export const VaccinatePage = ({ data, location, langCode, pageContext }) => {
    const prefix = pageContext?.prefix ?? ''
    const rescueProductMenuData = getPageData(data, 'productMenuQuery')

    const vaccinateStart = getPageData(data, 'vaccinatestartQuery')
    const vaccinateDownload = getPageData(data, 'vaccinatedownloadQuery')
    const vaccinateAdditionalInfos = getPageData(
        data,
        'vaccinateAdditionalInfos'
    )
    const vaccinateFeatures = getPageData(data, 'vaccinateFeatures')
    const vaccinateSignlanguage = getPageData(data, 'vaccinateSignlanguage')
    const { footerdata } = useFooterData(
        data,
        'footerQuery',
        'entities',
        prefix
    )
    const mobileBannerData = data.drupal.mobileBannerQuery.mobileBanner[0]
    const topmenu = getTopmenuData(data)
    const submenu = getSubmenuData(data)
    // TODO drupal
    const translations = data?.allTranslationsJson ?? ''
    const seoTitle = pageContext?.seoTitle ?? 'Rescue Impfen'
    const seoDescription =
        langCode === 'DE'
            ? 'Die mehrsprachige Impfkommunikation für Praxis und Impfzentrum (kostenlos)'
            : 'Multilingual vaccination communication tool for clinics and vaccination centers (free)' //TODO: double check translation (EN not available)
    const seoImage =
        langCode === 'DE' ? `${seo_home_de_fb}` : `${seo_home_en_fb}`
    const seoImageAlt = pageContext?.seoTitle
    const seoType = 'website'
    const seoUri =
        langCode === 'DE'
            ? 'https://www.aidminutes.com/impfen'
            : 'https://www.aidminutes.com/en/impfen'

    return (
        <>
            <Layout
                path={location.pathname}
                langCode={langCode}
                productmenuData={rescueProductMenuData}
                translations={translations}
                pageContext={pageContext}
                topmenu={topmenu}
                submenu={submenu}
                footerdata={footerdata}
                mobileBannerData={mobileBannerData}
            >
                <Seo
                    lang={langCode}
                    title={seoTitle}
                    type={seoType}
                    url={seoUri}
                    image={seoImage}
                    alt={seoImageAlt}
                    description={seoDescription}
                />
                <VaccinateStart
                    pageContext={pageContext}
                    data={vaccinateStart}
                />
                <VaccinateDownload
                    translations={translations}
                    data={vaccinateDownload}
                />
                <VaccinateFeatures data={vaccinateFeatures} />
                <VaccinateSignlanguage data={vaccinateSignlanguage} />
                <VaccinateAdditionalInfos data={vaccinateAdditionalInfos} />
            </Layout>
        </>
    )
}
