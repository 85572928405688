import styled from 'styled-components'
import { SectionTitleH1 } from '../../../basic'
import { fontSizes, colors } from '../../../Theme'

const { white } = colors
const { h3Size, h4Size } = fontSizes

export const ContentWrap = styled.div`
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${white};
    }
`

export const RescueCovid19H1 = styled(SectionTitleH1)`
    margin: 0.75em 0;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${white};
    }
`

export const AdditionalText = styled.p`
    direction: ${(props) => props.direction || 'ltr'};
    font-size: ${(props) => props.fontSize || h3Size};
    font-weight: ${(props) => props.fontWeight || '300'};
    line-height: 1em;
    margin: 0;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h4Size};
        /* line-height: 1.5rem; */
        margin: 0.5rem 0;
    }
`
