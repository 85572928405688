import React from 'react'
import styled from 'styled-components'
import { FiExternalLink } from 'react-icons/fi'

import { fontSizes, colors } from '../Theme'

const { h4Size, h3Size } = fontSizes
const { primaryColor } = colors

export const Link = styled.a`
    align-items: center;
    color: ${primaryColor};
    display: flex;
    font-size: ${h4Size};
    font-weight: 700;
    text-decoration: none;
    max-width: 100vw;
    margin: 3rem 0;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: 6rem 0 6rem 0;
        font-size: ${h3Size};
    }
    &:hover {
        text-decoration: underline;
    }
    svg {
        margin: 0 0 0 1rem;
    }
`

export const BigLink = ({ children, url }) => {
    return (
        <Link href={url} target="_blank">
            {children}
            <FiExternalLink />
        </Link>
    )
}
