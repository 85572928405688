import styled from 'styled-components'
import { colors } from '../../../../Theme'

import {
    HoneyCombHeight,
    HoneyCombWidth,
    HoneyCombHeightMobile,
    HoneyCombWidthMobile,
} from './honeyComb.config'

const { primaryColor, inactiveItemsColor, greyishBgColor } = colors

export const HoneyCombLeft = styled.div`
    border-top: ${HoneyCombHeightMobile / 2}px solid transparent;
    border-bottom: ${HoneyCombHeightMobile / 2}px solid transparent;
    float: left;
    border-inline-end-width: ${HoneyCombWidth / 2}px;
    border-inline-end-style: solid;
    border-inline-end: ${HoneyCombWidthMobile / 2}px solid;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        border-top: ${HoneyCombHeight / 2}px solid transparent;
        border-bottom: ${HoneyCombHeight / 2}px solid transparent;
        border-inline-end: ${HoneyCombWidth / 2}px solid;
    }
`
export const HoneyCombRight = styled.div`
    border-top: ${HoneyCombHeightMobile / 2}px solid transparent;
    border-bottom: ${HoneyCombHeightMobile / 2}px solid transparent;
    float: left;
    border-inline-start-width: ${HoneyCombWidth / 2}px;
    border-inline-start-style: solid;
    border-inline-start: ${HoneyCombWidthMobile / 2}px solid;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        border-top: ${HoneyCombHeight / 2}px solid transparent;
        border-bottom: ${HoneyCombHeight / 2}px solid transparent;
        border-inline-start: ${HoneyCombWidth / 2}px solid;
    }
`
export const HoneyCombCenter = styled.div`
    float: left;
    width: ${HoneyCombWidthMobile}px;
    height: ${HoneyCombHeightMobile}px;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        width: ${HoneyCombWidth}px;
        height: ${HoneyCombHeight}px;
    }
`

export const HoneyCombContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    grid-column-start: ${(props) => props.hexadata.column.mobile};
    grid-column-end: span 4;
    grid-row-start: ${(props) => props.hexadata.row.mobile};
    grid-row-end: span 2;

    ${HoneyCombCenter} {
        background-color: ${(props) =>
            props.hexadata.bgColor.tablet === 'grey'
                ? inactiveItemsColor
                : props.hexadata.bgColor.tablet === 'grannygreen'
                ? primaryColor
                : greyishBgColor};
    }
    ${HoneyCombLeft} {
        border-inline-end-color: ${(props) =>
            props.hexadata.bgColor.tablet === 'grey'
                ? inactiveItemsColor
                : props.hexadata.bgColor.tablet === 'grannygreen'
                ? primaryColor
                : greyishBgColor};
    }
    ${HoneyCombRight} {
        border-inline-start-color: ${(props) =>
            props.hexadata.bgColor.tablet === 'grey'
                ? inactiveItemsColor
                : props.hexadata.bgColor.tablet === 'grannygreen'
                ? primaryColor
                : greyishBgColor};
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-column-start: ${(props) => props.hexadata.column.tablet};
        grid-row-start: ${(props) => props.hexadata.row.tablet};
    }
    @media (min-width: ${({ theme }) => theme.desktop}) {
        grid-column-start: ${(props) => props.hexadata.column.desktop};
        grid-row-start: ${(props) => props.hexadata.row.desktop};
        ${HoneyCombCenter} {
            background-color: ${(props) =>
                props.hexadata.bgColor.desktop === 'grey'
                    ? inactiveItemsColor
                    : props.hexadata.bgColor.desktop === 'grannygreen'
                    ? primaryColor
                    : greyishBgColor};
        }
        ${HoneyCombLeft} {
            border-inline-end-color: ${(props) =>
                props.hexadata.bgColor.desktop === 'grey'
                    ? inactiveItemsColor
                    : props.hexadata.bgColor.desktop === 'grannygreen'
                    ? primaryColor
                    : greyishBgColor};
        }
        ${HoneyCombRight} {
            border-inline-start-color: ${(props) =>
                props.hexadata.bgColor.desktop === 'grey'
                    ? inactiveItemsColor
                    : props.hexadata.bgColor.desktop === 'grannygreen'
                    ? primaryColor
                    : greyishBgColor};
        }
    }
`
