import React from 'react'
import { LanguageLink } from './LanguageSwitchMenu.styled'
import { useStaticQuery, graphql } from 'gatsby'
import { getTranslations } from '../../utils'
import { OffCanvasMenu } from '../OffCanvasMenu'
import { useTheme } from '../Theme'

export const LanguageSwitchMenu = ({ pathname, translations, pageContext }) => {
    const availableLanguages = useStaticQuery(graphql`
        query LanguageSwitchMenuQuery {
            drupal {
                availableLanguages {
                    id
                }
            }
        }
    `)
    const languages = availableLanguages.drupal.availableLanguages.map(
        (language) => language.id
    )
    const path = pathname.split('/')
    let pageUrl = path
        .filter((p) => p !== '' && !languages.includes(p))
        .toString()

    const { language } = getTranslations(translations)

    const abcSort = (array) => {
        array.sort(function (a, b) {
            const languageA = a.entity.fieldCode.toUpperCase() // ignore upper and lowercase
            const languageB = b.entity.fieldCode.toUpperCase() // ignore upper and lowercase
            if (languageA < languageB) {
                return -1 //languageA comes first
            }
            if (languageA > languageB) {
                return 1 // nameB comes first
            }
            return 0 // names must be equal
        })
        return array
    }

    const sortDirectionAndAscending = (array) => {
        const object = { rtl: [], ltr: [] }
        array.map((language) => {
            return language.entity.fieldRtl
                ? object['rtl'].push(language)
                : object['ltr'].push(language)
        })

        let keys = Object.keys(object)

        keys.map((key) => {
            return (object[key] = abcSort(object[key]))
        })

        return object
    }

    const sortedSiteLanguages =
        typeof pageContext.siteLanguages !== 'undefined' &&
        pageContext.siteLanguages.length > 0
            ? sortDirectionAndAscending(pageContext.siteLanguages)
            : ''

    const direction = pageContext?.direction ?? 'ltr'
    const themeContext = useTheme()

    return (
        <OffCanvasMenu
            iconType="earth"
            direction={direction}
            triggerText={language}
            menuID="language-switch"
            preheader={themeContext.preheader}
        >
            {sortedSiteLanguages.ltr.map((language, index) => {
                let path =
                    language.entity.fieldCode !== 'de'
                        ? '/' + language.entity.fieldCode + '/'
                        : '/'
                return pageContext.translationLanguages.includes(
                    language.entity.fieldCode
                ) && language.entity.fieldCode !== pageContext.langCode ? (
                    <LanguageLink
                        key={index}
                        to={`${path}${pageUrl}`}
                        direction="ltr"
                    >
                        <span>{language.entity.fieldMenuName}</span>
                    </LanguageLink>
                ) : (
                    ''
                )
            })}
            {sortedSiteLanguages.rtl.map((language, index) => {
                let path =
                    language.entity.fieldCode !== 'de'
                        ? '/' + language.entity.fieldCode + '/'
                        : ''
                return pageContext.translationLanguages.includes(
                    language.entity.fieldCode
                ) && language.entity.fieldCode !== pageContext.langCode ? (
                    <LanguageLink
                        key={index}
                        to={`${path}${pageUrl}`}
                        direction="rtl"
                    >
                        <span>{language.entity.fieldMenuName}</span>
                    </LanguageLink>
                ) : (
                    ''
                )
            })}
        </OffCanvasMenu>
    )
}
