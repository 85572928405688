import styled from 'styled-components'

export const BasicGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media (min-width: 36em) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 48em) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 62em) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 75em) {
        grid-template-columns: repeat(6, 1fr);
    }
`

export const ContentGrid = styled.div`
    grid-template-columns: 1fr;
    width: 100%;
    padding: ${(props) => props.mobilePadding || '3rem 1rem'};
    margin: 0;
    display: grid;
    grid-auto-rows: ${(props) => props.autoRows || 'auto'};
    grid-column-gap: ${(props) => props.gap || '0'};

    &.mobileColumn {
        display: flex;
        flex-direction: column;
        @media (min-width: ${({ theme }) => theme.tablet}) {
            display: none;
        }
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-template-columns: ${(props) => props.columns};
        width: ${(props) => props.width || '90vw'};
        padding: ${(props) => props.padding || '0 10vw 0 5vw'};
        margin: ${(props) => props.margin || '15vh 0 5vh'};
        align-items: ${(props) => props.alignItems || 'initial'};
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        grid-template-columns: 1fr;
        padding: ${(props) => props.mobilePadding || '5vw'};
        width: 100%;
        margin: 0;
    }
`
export const FlowContentGrid = styled.div`
    grid-template-columns: 1fr;
    width: 100%;
    padding: ${(props) => props.mobilePadding || '3rem 1rem'};
    margin: 0;
    display: grid;
    grid-auto-rows: auto;
    grid-column-gap: ${(props) => props.gap || '0'};

    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-template-columns: none;
        grid-template-rows: auto;
        min-height: auto;
        padding: ${(props) => props.paddingTablet || '1rem'};
        margin: ${(props) => props.marginTablet || '1rem'};
    }
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        grid-template-columns: ${(props) => props.columns};
        grid-template-rows: ${(props) => props.rows || 'none'};
        min-height: auto;
        width: ${(props) => props.width || '90vw'};
        max-width: 100vw;
        padding: ${(props) => props.padding || '0 4vw 0 4vw'};
        margin: ${(props) => props.margin || '15vh 0 5vh'};
        align-items: ${(props) => props.alignItems || 'initial'};
    }
`

export const ContentFlexBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.bgColor || 'transparent'};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        min-width: 100%;
        width: 100%;
    }
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        flex-direction: row;
        margin: auto;
        padding: 0.5rem 1rem;
        gap: 4rem;
        align-items: flex-start;
    }
    @media (min-width: ${({ theme }) => theme.desktop}) {
        padding: 1.5rem 1rem;
        justify-content: center;
    }
    @media (min-width: ${({ theme }) => theme.desktopXXL}) {
        max-width: ${({ theme }) => theme.desktopXL};
        gap: 10rem;
    }
`
