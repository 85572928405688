import styled from 'styled-components'

export const StoreLinkContainer = styled.div`
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin: 0;
        text-align: center;
        justify-content: center;
    }
`

export const ImageContainer = styled.div`
    img {
        margin: 1rem 0;
        height: auto;
        /* max-width: 190px; */
        width: 100%;
        @media (max-width: ${({ theme }) => theme.tablet}) {
            height: 49px;
            width: auto;
        }
    }
`

export const StoreLinkContainerNew = styled.div`
    margin: 2rem 0;
    display: flex;
    flex-direction: column;

    div:first-child {
        margin-bottom: 0.5rem;
    }

    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin: 0;
        text-align: center;
        justify-content: center;
    }
`
export const BadgeContainer = styled.div`
    height: fit-content;
    margin-bottom: 0 !important;
    margin-right: 16px;

    img {
        margin: 0;
        /* max-width: 190px; */
        height: 65px;
        width: auto;
        @media (min-width: ${({ theme }) => theme.tablet}) {
            height: 60px;
        }
    }
`
