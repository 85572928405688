import React from 'react'
import {
    DownloadButtonWrap,
    DownloadButtonContainer,
    DownloadButtonTextFirst,
    DownloadIconContainer,
    DownloadButtonTextSecond,
    DownloadButtonTextContainer,
    Form,
} from './DownloadButton.styled'
import { FaRegEnvelope } from 'react-icons/fa'
import { IoMdBook } from 'react-icons/io'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { CustomIcon } from '../CustomIcon'

/**
 * DownloadButton
 * since Anamnesis Page Umbau is used in less cases, so only partially deprecated (therefore not using the deprecated Tag)
 * The component is refactored and cleaned up in [UN-199] Branch
 */
export const DownloadButton = ({
    data: { description, text, color, iconColor, src, type },
    darkMode,
    customMargin,
    textColorMobile,
    borderColorMobile,
    textColorDesktop,
    borderColorDesktop,
    maxWidth,
}) => {
    const btnText = text || 'Download aidminutes'
    const btnDescription = description || ''
    const btnIconColor = iconColor || ''
    const btnBgColor = color || ''

    let buttonIcon
    let linking = 'form'

    const handleSubmit = (e) => {
        e.preventDefault()
        if (type === 'mail') {
            window.open('mailto:' + src, '_blank')
            return
        }
        window.open(src, '_blank')
    }

    switch (type) {
        case 'pdf':
            buttonIcon = <IoMdBook />
            break
        case 'mail':
            buttonIcon = <FaRegEnvelope />
            break
        case 'download':
            buttonIcon = <AiOutlineCloudDownload />
            break
        case 'instructions':
            buttonIcon = <CustomIcon margin={'0rem'} type="instructions" />
            break
        case 'instructionsWhite':
            buttonIcon = <CustomIcon margin={'0rem'} type="instructionsWhite" />
            break
        case 'newsletter':
            buttonIcon = <CustomIcon type="newsletter" />
            linking = 'link'
            break
        default:
            buttonIcon = <FaRegEnvelope />
    }
    // TODO improve
    if (linking === 'link') {
        return (
            <a href={src} target="_blank" rel="noopener noreferrer">
                <DownloadButtonWrap
                    bgColor={btnBgColor}
                    darkMode={darkMode}
                    type="submit"
                    customMargin={customMargin}
                >
                    <DownloadButtonContainer>
                        <DownloadButtonTextContainer>
                            <DownloadButtonTextFirst>
                                {btnText}
                            </DownloadButtonTextFirst>
                            <DownloadButtonTextSecond>
                                {btnDescription}
                            </DownloadButtonTextSecond>
                        </DownloadButtonTextContainer>
                        <DownloadIconContainer iconColor={btnIconColor}>
                            {buttonIcon}
                        </DownloadIconContainer>
                    </DownloadButtonContainer>
                </DownloadButtonWrap>
            </a>
        )
    }
    return (
        <Form
            rel="noopener noreferrer"
            onSubmit={(e) => handleSubmit(e)}
            maxWidth={maxWidth}
        >
            <DownloadButtonWrap
                bgColor={btnBgColor}
                darkMode={darkMode}
                type="submit"
                customMargin={customMargin}
                textColorMobile={textColorMobile}
                borderColorMobile={borderColorMobile}
                textColorDesktop={textColorDesktop}
                borderColorDesktop={borderColorDesktop}
            >
                <DownloadButtonContainer>
                    <DownloadButtonTextContainer>
                        <DownloadButtonTextFirst>
                            {btnText}
                        </DownloadButtonTextFirst>
                        <DownloadButtonTextSecond>
                            {btnDescription}
                        </DownloadButtonTextSecond>
                    </DownloadButtonTextContainer>
                    <DownloadIconContainer iconColor={btnIconColor}>
                        {buttonIcon}
                    </DownloadIconContainer>
                </DownloadButtonContainer>
            </DownloadButtonWrap>
        </Form>
    )
}
