import styled from 'styled-components'
import { PageImage } from '../../../basic'

export const AppsGridContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        p {
            max-width: 600px;
        }
    }
`

export const AppsGridContainerRight = styled(AppsGridContainer)`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
`

export const RescueAppImage = styled(PageImage)`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: 1vh 0 0;
    }
`

export const AppsTitleContainer = styled.div`
    img {
        display: none;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-column: span 2;
        position: relative;
        img {
            display: block;
            float: right;
            position: relative;
        }
    }
`
export const RightColumnContentContainer = styled.div``
