import styled from 'styled-components'

export const Logo = styled.img`
    width: 100%;
`

export const GridContainer = styled.div`
    display: grid;
    /* grid-template-columns: repeat(3, 1fr); */
    grid-template-columns: repeat(${(props) => props.columns || '3'}, 1fr);
    grid-template-rows: ${(props) => props.rows || '1'}fr;
    grid-column-gap: 2rem;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        width: ${(props) => props.width || '80%'};
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: flex;
        flex-direction: column;
        align-items: center;

        ${Logo} {
            margin: 20px 0;
        }
    }
`

export const LogoBlock = styled.div`
    align-items: center;
    display: flex;
    filter: saturate(0%);
    flex-wrap: nowrap;
    justify-content: center;
    opacity: 0.73;
    transition: opacity 475ms ease;
    margin-top: ${(props) => props.marginTop || '5rem'};

    &:hover {
        filter: saturate(100%);
        opacity: 1;
        perspective: 0px;
        transform: perspective(0px);
        transform-origin: 50% 50%;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin-top: 1rem;
        width: ${(props) => props.mobileWidth || '250px'};
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
        &:nth-child(2) {
            padding: 20%;
        }
        &:nth-child(3) {
            padding: 15%;
        }
        ${Logo} {
            max-width: 80%;
        }
    }
`
