const RescueStrings = {
    featuresHeadline: {
        en: 'The advantages of aidminutes.rescue License Version',
        de: 'Die Vorteile der aidminutes.rescue Lizenz Version',
    },
    featuresMobileHeadline: {
        en: 'The advantages of the License Version',
        de: 'Die Vorteile der Lizenz Version',
    },
    featuresPaginationText: {
        en: 'The benefits',
        de: 'Die Vorteile',
    },
    professionalUseHeadline: {
        en: 'Professional use',
        de: 'Professionelle Nutzung',
    },
    professionalUseParagraph: {
        en: 'Your license to use aidminutes.rescue in professional emergency care environments',
        de: 'Ihre Lizenz zum Einsatz von aidminutes.rescue im beruflichen Umfeld der Notfallversorgung',
    },
    administrationHeadline: {
        en: 'Clear user administration',
        de: 'Übersichtliche Nutzer:innen Verwaltung',
    },
    administrationParagraph: {
        en: 'Starting at 5 licenses, you can create and manage accounts for your employees in our clearly arranged customer portal.',
        de: 'Ab 5 Lizenzen können Sie Accounts für Ihre Mitarbeiter:innen in unserem übersichtlichen Kundenportal anlegen und verwalten.',
    },
    protocolHeadline: {
        en: 'Interview transcript',
        de: 'Gesprächsprotokoll',
    },
    protocolParagraph1: {
        en: 'Provides the compact summary of the call history as an overview in SAMPLER and log format.',
        de: 'Bietet die kompakte Zusammenfassung des Gesprächsverlaufs als Übersicht im SAMPLER- und Logformat.',
    },
    protocolParagraph2: {
        en: 'This can be transferred via QR code to emergency services’ common digital deployment protocols.',
        de: 'Es kann per QR-Code in die gängigen digitalen Einsatzprotokolle der Rettungsdienste übertragen werden.',
    },
    offlineHeadline: {
        en: 'Offline capabilities',
        de: 'Offline Fähigkeit',
    },
    offlineParagraph: {
        en: 'Download all audio and use it offline at any time. Suitable for use in locations with poor reception, e.g. in rural areas or underground garages',
        de: 'Alle Audios herunterladen und jederzeit offline nutzen. Geeignet für den Einsatz an Orten mit schlechtem Empfang, z. B. in ländlichen Gegenden oder Tiefgaragen',
    },
    qrNote: {
        en: '(*) The "Export via QR code" feature is currently only available with German-language content.',
        de: '(*) Das Feature “Export via QR-Code” ist derzeit nur mit deutschsprachigen Inhalten verfügbar',
    },
    licenseButton: {
        en: 'Now to the license acquisition',
        de: 'Jetzt zum Lizenzerwerb',
    },
    pricingFrom: {
        en: 'from net',
        de: 'ab',
    },
    pricing: {
        en: '€ 2.59',
        de: '2,59 €',
    },
    pricingTaxing: {
        en: '/ Month',
        de: 'netto / Mon.',
    },
    licenseMinutes: {
        en: 'aid',
        de: 'aid',
    },
    licenseRescue: {
        en: 'minutes.rescue',
        de: 'minutes.rescue',
    },
    license: {
        en: 'Business License',
        de: 'Business Lizenz',
    },
    outsideGermany: {
        en: 'For license inquiries outside Germany, please contact us by e-mail: ',
        de: 'Für Lizenz-Anfragen außerhalb Deutschlands kontaktieren Sie uns bitte per E-Mail: ',
    },
    outsideGermanyContact: {
        en: 'business.rescue@aidminutes.com',
        de: 'business.rescue@aidminutes.com',
    },
    awards: {
        en: 'health awards',
        de: 'Gesundheitspreise',
    },
    videoSectionTitle: {
        en: 'Videos',
        de: 'Filme',
    },
    videoSectionInfo: {
        en: 'We have already received several awards for the quality of our digital communication tools for the e-health sector.',
        de: 'Für die Qualität unserer digitalen Kommunikationstools für den E-Health Bereich wurden wir bereits mehrfach ausgezeichnet.',
    },
    videoSectionAwards: {
        en: 'We are truly well pleased with these renowned awards:',
        de: 'Wir freuen uns sehr über diese renommierten Preise:',
    },
    videoSectionAward_1: {
        en: 'the {{prize_name}} for our aidminutes.rescue app',
        de: 'den {{prize_name}} für unsere aidminutes.rescue App',
    },
    videoSectionAward_2: {
        en: 'the {{prize_name}}',
        de: 'den {{prize_name}}',
    },
    videoSectionMoreVideosOnVimeo: {
        en: 'Find these and more videos about aidminutes on our Vimeo Channel',
        de: 'Diese und weitere Filme und Videos von und über aidminutes finden Sie auch auf unserem Vimeo-Channel',
    },
}

export function getRescueString(key, langCode) {
    return RescueStrings[key]?.[langCode.toLowerCase()] ?? key
}
