import React, { useEffect, createRef } from 'react'
import lottie from 'lottie-web'
import styled from 'styled-components'
import animation from '../../../animations/scroll-down.json'

const AnimationContainer = styled.div`
    bottom: 8vh;
    height: 100px;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    transform: translateX(-50%);
    width: 100px;

    svg {
        width: 100px;
    }
`

export const ScrollDown = () => {
    let animationContainer = createRef()

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            animationData: animation,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        })
        return () => anim.destroy()
    }, [animationContainer])

    return <AnimationContainer ref={animationContainer} />
}
