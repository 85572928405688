import styled from 'styled-components'
import { colors, fontSizes } from '../Theme'

const { smallSize } = fontSizes

const {
    attentionColor,
    attentionColorInactive,
    inactiveItemsColor,
    darkBgColor,
    white,
} = colors
export const ButtonStyled = styled.button`
    display: inline-block;
    background-color: ${(props) =>
        props.enabled === true
            ? props.bgColor === 'red'
                ? attentionColor
                : darkBgColor
            : props.bgColor === 'redlight'
            ? attentionColorInactive
            : inactiveItemsColor};
    border-radius: 8px;
    border: none;
    color: ${(props) => (props.color === 'white' ? white : 'inherit')};
    cursor: ${(props) => (props.enabled === true ? 'pointer' : 'not-allowed')};
    margin: ${(props) => props.margin || '2rem 0'};
    padding: 1.5rem;
    transition: background-color 0.5s ease;
    width: ${(props) => props.buttonWidth || 'auto'};
    @media (max-width: ${({ theme }) => theme.tablet}) {
        text-align: center;
        margin: 1rem 0;
        width: 100%;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        max-width: 320px;
    }
`

export const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
`

export const ButtonText = styled.p`
    height: 100%;
    line-height: 1.6em;
    margin: 0;
    text-align: left;
    width: 100%;
`
export const ButtonTextAddional = styled(ButtonText)`
    font-size: ${smallSize};
`

export const IconContainer = styled.div`
    grid-row: span 2;
    display: flex;
    align-items: center;
    & > svg {
        color: ${(props) => props.iconColor};
        height: 25px;
        width: 25px;
    }
`
