import React from 'react'
import { AlternativeApkLink } from './AlternativeApkLink'
import styled from 'styled-components'

const Systems = styled.ul`
    padding-left: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: center;
    li {
        list-style-type: none;
        line-height: 1.6em;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        text-align: left;
    }
`

const Headline = styled.h4`
    margin-bottom: 0;
    text-align: center;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        text-align: left;
    }
`

export const AlternativeDownload = ({ systems, headline, setVisible }) => {
    if (systems.length <= 0) return null
    return (
        <>
            <Headline>{headline}</Headline>
            <Systems>
                {systems.map((system, index) => (
                    <li key={system + index}>
                        <AlternativeApkLink
                            setVisible={setVisible}
                            systemName={system}
                        />
                    </li>
                ))}
            </Systems>
        </>
    )
}
