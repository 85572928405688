import styled from 'styled-components'
import { colors } from '../Theme'

const { mainFontColor, brightFontColor } = colors

export const Container = styled.aside`
    color: ${(props) => (props.darkMode ? brightFontColor : mainFontColor)};
    position: relative;
    button {
    }
    margin-top: 3rem;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-top: 0;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        margin-top: 3rem;
    }
`

export const Headline = styled.h3`
    color: ${mainFontColor};
    direction: ltr;
    font-weight: 700;
    margin: 0 0 3rem 0;
    font-size: 1rem;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        text-align: center;
        text-transform: uppercase;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        text-align: center;
        text-transform: uppercase;
    }
`

export const AddInfo = styled.p`
    &:before {
        content: 'ⓘ';
        width: 1rem;
        height: 1rem;
        margin: 0 0.5rem 0 0;
        text-transform: none;
    }
`
