import React, { useContext } from 'react'
import styled from 'styled-components'
import { colors } from '../Theme'
import { Link } from 'gatsby'
import { Location } from '@reach/router'
import { getTranslations } from '../../utils'
import { PageContext } from '../../contexts'

const { mainFontColor, brightFontColor } = colors

const ContactFlagText = styled.span`
    color: ${mainFontColor};
    color: ${(props) => (props.darkMode ? 'black' : '')};
    color: ${(props) => (props.darkMode === 'white' ? 'white' : '')};
    transform: rotate(-90deg) translateY(20px);
`

const ContactFlagLine = styled.hr`
    color: ${brightFontColor};
    color: ${(props) => (props.darkMode ? 'black' : '')};
    color: ${(props) => (props.darkMode === 'white' ? 'white' : '')};

    width: 25px;
`

const ContactFlagLink = styled(Link)`
    direction: ltr;
    display: flex;
    position: absolute;
    right: 0;
    text-decoration: none;
    text-transform: uppercase;
    top: 50%;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: none;
    }
`

export const ContactFlag = ({ translations, darkMode }) => {
    const { contact } = getTranslations(translations)
    const { langCode } = useContext(PageContext)
    return (
        <Location>
            {({ location }) => {
                return (
                    <ContactFlagLink
                        to={`${
                            langCode === 'de' ? '' : `/${langCode}`
                        }/#contact`}
                    >
                        <ContactFlagText darkMode={darkMode}>
                            {contact}
                        </ContactFlagText>
                        <ContactFlagLine darkMode={darkMode} />
                    </ContactFlagLink>
                )
            }}
        </Location>
    )
}
