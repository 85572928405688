import theme from 'styled-theming'
import { aidminutesColors, aidminuteWebColors } from './aidminutesStyle'

const {
    aidminutesGrey,
    aidminutesDark,
    aidminutesDarker,
    aidminutesGrannygreen,
    aidminutesLight,
    aidminutesLighter,
    aidminutesNormal,
    aidminutesRedLighter,
    aidminutesDarkest,
    aidminutesDarkestGrey,
    aidminutesLightest,
    aidminutesSatinpearl,
    aidminutesCoralRed,
    aidminutesBlue,
    aidminutesRescueGreen,
    hediBlue,
    mailToLinkBlue,
    aidminutesMapGrey,
} = aidminutesColors

const { aidminutesWebRed, aidminutesWebDarkGreyBlue } = aidminuteWebColors

export const black = theme('mode', {
    light: '#000',
    dark: '#fff',
    highcontrast: '#000',
})
export const white = theme('mode', {
    light: '#fff',
    dark: '#000',
    highcontrast: '#fff',
})
export const semiTransparentBlack = theme('mode', {
    light: 'rgba(0, 0, 0, 0.6)',
    dark: 'rgba(255, 255, 255, 0.7)',
    highcontrast: 'rgba(0, 0, 0, 0.6)',
})
export const semiTransparentWhite = theme('mode', {
    light: 'rgba(255, 255, 255, 0.6)',
    dark: 'rgba(0, 0, 0, 0.6)',
    highcontrast: 'rgba(255, 255, 255, 0.6)',
})
export const primaryColor = theme('mode', {
    light: aidminutesGrannygreen,
    dark: aidminutesGrannygreen,
    highcontrast: aidminutesGrannygreen,
})
export const attentionColor = theme('mode', {
    light: aidminutesWebRed,
    dark: '#FE6669',
    highcontrast: aidminutesWebRed,
})
export const attentionColorInactive = theme('mode', {
    light: aidminutesRedLighter,
    dark: '#00716F',
    highcontrast: aidminutesRedLighter,
})
export const inactiveItemsColor = theme('mode', {
    light: aidminutesGrey,
    dark: '#343432',
    highcontrast: aidminutesGrey,
})
export const mainFontColor = theme('mode', {
    light: aidminutesGrey,
    dark: '#97979A',
    highcontrast: aidminutesDark,
})
export const brightFontColor = theme('mode', {
    light: aidminutesNormal,
    dark: '#CACBCE',
    highcontrast: aidminutesDarker,
})
export const brighterFontColor = theme('mode', {
    light: aidminutesLight,
    dark: aidminutesDark,
    highcontrast: aidminutesDarker,
})
export const darkFontColor = theme('mode', {
    light: aidminutesDarker,
    dark: '#B2B2B4',
    highcontrast: aidminutesDarkest,
})
export const darkerFontColor = theme('mode', {
    light: aidminutesDark,
    dark: '#B2B2B4',
    highcontrast: aidminutesDarkest,
})
export const darkestFontColor = theme('mode', {
    light: aidminutesDarkest,
    dark: '#DCDADC', // TODO improve
    highcontrast: aidminutesDarkest,
})
export const darkFontColorOnLightBackground = theme('mode', {
    light: aidminutesGrey,
    dark: aidminutesDarkest,
    highcontrast: aidminutesDarkest,
})
export const brightMenuLinkColor = theme('mode', {
    light: aidminutesLight,
    dark: '#D7D8DB',
    highcontrast: aidminutesLight,
})
export const brightBgColor = theme('mode', {
    light: aidminutesLighter,
    dark: '#1C2431',
    highcontrast: aidminutesLighter,
})
export const brightButtonBgColor = theme('mode', {
    light: aidminutesLighter,
    dark: aidminutesLighter,
    highcontrast: aidminutesLighter,
})
export const darkBgColor = theme('mode', {
    light: aidminutesDarker,
    dark: '#B2B2B4',
    highcontrast: aidminutesDarker,
})
export const greyishBgColor = theme('mode', {
    light: aidminutesNormal,
    dark: '#2D2D2F',
    highcontrast: aidminutesNormal,
})
export const darkBlueishBgColor = theme('mode', {
    light: aidminutesWebDarkGreyBlue,
    dark: '#0E0D0F',
    highcontrast: aidminutesWebDarkGreyBlue,
})
export const modalOuterBg = theme('mode', {
    light: 'rgba(0, 0, 0, 0.6)',
    dark: 'rgba(255, 255, 255, 0.6)',
    highcontrast: 'rgba(0, 0, 0, 0.6)',
})
export const redCoralBgColor = theme('mode', {
    light: aidminutesCoralRed,
    dark: aidminutesCoralRed,
    highcontrast: aidminutesCoralRed,
})

export const satinpearlBgColor = theme('mode', {
    light: aidminutesSatinpearl,
    dark: aidminutesDarkest,
    highcontrast: aidminutesDarkest,
})
export const toggleBgColor = theme('mode', {
    light: aidminutesDark,
    dark: aidminutesLight,
    highcontrast: aidminutesDarkest,
})
export const lightestBgColor = theme('mode', {
    light: aidminutesLightest,
    dark: aidminutesDarker,
    highcontrast: aidminutesDarker,
})
export const darkestBgColor = theme('mode', {
    light: aidminutesSatinpearl,
    dark: aidminutesDarkestGrey,
    highcontrast: aidminutesDarkestGrey,
})
export const hediBlueBgColor = theme('mode', {
    light: hediBlue,
    dark: hediBlue,
    highcontrast: hediBlue,
})
export const hediBlueFontColor = theme('mode', {
    light: aidminutesBlue,
    dark: aidminutesBlue,
    highcontrast: aidminutesBlue,
})
export const hediSoftLightBlueBgColor = theme('mode', {
    // TODO: dark noch nicht definitiv, replace it when dark mode palette is ready
    light: '#F4F7FA',
    dark: '#0b0805',
    highcontrast: '#000',
})
export const mailToLinkFontBlueColor = theme('mode', {
    light: mailToLinkBlue,
    dark: aidminutesBlue,
    highcontrast: aidminutesBlue,
})
export const SecondarySageGreen = theme('mode', {
    light: aidminutesRescueGreen,
    dark: aidminutesRescueGreen,
    highcontrast: aidminutesRescueGreen,
})
export const mapGreyBgColor = theme('mode', {
    light: aidminutesMapGrey,
    dark: '#000',
    highcontrast: aidminutesMapGrey,
})
export const cloudyGrey = theme('mode', {
    light: aidminutesGrey,
    dark: aidminutesGrey,
    highcontrast: aidminutesGrey,
})

export const colors = {
    black: black,
    white: white,
    semiTransparentBlack: semiTransparentBlack,
    semiTransparentWhite: semiTransparentWhite,
    primaryColor: primaryColor,
    attentionColor: attentionColor,
    attentionColorInactive: attentionColorInactive,
    inactiveItemsColor: inactiveItemsColor,
    mainFontColor: mainFontColor,
    brightFontColor: brightFontColor,
    darkFontColor: darkFontColor,
    darkerFontColor: darkerFontColor,
    darkestFontColor: darkestFontColor,
    brightBgColor: brightBgColor,
    brightMenuLinkColor: brightMenuLinkColor,
    darkBgColor: darkBgColor,
    greyishBgColor: greyishBgColor,
    darkBlueishBgColor: darkBlueishBgColor,
    modalOuterBg: modalOuterBg,
    brightButtonBgColor: brightButtonBgColor,
    satinpearlBgColor: satinpearlBgColor,
    lightestBgColor: lightestBgColor,
    darkestBgColor: darkestBgColor,
    toggleBgColor: toggleBgColor,
    redCoralBgColor: redCoralBgColor,
    darkFontColorOnLightBackground: darkFontColorOnLightBackground,
    hediBlueBgColor: hediBlueBgColor,
    hediBlueFontColor: hediBlueFontColor,
    hediSoftLightBlueBgColor: hediSoftLightBlueBgColor,
    mailToLinkFontBlueColor: mailToLinkFontBlueColor,
    SecondarySageGreen: SecondarySageGreen,
    mapGreyBgColor: mapGreyBgColor,
    cloudyGrey: cloudyGrey,
}
