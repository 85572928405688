const getPaginationNumber = (data) => {
    return data?.fieldPagination?.entity?.fieldPaginationNummer ?? ''
}
const getPaginationText = (data) => {
    return data?.fieldPagination?.entity?.fieldPaginationText ?? ''
}
const getTextBannerImage = (data) => {
    return data?.fieldTextBanner?.entity?.fieldIcon?.imageFile?.publicURL ?? ''
}
const getTextBannerText = (data) => {
    return data?.fieldTextBanner?.entity?.fieldWysiwyg?.value ?? ''
}
const getFluidImage = (data) => {
    return data?.fieldContentImage?.imageFile?.childImageSharp?.fluid ?? ''
}
const getFluidImageDark = (data) => {
    return (
        data?.fieldContentImageDarkmode?.imageFile?.childImageSharp?.fluid ?? ''
    )
}
const getHeadline = (data) => {
    return data?.title ?? ''
}
const getSubHeadline = (data) => {
    return data?.fieldSubHeadline ?? ''
}
const getWysiwygText = (data) => {
    return data?.fieldWysiwyg?.value ?? ''
}
const getWysiwygHeadline = (data) => {
    return data?.fieldMainHeadline?.value ?? ''
}
const getMobileImage = (data) => {
    return data?.fieldBgImage?.imageFile?.childImageSharp?.fluid ?? ''
}
const getAlternativeMobileImage = (data) => {
    return (
        data?.fieldMobileAlternativeImage?.imageFile?.childImageSharp?.fluid ??
        ''
    )
}
const getAlternativeMobileImageSrc = (data) => {
    return (
        data?.fieldMobileAlternativeImage?.imageFile?.childImageSharp?.original
            ?.src ?? ''
    )
}
const getAltAlternativeMobileImage = (data) => {
    return data?.fieldMobileAlternativeImage?.alt || ''
}
const getBgImage = (data) => {
    return data?.fieldBgImage?.imageFile?.childImageSharp?.original?.src ?? ''
}
const getOriginalContentImage = (data) => {
    return (
        data?.fieldContentImage?.imageFile?.childImageSharp?.original?.src ?? ''
    )
}
const getAddHeadline = (data) => {
    return data?.fieldAdditionalHeadline?.value ?? ''
}
const getMobileImageResize = (data) => {
    return data?.fieldBgImage?.imageFile?.childImageSharp?.resize?.src ?? ''
}
const getAddText = (data) => {
    return data?.fieldAdditionalText?.value ?? ''
}
const getAltText = (data) => {
    return data?.fieldContentImage?.alt ?? ''
}
const getPreHeadline = (data) => {
    return data?.fieldPreHeadline ?? ''
}
const getSubText = (data) => {
    return data?.fieldSubtext ?? ''
}
const getAddInfo = (data) => {
    return data?.fieldAdditionalInfo ?? ''
}
const getQuoteCite = (data) => {
    return data?.fieldQuoteEntity?.entity?.fieldCite ?? ''
}
const getQuoteSrc = (data) => {
    return data?.fieldQuoteEntity?.entity?.fieldSrc ?? ''
}
const getQuote = (data) => {
    return data?.fieldQuoteEntity?.entity?.fieldQuote?.value ?? ''
}
const getBottomText = (data) => {
    return data?.fieldBottomText?.value ?? ''
}
const getTextLong = (data) => {
    return data?.fieldTextLong ?? ''
}
const getVimeoVideoID = (data) => {
    return data?.fieldVimeoVideoId ?? ''
}

const getSVGEntities = (data) => {
    let svgentities = {}
    if (data && data.fieldSvgentities && data.fieldSvgentities.length > 0) {
        data.fieldSvgentities.map((item) => {
            return (svgentities[item.entity.fieldSvgid] =
                item.entity.fieldSvgtext)
        })
        return svgentities
    } else {
        return ''
    }
}
const getPartnerLogos = (data) => {
    return data?.fieldPartnerLogos ?? ''
}

const getLink = (data) => {
    return data?.fieldLink ?? ''
}

const getFeatureSlider = (data) => {
    return data?.fieldSlider?.entity?.fieldFeatureImage ?? ''
}

const getFeatureSliderTitle = (data) => {
    return data?.fieldSlider?.entity?.title ?? ''
}

const getButtonSubText = (data) => {
    return data?.fieldButtonSubText?.value ?? ''
}

const getRechtlicheDownloads = (data) => {
    return data?.fieldRechtlicheDownloads ?? []
}

export const getDrupalData = (data) => {
    const alternativeMobileImage = getAlternativeMobileImage(data)
    const headline = getHeadline(data)
    const mainHeadline = getWysiwygHeadline(data)
    const subHeadline = getSubHeadline(data)
    const addHeadline = getAddHeadline(data)
    const preHeadline = getPreHeadline(data)
    const text = getWysiwygText(data)
    const addText = getAddText(data)
    const subText = getSubText(data)
    const paginationNumber = getPaginationNumber(data)
    const paginationText = getPaginationText(data)
    const textBannerImage = getTextBannerImage(data)
    const textBannerText = getTextBannerText(data)
    const bgImage = getBgImage(data)
    const fluidImage = getFluidImage(data)
    const fluidImageDark = getFluidImageDark(data)
    const mobileImage = getMobileImage(data)
    const mobileImageResize = getMobileImageResize(data)
    const altText = getAltText(data)
    const addInfo = getAddInfo(data)
    const quoteCite = getQuoteCite(data)
    const quoteSrc = getQuoteSrc(data)
    const quote = getQuote(data)
    const textLong = getTextLong(data)
    const svgentities = getSVGEntities(data)
    const bottomText = getBottomText(data)
    const vimeoVideoID = getVimeoVideoID(data)
    const partnerLogos = getPartnerLogos(data)
    const link = getLink(data)
    const altAlternativeMobileImage = getAltAlternativeMobileImage(data)
    const imageSrc = getOriginalContentImage(data)
    const altImgSrc = getAlternativeMobileImageSrc(data)
    const buttonSubText = getButtonSubText(data)
    const featureSlider = getFeatureSlider(data)
    const featureSliderSectionTitle = getFeatureSliderTitle(data)
    const rechtlicheDownloads = getRechtlicheDownloads(data)

    return {
        fluidImage: fluidImage,
        fluidImageDark: fluidImageDark,
        headline: headline,
        preHeadline: preHeadline,
        text: text,
        subHeadline: subHeadline,
        mainHeadline: mainHeadline,
        paginationNumber: paginationNumber,
        paginationText: paginationText,
        mobileImage: mobileImage,
        bgImage: bgImage,
        addHeadline: addHeadline,
        mobileImageResize: mobileImageResize,
        addText: addText,
        altText: altText,
        subText: subText,
        addInfo: addInfo,
        quote: quote,
        quoteCite: quoteCite,
        quoteSrc: quoteSrc,
        textLong: textLong,
        svgentities: svgentities,
        bottomText: bottomText,
        vimeoVideoID: vimeoVideoID,
        partnerLogos: partnerLogos,
        link: link,
        alternativeMobileImage: alternativeMobileImage,
        altAlternativeMobileImage: altAlternativeMobileImage,
        altImgSrc: altImgSrc,
        imageSrc: imageSrc,
        buttonSubText: buttonSubText,
        featureSlider: featureSlider,
        featureSliderSectionTitle: featureSliderSectionTitle,
        textBannerImage: textBannerImage,
        textBannerText: textBannerText,
        rechtlicheDownloads: rechtlicheDownloads,
    }
}
