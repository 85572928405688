import styled from 'styled-components'
import { H1, CenterSection } from '../../../basic'

export const StartContainer = styled.div`
    margin-top: 0;
    padding: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 100%;
    position: relative;
    z-index: 90;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-top: 10vh;
        padding: 0;
        padding-right: 5vh;
        padding-left: 5vw;
        width: 50%;
    }
`

export const StartHeading = styled(H1)`
    text-transform: uppercase;
    word-wrap: break-word;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        word-wrap: unset;
    }
`

export const StartSection = styled(CenterSection)``

export const HeroSVG = styled.div`
    svg {
        @media (min-width: ${({ theme }) => theme.tablet}) {
            position: absolute;
            right: ${(props) => (props.direction === 'ltr' ? '1vw' : '')};
            left: ${(props) => (props.direction === 'ltr' ? 'unset' : '1vw')};
            direction: ltr;
            bottom: 6vw;
            width: 70vw;
            height: 70vh;
        }
        max-width: 100%;
        position: relative;
        padding: 0 1rem;

        @media (min-width: ${({ theme }) => theme.tablet}) {
            padding: 0;
        }
    }
`

export const Wrap = styled.div`
    width: 100%;
    align-items: center;
    flex-direction: column;
    height: auto;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        height: 100%;
    }
`
