import React from 'react'
import { Wysiwyg } from '../wysiwygText'

export const BidiText = ({ text, item }) => {
    const newString = text.replace(
        item,
        '<span style="direction: rtl;">' + item + '</span>'
    )
    return (
        <>
            <Wysiwyg text={newString} />
        </>
    )
}
