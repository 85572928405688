import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

export const ContentNativeImage = styled.img`
    width: 100%;
    height: auto;
    img {
        object-fit: contain !important;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        max-height: 50vh;
        /* margin: 3rem 0; */
    }
`

export const ContentImage = styled(GatsbyImage)`
    width: 100%;
    height: auto;
    img {
        object-fit: contain !important;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        max-height: 50vh;
        /* margin: 3rem 0; */
    }
`
export const ContentImageDesktopOnly = styled(ContentImage)`
    display: none;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: block;
    }
`

export const PageImage = styled.img`
    height: auto;
    float: ${(props) => props.float || 'none'};
    max-width: ${(props) => props.width || '100%'};
`

export const PageImageMobileOnly = styled(PageImage)`
    display: block;
    min-width: 100%;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: none;
    }
`

export const ContentImageMobileOnly = styled(ContentImage)`
    display: block;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: none;
    }
`

export const ContentImageMobileOnlyRcp = styled(ContentImage)`
    display: none;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: block;
    }
    @media (width: 801px) {
        display: none;
    }
`

export const ContentImageMobileOnlyRcpAsImgTag = styled.img`
    display: none;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: block;
    }
    @media (width: 801px) {
        display: none;
    }
`
