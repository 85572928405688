const getSrc = (data) => {
    return data?.fieldBtnSrc ?? ''
}
const getDescription = (data) => {
    return data?.fieldBtnDescription ?? ''
}
const getText = (data) => {
    return data?.fieldBtnTitle ?? ''
}
const getType = (data) => {
    return data?.fieldBtnType ?? ''
}
const getColor = (data) => {
    return data?.fieldBtnBgColor ?? ''
}
const getIconColor = (data) => {
    return data?.fieldBtnIconColor ?? ''
}
const getDownloadFile = (data) => {
    return data?.fieldDownloadFile[0]?.downloadFile?.publicURL ?? ''
}
export const downloadButtonData = (data) => {
    const src = getDownloadFile(data) || getSrc(data)
    const description = getDescription(data)
    const text = getText(data)
    const type = getType(data)
    const color = getColor(data)
    const iconColor = getIconColor(data)

    const obj = {
        src: src,
        description: description,
        text: text,
        type: type,
        color: color,
        iconColor: iconColor,
    }

    return obj
}
