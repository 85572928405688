import React from 'react'
import { useInView } from 'react-intersection-observer'
import {
    SectionTitleH1,
    FlowContentGrid,
    SectionBackgroundDesktop,
    SubHeadline,
} from '../../../basic'
import {
    TextContainer,
    TextSubContainer,
    ImageContainer,
    Logo,
    LogosWrapper,
    LogoDescription,
    MobileImageContainer,
} from './HediStart.styled'
import hediAppOnMobile from '../../../../images/hedi_01_app_on_mobiles_mobile.png'
const radialGradientDesktop =
    'radial-gradient( 124.74% 394.24% at -5.89% 39.49%, rgba(254, 254, 255, 0.224) 0%, rgba(223, 229, 237, 0.199332) 0%, rgba(160, 192, 235, 0.0368508) 33.82%, rgba(86, 104, 126, 0.112893) 47.38%, rgba(73, 89, 109, 0.0899897) 69.55%, rgba(64, 91, 126, 0) 100%)'

export const HediStart = ({ data, translations, langCode }) => {
    const [ref, inView, entry] = useInView()

    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    return (
        <SectionBackgroundDesktop
            id="hedi-start"
            background={radialGradientDesktop}
            backgroundPositionY="center"
            backgroundPostionX="center"
            backgroundSize="cover"
        >
            <FlowContentGrid
                columns="50% 50%"
                mobilePadding="0"
                margin="18vh 2vw 5vh 6vw"
                width="auto"
                maxWidthXL="100vw"
            >
                <TextContainer ref={ref} className="not-in-view">
                    <TextSubContainer>
                        <SectionTitleH1 textTransform="uppercase">
                            {data?.title}
                        </SectionTitleH1>
                        <SubHeadline
                            displayMobile
                            paddingMobile="0"
                            displayDesktop
                            margin="1.5rem 0 5rem 0"
                        >
                            {data?.fieldPreHeadline}
                        </SubHeadline>
                        <SubHeadline
                            displayMobile
                            paddingMobile="0"
                            displayDesktop
                            fontWeight="300"
                            margin="0 0 2rem 0"
                        >
                            {data?.fieldSubtext}
                        </SubHeadline>
                    </TextSubContainer>
                    <TextSubContainer>
                        <LogoDescription>
                            {data?.fieldBottomText?.value}
                        </LogoDescription>
                        <LogosWrapper>
                            {data?.fieldPartnerLogos?.map((logo, index) => {
                                const source =
                                    logo?.imageFile?.childImageSharp?.fluid ??
                                    ''
                                const alt = logo?.alt ?? ''
                                return (
                                    <Logo
                                        key={index}
                                        src={source.src}
                                        alt={alt}
                                    />
                                )
                            })}
                        </LogosWrapper>
                    </TextSubContainer>
                </TextContainer>
                <MobileImageContainer>
                    <ImageContainer background={`url(${hediAppOnMobile})`} />
                </MobileImageContainer>
            </FlowContentGrid>
        </SectionBackgroundDesktop>
    )
}
