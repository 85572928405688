import styled from 'styled-components'

export const TextContainer = styled.div`
    padding: 0;
    flex-direction: column;
    height: fit-content;
    @media (min-width: ${({ theme }) => theme.mobileSmall}) {
        padding: 1rem;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: flex-start;
        display: flex;
        padding: 3rem 1rem;
        height: auto;
        justify-content: flex-start;
        letter-spacing: 1.5px;
    }
    @media (min-width: ${({ theme }) => theme.desktopXL}) {
        max-width: 90%;
    }
`
export const Description = styled.div`
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
    margin: 0 0 2rem 0;
    @media (min-width: ${({ theme }) => theme.desktopXL}) {
        max-width: 85%;
    }
`
export const ImageContainerWrapper = styled.div`
    margin: 1rem 2rem;
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        margin: 1rem 0;
        min-height: 90vh;
        height: 100%; // safari
    }
`
export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30vh;
    min-width: 100%;
    background: ${(props) => props.background};
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    background-color: transparent;
    background-size: contain;
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        display: block;
        padding: 1rem;
        min-height: 100%;
    }
`

export const SocialIcon = styled.div`
    height: 2.2rem;
    width: 2.2rem;
    background: ${(props) => props.background};
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
`

export const SocialMediaIconsWrapper = styled.div`
    display: flex;
    gap: 4rem;
    justify-content: center;
    margin: 3rem 0 3rem 0;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        justify-content: flex-start;
        margin: 0 0 3rem 0;
    }
`
