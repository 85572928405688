import styled from 'styled-components'
import { colors } from '../../Theme'
import { StickyHeader, NavLink, NavInner, Nav } from '../Menu.styled'

const { primaryColor, mainFontColor } = colors

export const ProductStickyHeader = styled(StickyHeader)`
    background-color: ${primaryColor};
    position: static;
`

export const ProductNavLink = styled(NavLink)`
    padding: 0 20px;
    &:first-child {
        padding: 20px 20px 20px 0;
    }
    &:last-child {
        @media (min-width: ${({ theme }) => theme.tablet}) {
            padding: 20px 0 20px 20px;
        }
    }
`

export const ProductMenuNameContainer = styled.span`
    color: ${mainFontColor};
    font-weight: 700;
`

export const ProductMenuNavContainer = styled(Nav)`
    display: flex;
`

export const ProductNavInner = styled(NavInner)`
    display: none;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        background-color: ${primaryColor};
        color: ${(props) => (props.darkMode ? 'black' : mainFontColor)};
        direction: ltr;
        display: flex;
        flex-wrap: wrap;
        p:first-child {
            padding-right: 20px;
        }
        ${(props) => (props.darkMode ? '* { color: black !important; }' : '')}
    }
`
