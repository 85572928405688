import styled from 'styled-components'
import { colors } from '../Theme'

const { white } = colors

export const Section = styled.section`
    position: relative;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: flex;
        min-height: 100vh;
    }
`

export const SectionLg = styled(Section)`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: block;
        min-height: unset;
    }

    @media (min-width: ${({ theme }) => theme.desktop}) {
        display: flex;
        min-height: 100vh;
    }
`

export const SectionBackground = styled(Section)`
    flex-direction: ${(props) => props.flexDirection};
    background: url(${(props) => props.imageUrl}) no-repeat center;
    background: ${(props) => props.background || white};
    background-size: ${(props) => props.backgroundSize || 'unset'};
    background-position-x: ${(props) => props.backgroundPostionX || 'unset'};
    background-position-y: ${(props) => props.backgroundPositionY || 'unset'};
`
export const SectionBackgroundDesktopOnly = styled(Section)`
    background: ${(props) => props.mobileBackground || white};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        flex-direction: ${(props) => props.flexDirection || 'initial'};
        background: ${(props) => props.background || white};
        background-size: ${(props) => props.backgroundSize || 'unset'};
        background-position-x: ${(props) =>
            props.backgroundPostionX || 'unset'};
        background-position-y: ${(props) =>
            props.backgroundPostionY || 'unset'};
        background-attachment: ${(props) =>
            props.backgroundAttachment || 'unset'};
    }
`
export const SectionBackgroundOnBreakpoint = styled(Section)`
    background: ${(props) => props.backgroundMobile || white};
    background-size: ${(props) => props.backgroundSize || 'unset'};
    background-position-x: ${(props) => props.backgroundPostionX || 'unset'};
    background-position-y: ${(props) => props.backgroundPostionY || 'unset'};
    background-attachment: ${(props) => props.backgroundAttachment || 'unset'};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        background: ${(props) => props.backgroundDesktop || white};
    }
`

export const SectionBackgroundDesktop = styled(Section)`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        background-size: ${(props) => props.backgroundSize || 'unset'};
        background-position-x: ${(props) =>
            props.backgroundPostionX || 'unset'};
        background-position-y: ${(props) =>
            props.backgroundPostionY || 'unset'};
        background-attachment: ${(props) =>
            props.backgroundAttachment || 'unset'};
        background: ${(props) => props.background || white};
    }
    @media (min-width: ${({ theme }) => theme.desktopXL}) {
        background: ${(props) => props.background || white};
        background-size: ${(props) => props.backgroundSize || 'unset'};
    }
`

export const HighSectionBackground = styled(Section)`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        background: ${(props) => props.background || white};
        background-size: ${(props) => props.backgroundSize || 'unset'};
        height: ${(props) => props.backgroundHeight || 'unset'}; //image height
        @media (max-width: ${({ theme }) => theme.desktop}) {
            min-height: 75rem;
        }
    }
`
export const CenterSection = styled(Section)`
    align-items: flex-start;
    background-image: ${({ theme }) => theme.startGradient};
    height: 100vh;
    justify-content: flex-start;
    padding-top: 0vh;
    position: relative;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: flex;
    }
`
export const BackroundVideoSection = styled.section`
    max-width: 100vw;
    position: relative;
    width: auto;
    background: ${(props) =>
        props.darkMode
            ? '#EDEDED'
            : ''}; /* this is hardcoded from the background video */

    @media (min-width: ${({ theme }) => theme.tablet}) {
        min-height: 100vh;
        height: 100%;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        background: ${(props) => props.bgColor || ''};
        background-size: cover;
        background-position: center;
    }
`
