import React from 'react'
import {
    ProductNavLink,
    ProductMenuNavContainer,
    ProductNavInner,
} from './ProductMenu.styled'

export const ProductMenu = ({ darkMode, productmenuData, pathname }) => {
    const title = productmenuData.title
    const menuItems = productmenuData.fieldMenuitem

    return (
        <ProductNavInner darkMode={darkMode}>
            <div>
                <p>{title}</p>
            </div>
            <div>
                <ProductMenuNavContainer
                    role="navigation"
                    aria-label="pagenavigation"
                >
                    {menuItems &&
                        menuItems.map((menuitem) => {
                            return (
                                <ProductNavLink
                                    key={
                                        menuitem.entity.fieldMainHeadline.value
                                    }
                                    to={`${pathname}${menuitem.entity.fieldPath}`}
                                >
                                    {menuitem.entity.fieldMainHeadline.value}
                                </ProductNavLink>
                            )
                        })}
                </ProductMenuNavContainer>
            </div>
        </ProductNavInner>
    )
}
