import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
    ContactContainer,
    ContactLocationsContainer,
    ContactBlockReachability,
    ContactEmail,
    ContactPhone,
    EmailIcon,
    PhoneIcon,
    ContactAddressContainer,
    SupportEmail,
} from './Contact.styled'
import { Location } from './Location'
import { SectionBackground, SectionTitle } from '../../../basic'
import { StaticMap } from './StaticMap'
import { MobileHeadline } from '../../../MobileHeadline'
import { useInView } from 'react-intersection-observer'
import { useTheme, colors } from '../../../Theme'

export const Contact = ({ contactdata }) => {
    const headline = (contactdata && contactdata.title) || ''
    const phone = '+49 (0)176 717 150 98'
    const phoneLink = '+4917671715098'
    const email = (contactdata && contactdata.fieldEMail) || ''
    const emailLink = (contactdata && contactdata.fieldEMailLink) || ''
    const locations = (contactdata && contactdata.fieldLocations) || ''

    const supportMaildata = useStaticQuery(graphql`
        query SupportMailQuery {
            site {
                siteMetadata {
                    supportMails {
                        address
                    }
                }
            }
        }
    `)

    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    const themeContext = useTheme()

    return (
        <SectionBackground background={colors.mapGreyBgColor} id="contact">
            <MobileHeadline text={headline} />
            <ContactContainer>
                <StaticMap />
                <ContactLocationsContainer ref={ref} className="not-in-view">
                    <SectionTitle
                        dark={themeContext.mode === 'dark'}
                        displayMobile="none"
                    >
                        {headline}
                    </SectionTitle>
                    <ContactBlockReachability>
                        {/* TODO: kombinierte Componenten raus machen */}
                        <address>
                            <ContactPhone
                                darkMode={themeContext.mode === 'dark'}
                            >
                                <PhoneIcon />
                                <a href={`tel:${phoneLink}`}>{phone}</a>
                            </ContactPhone>
                            <ContactEmail
                                darkMode={themeContext.mode === 'dark'}
                            >
                                <EmailIcon />
                                <a href={`mailto:${emailLink}`}>{email}</a>
                            </ContactEmail>
                            {supportMaildata.site.siteMetadata.supportMails.map(
                                (mail, index) => {
                                    return (
                                        <SupportEmail
                                            darkMode={
                                                themeContext.mode === 'dark'
                                            }
                                            key={index}
                                        >
                                            <EmailIcon />
                                            <a href={`mailto:${mail.address}`}>
                                                {mail.address}
                                            </a>
                                        </SupportEmail>
                                    )
                                }
                            )}
                        </address>
                    </ContactBlockReachability>
                    <ContactAddressContainer>
                        {locations.map((location, index) => {
                            return (
                                <Location
                                    darkMode={themeContext.mode === 'dark'}
                                    key={index}
                                    locationdata={location.location}
                                />
                            )
                        })}
                    </ContactAddressContainer>
                </ContactLocationsContainer>
            </ContactContainer>
        </SectionBackground>
    )
}
