import React, { useState } from 'react'
import { ContentGrid, Section, Wysiwyg } from '../../../basic'
import {
    BigParagraph,
    HeadlineParagraph,
    AsideContainer,
    SubParagraph,
    BigContainer,
} from './RescueCovid19Download.styled'
import { SitePagination } from '../../../SitePagination'
import { Modal } from '../../../Modal'
import { MobileHeadline } from '../../../MobileHeadline'
import { ConditionalDownload } from '../../../ConditionalDownload'
import { AsideDownload } from '../../../index'
import {
    getDrupalData,
    softwareDownloadData,
    moreinfoData,
    getTranslations,
} from '../../../../utils'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '../../../Theme'

export const RescueCovid19Download = ({ data, translations }) => {
    // TODO: improve
    const androidData = softwareDownloadData(
        data.fieldSoftwareDownloads[0].entity
    )
    androidData.deviceId = 'apk'

    const moreRescueApp = moreinfoData(data.fieldMore[1].entity)
    const storeLinks = data?.fieldStoreLink ?? ''

    const { langCode, androidlegal, alternativeDownload } =
        getTranslations(translations)

    //TODO: take out fix for languages, which are on the main page
    if (langCode !== 'de') {
        moreRescueApp.path = `/en${moreRescueApp.path}`
    }

    const {
        text,
        mainHeadline,
        addText,
        preHeadline,
        addHeadline,
        paginationNumber,
        paginationText,
    } = getDrupalData(data)

    const [visible, setVisible] = useState(false)

    const handleClose = () => {
        setVisible(false)
    }
    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }
    const themeContext = useTheme()
    return (
        <Section id="rescue-service">
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={mainHeadline}
            />
            <SitePagination
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <ContentGrid
                columns="70% 25%"
                gap="5%"
                margin="22vh 0 10vh"
                className="not-in-view"
                ref={ref}
            >
                <div>
                    <BigContainer marginTop="0">
                        <Wysiwyg fontdependable text={text} />
                    </BigContainer>
                    <BigParagraph
                        className="language-dependent-font"
                        marginTop="3em"
                    >
                        {preHeadline}
                    </BigParagraph>
                    <HeadlineParagraph className="language-dependent-font">
                        {mainHeadline}
                    </HeadlineParagraph>
                    <SubParagraph marginTop="2em">
                        <Wysiwyg fontdependable text={addText} />
                    </SubParagraph>
                </div>
                <AsideContainer darkMode={themeContext.mode === 'dark'}>
                    <AsideDownload
                        headline={addHeadline}
                        storelinks={storeLinks}
                        apksHeadline={alternativeDownload}
                        setVisible={setVisible}
                        androidlegal={androidlegal}
                    />
                </AsideContainer>
            </ContentGrid>
            <Modal
                show={visible}
                handleClose={handleClose}
                translations={translations}
            >
                <ConditionalDownload
                    data={androidData}
                    langCode={langCode}
                    handleClose={handleClose}
                />
            </Modal>
        </Section>
    )
}
