import { useEffect } from 'react'

export const useOnClickOutsideSubmenuTrigger = (ref, handler, trigger) => {
    useEffect(() => {
        const listener = (event) => {
            if (
                (!ref.current || ref.current.contains(event.target)) &&
                !trigger
            ) {
                return
            }

            handler(event)
        }
        document.addEventListener('mousedown', listener)
        return () => {
            document.removeEventListener('mousedown', listener)
        }
    }, [ref, handler, trigger])
}
