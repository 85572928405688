const HEXAGON_RATIO = 1.733333333333
export const HoneyCombHeight = 208 // Vielfache von 52 funktionieren sauber
export const HoneyCombWidth = Math.floor(HoneyCombHeight / HEXAGON_RATIO)
export const HoneyCombHeightMobile = 156
export const HoneyCombWidthMobile = Math.floor(
    HoneyCombHeightMobile / HEXAGON_RATIO
)

export const HoneyCompGridConfig = [
    {
        id: 1,
        row: {
            desktop: 1,
            tablet: 1,
            mobile: 1,
        },
        column: {
            desktop: 1,
            tablet: 1,
            mobile: 1,
        },
        bgColor: {
            desktop: 'grey',
            tablet: 'grey',
            mobile: 'grey',
        },
    },
    {
        id: 2,
        row: {
            desktop: 1,
            tablet: 3,
            mobile: 2,
        },
        column: {
            desktop: 13,
            tablet: 1,
            mobile: 4,
        },
        bgColor: {
            desktop: 'grey',
            tablet: 'normal',
            mobile: 'grey',
        },
    },
    {
        id: 3,
        row: {
            desktop: 2,
            tablet: 1,
            mobile: 3,
        },
        column: {
            desktop: 4,
            tablet: 7,
            mobile: 1,
        },
        bgColor: {
            desktop: 'normal',
            tablet: 'grey',
            mobile: 'normal',
        },
    },
    {
        id: 4,
        row: {
            desktop: 2,
            tablet: 3,
            mobile: 4,
        },
        column: {
            desktop: 10,
            tablet: 7,
            mobile: 4,
        },
        bgColor: {
            desktop: 'normal',
            tablet: 'normal',
            mobile: 'normal',
        },
    },
    {
        id: 5,
        row: {
            desktop: 3,
            tablet: 4,
            mobile: 5,
        },
        column: {
            desktop: 7,
            tablet: 4,
            mobile: 1,
        },
        bgColor: {
            desktop: 'grey',
            tablet: 'grey',
            mobile: 'grey',
        },
    },
    // Aidminutes HoneyComb
    {
        id: 99,
        row: {
            desktop: 1,
            tablet: 2,
            mobile: 6,
        },
        column: {
            desktop: 7,
            tablet: 4,
            mobile: 4,
        },
        bgColor: {
            desktop: 'grannygreen',
            tablet: 'grannygreen',
            mobile: 'grannygreen',
        },
    },
]
