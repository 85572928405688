import React from 'react'
import {
    BackroundVideoSection,
    PageImageMobileOnly,
    SectionTitleH1,
    Wysiwyg,
    SectionBackgroundDesktopOnly,
    ContentGrid,
} from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import { BackgroundVideo } from '../../../BackgroundVideo'
import {
    RescupeAppsH3,
    VideoContainer,
    TextContainer,
} from './RescueApp.styled'
import { ContactFlag } from '../../../ContactFlag'
import { MobileHeadline } from '../../../MobileHeadline'
import { getDrupalData } from '../../../../utils'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '../../../Theme'
import { TextBanner } from '../../../TextBanner'
import videoIphone from '../../../../images/IPhone_Rescue.mp4'
import videoRescueHandy from '../../../../images/Video_Rescue_Handy.png'

export const RescueApp = ({ data, translations }) => {
    const {
        mainHeadline,
        subHeadline,
        text,
        paginationNumber,
        paginationText,
        textBannerText,
        textBannerImage,
    } = getDrupalData(data)

    // TODO CC
    // TODO remove this when file can be uploaded to drupal
    const videodata = [
        {
            downloadFile: {
                publicURL: videoIphone,
            },
            entity: {
                filemime: 'video/mp4',
            },
        },
    ]

    // TODO put me back after data has been added to Drupal
    // const videodata = (data && data.fieldVideos) || ''

    const poster =
        (data &&
            data.fieldContentImage &&
            data.fieldContentImage.imageFile &&
            data.fieldContentImage.imageFile.publicURL) ||
        ''
    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }
    const themeContext = useTheme()
    return (
        <SectionBackgroundDesktopOnly
            darkMode={themeContext.mode === 'dark'}
            background="#f2f1f4"
            id="emergency-communication"
        >
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={mainHeadline}
            />
            <PageImageMobileOnly src={poster} alt={mainHeadline} />
            <SitePagination
                darkMode={themeContext.mode === 'dark'}
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <ContentGrid
                columns="40% 60%"
                margin="0"
                padding="0 0 0 5vw"
                mobilePadding="0"
                alignItems="start"
            >
                <TextContainer
                    justify="start"
                    alignItems="center"
                    margin="22vh 0 10vh"
                    className="not-in-view"
                    ref={ref}
                >
                    <TextBanner
                        image={textBannerImage}
                        text={textBannerText}
                        dark
                    />
                    <SectionTitleH1
                        colorOnBreakpointTablet={
                            themeContext.mode === 'dark' ? 'black' : ''
                        }
                        dark
                        displayMobile="none"
                        fontSize="3vw"
                    >
                        <Wysiwyg text={mainHeadline} />
                    </SectionTitleH1>
                    <RescupeAppsH3
                        darkMode={themeContext.mode === 'dark'}
                        colorOnBreakpointTablet={
                            themeContext.mode === 'dark' ? 'black' : ''
                        }
                    >
                        {subHeadline}
                    </RescupeAppsH3>
                    <div>
                        <Wysiwyg
                            colorOnBreakpointTablet={
                                themeContext.mode === 'dark' ? 'black' : ''
                            }
                            text={text}
                        />
                    </div>
                </TextContainer>
                <VideoContainer>
                    <BackroundVideoSection
                        darkMode={themeContext.mode === 'dark'}
                        bgColor={({ theme }) => theme.backgroundGradient}
                    >
                        <BackgroundVideo
                            darkMode={themeContext.mode === 'dark'}
                            data={videodata}
                            poster={videoRescueHandy} // TODO add data to Drupal
                            overlayPosition="relative"
                        />
                    </BackroundVideoSection>
                </VideoContainer>
            </ContentGrid>
            <ContactFlag translations={translations} />
        </SectionBackgroundDesktopOnly>
    )
}
