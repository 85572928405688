import React from 'react'
import { SectionTitleH1, SectionBackgroundDesktop } from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import { useInView } from 'react-intersection-observer'
import Professionelle_Nutzung from '../../../../images/Professionelle_Nutzung.png'
import Gespraechsprotokoll from '../../../../images/Gespraechsprotokoll.png'
import Offline from '../../../../images/Offline.png'
import Verwaltung from '../../../../images/Verwaltung.png'
import RescueFeaturesTop from '../../../../images/rescue_features_top.png'
import RescueFeaturesBottom from '../../../../images/rescue_features_bottom.png'
import { MobileHeadline } from '../../../MobileHeadline'
import {
    Feature,
    FeatureColumns,
    FeatureGridLeft,
    FeatureGridRight,
    FeatureHeadline,
    FeatureImage,
    FeatureParagraph,
    FeaturesList,
    FeatureText,
    LanguageInfo,
    AllFeaturesBgImage,
} from './RescueFeatures.styled'
import { getRescueString } from '../rescueStrings'
import { useTheme } from '../../../Theme'
import { getTranslations } from '../../../../utils'

export const RescueFeatures = ({ prefix, data, translations }) => {
    /*
    const {
        mainHeadline,
        subHeadline,
        text,
        paginationNumber,
        paginationText,
    } = getDrupalData(data)
*/
    const { langCode } = getTranslations(translations)
    const mainHeadline = getRescueString('featuresHeadline', langCode)
    const mobileMainHeadline = getRescueString(
        'featuresMobileHeadline',
        langCode
    )

    const paginationNumber = '02'
    const paginationText = getRescueString('featuresPaginationText', langCode)
    // TODO CC
    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    const themeContext = useTheme()

    return (
        <SectionBackgroundDesktop
            id="rescue-features"
            style={{ backgroundColor: '#e9e9ec' }}
        >
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={mobileMainHeadline}
            />
            <SitePagination
                darkMode={themeContext.mode === 'dark'}
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <FeatureColumns
                topBgImage={RescueFeaturesTop}
                bottomBgImage={RescueFeaturesBottom}
                ref={ref}
            >
                <FeatureGridLeft ref={ref}>
                    <SectionTitleH1
                        displayMobile="none"
                        greyish
                        fontSize="2rem"
                    >
                        {mainHeadline}
                    </SectionTitleH1>
                    <FeaturesList>
                        <Feature>
                            <FeatureImage image={Professionelle_Nutzung} />
                            <FeatureText>
                                <FeatureHeadline>
                                    {getRescueString(
                                        'professionalUseHeadline',
                                        langCode
                                    )}
                                </FeatureHeadline>
                                <FeatureParagraph>
                                    {getRescueString(
                                        'professionalUseParagraph',
                                        langCode
                                    )}
                                </FeatureParagraph>
                            </FeatureText>
                        </Feature>
                        <Feature>
                            <FeatureImage image={Gespraechsprotokoll} />
                            <FeatureText>
                                <FeatureHeadline>
                                    {getRescueString(
                                        'administrationHeadline',
                                        langCode
                                    )}
                                </FeatureHeadline>
                                <FeatureParagraph>
                                    {getRescueString(
                                        'administrationParagraph',
                                        langCode
                                    )}
                                </FeatureParagraph>
                            </FeatureText>
                        </Feature>
                        <Feature>
                            <FeatureImage image={Offline} />
                            <FeatureText>
                                <FeatureHeadline>
                                    {getRescueString(
                                        'protocolHeadline',
                                        langCode
                                    )}
                                </FeatureHeadline>
                                <FeatureParagraph>
                                    {getRescueString(
                                        'protocolParagraph1',
                                        langCode
                                    )}
                                </FeatureParagraph>
                                <FeatureParagraph>
                                    {getRescueString(
                                        'protocolParagraph2',
                                        langCode
                                    )}
                                </FeatureParagraph>
                            </FeatureText>
                        </Feature>
                        <Feature>
                            <FeatureImage image={Verwaltung} />
                            <FeatureText>
                                <FeatureHeadline>
                                    {getRescueString(
                                        'offlineHeadline',
                                        langCode
                                    )}
                                </FeatureHeadline>
                                <FeatureParagraph>
                                    {getRescueString(
                                        'offlineParagraph',
                                        langCode
                                    )}
                                </FeatureParagraph>
                            </FeatureText>
                        </Feature>
                    </FeaturesList>
                    <LanguageInfo>
                        {getRescueString('qrNote', langCode)}
                    </LanguageInfo>
                </FeatureGridLeft>
                <FeatureGridRight>
                    <AllFeaturesBgImage
                        image={RescueFeaturesTop}
                        position="top"
                    />
                    <AllFeaturesBgImage
                        image={RescueFeaturesBottom}
                        position="bottom"
                    />
                </FeatureGridRight>
            </FeatureColumns>
        </SectionBackgroundDesktop>
    )
}
