/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from '../Theme'
import { GlobalStyles } from '../basic/global'
import { Header } from './Header'
import { Footer } from './Footer'
import { getTranslations } from '../../utils'
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks'
import { PageProvider } from '../../contexts'

export const Layout = ({
    path,
    children,
    productmenuData,
    translations,
    pageContext,
    topmenu,
    submenu,
    footerdata,
    mobileBannerData,
}) => {
    const prefix = pageContext?.prefix ?? ''
    const direction = pageContext?.direction ?? ''
    const submenuData = submenu ?? []
    const topmenuData = topmenu ?? []
    const { langCode } = getTranslations(translations)
    const mobileBanner = mobileBannerData ?? []

    setDefaultBreakpoints([{ tablet: 801 }])
    return (
        <ThemeContext>
            <GlobalStyles direction={direction} langCode={langCode} />
            <BreakpointProvider>
                <PageProvider lang={langCode}>
                    <Header
                        languagePath={path}
                        productmenuData={productmenuData}
                        translations={translations}
                        pageContext={pageContext}
                        topmenu={topmenuData}
                        submenu={submenuData}
                        mobileBannerData={mobileBanner}
                    />
                    <main>{children}</main>
                    <Footer
                        data={footerdata}
                        prefix={prefix}
                        translations={translations}
                    />
                </PageProvider>
            </BreakpointProvider>
        </ThemeContext>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}
