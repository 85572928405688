import styled from 'styled-components'
import { colors, fontSizes } from '../Theme'

const {
    white,
    mainFontColor,
    brightFontColor,
    darkFontColorOnLightBackground,
    greyishBgColor,
    darkerFontColor,
} = colors

const { h1Size, h2Size, h3Size, h4Size, h5Size } = fontSizes

export const H1 = styled.h1`
    color: ${white};
    font-size: ${h1Size};
    font-weight: 700;
    /* TODO: verallgemeinern */
    line-height: 1.36em;
    margin-top: 1.25rem;
    margin-bottom: 0.625rem;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h3Size};
    }
`

const H2Basic = styled.h2`
    font-size: ${(props) => props.fontSize || h2Size};
    font-weight: 700;
    /* TODO: verallgemeinern */
    line-height: 1.337em;
    text-align: ${(props) => props.textAlign || 'inherit'};
    margin-bottom: ${(props) => props.marginBottom || '1em'};
    @media (max-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h4Size};
        margin: 0 0 3rem 0;
    }

    /* Setzt die Farbe ab Tablet-Größe */
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${(props) => props.colorOnBreakpointTablet};
    }

    /* Setzt die Farbe ab Desktop-Größe */
    @media (min-width: ${({ theme }) => theme.desktop}) {
        color: ${(props) => props.colorOnBreakpointDesktop};
    }
`

const H3SectionBasic = styled.h3`
    font-size: ${h3Size};
    font-weight: 700;
    /* TODO: verallgemeinern */
    line-height: 1em;
    margin-top: ${(props) => props.marginTop || '0'};
    @media (max-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h5Size};
        margin: 0 0 3rem 0;
    }
`

export const H2 = styled(H2Basic)`
    color: ${mainFontColor};
    text-transform: uppercase;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: ${(props) => props.displayMobile || 'block'};
    }
`
export const H2Wysiwyg = styled.h2`
    font-size: ${h2Size};
    font-weight: ${({ fontWeight }) => fontWeight || 'inherit'};
    color: ${(props) =>
        props.color === 'normal' ? brightFontColor : mainFontColor};
    /* TODO: verallgemeinern */
    line-height: 1.4em;
    margin: 1.6em 0 1em;
    margin-bottom: ${(props) => props.marginBottom || '0'};
    margin-top: ${(props) => props.marginTop || '0'};
    text-transform: ${(props) => props.textTransform || 'inherit'};
    word-wrap: break-word;

    @media (max-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h4Size};
        margin: 0 0 3rem 0;
        display: ${(props) => props.displayMobile || 'block'};
    }
`
export const H1Wysiwyg = styled.h1`
    display: ${(props) => props.displayMobile || 'block'};
    font-weight: inherit;
    color: ${mainFontColor};
    /* TODO: verallgemeinern */
    line-height: 1em;
    margin-top: ${(props) => props.marginTop || '0'};
    text-transform: ${(props) => props.textTransform || 'inherit'};

    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h3Size};
        margin: 1.6em 0 1em 0;
        display: flex;
    }
    @media (min-width: 801px) and (max-width: 900px) {
        font-size: calc(${h3Size} * 0.9);
    }
    @media (min-width: ${({ theme }) => theme.desktop}) {
        font-size: ${h1Size};
        margin: 1.6em 0 1em;
    }
`

export const SectionTitleH1 = styled.h1`
    color: ${(props) => (props.dark ? mainFontColor : brightFontColor)};
    color: ${(props) => props.greyish && greyishBgColor};
    font-size: ${(props) => props.fontSize || h1Size};
    font-weight: 700;
    /* TODO: verallgemeinern */
    line-height: 1.337em;
    margin-top: ${(props) => props.marginTop || '0'};
    margin-bottom: ${(props) => props.marginBottom || 'inherit'};
    text-transform: ${(props) => props.textTransform || 'inherit'};
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: ${(props) => props.displayMobile || 'block'};
        font-size: ${h4Size};
        margin-bottom: 0;
        margin: ${(props) => props.marginMobile || '0.5em 0'};
    }
`

export const SectionTitle = styled(H2Basic)`
    color: ${(props) => (props.dark ? brightFontColor : brightFontColor)};
    color: ${(props) => (props.darkMode ? 'black' : '')};
    color: ${(props) => (props.darkMode === 'white' ? 'white' : '')};
    margin-top: ${(props) => props.marginTop || '0'};
    text-transform: ${(props) => props.textTransform || 'inherit'};
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: ${(props) => props.displayMobile || 'block'};
    }
`
export const SubHeadlineAnamnesis = styled(H2Basic)`
    display: ${(props) => props.displayMobile || 'block'};
    text-transform: ${(props) => props.textTransformMobile || 'lowercase'};
    color: ${(props) => props.fontColorMobile || darkerFontColor};
    margin: ${(props) => props.customMargin || '2rem 0'};
    font-weight: ${({ fontWeight }) => fontWeight || '700'};
    font-size: ${h5Size};
    line-height: 1.337em;
    letter-spacing: 0.05rem;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: ${(props) => props.display || 'flex'};
        text-transform: ${(props) => props.textTransform || 'inherit'};
        color: ${(props) =>
            props.darkMode === true
                ? 'black'
                : props.darkMode === false
                ? darkerFontColor
                : props.fontColorDesktop};
        max-width: ${(props) => props.desktopWidth || '100%'};
        font-size: ${(props) => props.fontSize || h3Size};
        line-height: 3rem;
    }
    @media (min-width: ${({ theme }) => theme.desktopXL}) {
        font-size: ${(props) => props.fontSize || '2rem'};
    }
`
export const SectionTitleAnamnesis = styled(SubHeadlineAnamnesis)`
    display: flex;
    text-transform: none;
    color: ${(props) => props.fontColorMobile || brightFontColor};
    font-size: ${h4Size};
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        text-transform: uppercase;
        color: ${(props) =>
            props.darkMode === true
                ? 'black'
                : props.darkMode === false
                ? brightFontColor
                : props.fontColorDesktop};
        font-size: ${h2Size};
        line-height: 1.337em;
        margin-top: ${(props) => props.marginTop};
        margin-bottom: ${(props) => props.marginBottom};
        padding-top: ${(props) => props.paddingTop};
    }
`
export const SectionTitleClinical = styled(SectionTitle)`
    margin-bottom: 0;
    color: ${(props) =>
        props.dark
            ? darkFontColorOnLightBackground
            : darkFontColorOnLightBackground};
`

export const SectionH3Title = styled(H3SectionBasic)`
    color: ${(props) => (props.dark ? mainFontColor : brightFontColor)};
    color: ${(props) => (props.darkMode ? 'black' : '')};
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: ${(props) => props.displayMobile || 'block'};
    }
`
export const H3 = styled.h3`
    color: ${mainFontColor};
    font-size: ${h4Size};
    font-weight: 400;
    /* TODO: verallgemeinern */
    line-height: 1.666em;
    margin-top: 10px;
    margin-bottom: 25px;
`
