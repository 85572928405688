import React from 'react'
import { getDrupalData } from '../../../../utils'
import {
    Wysiwyg,
    MobileHeadline,
    SitePagination,
    ContentGrid,
    SectionBackgroundDesktopOnly,
    ContentImageMobileOnly,
    BigText,
} from '../../../index'
import { Title, ImageDesktopOnly } from './VaccinateFeatures.styled'

export const VaccinateFeatures = ({ data }) => {
    const {
        mainHeadline,
        text,
        paginationNumber,
        paginationText,
        fluidImage,
        bgImage,
        alternativeMobileImage,
        altAlternativeMobileImage,
        altText,
    } = getDrupalData(data)

    return (
        <SectionBackgroundDesktopOnly
            background={`url(${bgImage}) no-repeat #72b742`}
            backgroundSize="cover"
            id="vaccinate-features"
        >
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={paginationText}
                primary
            />
            <ContentImageMobileOnly
                image={alternativeMobileImage}
                alt={altAlternativeMobileImage}
            />
            <SitePagination
                pageNumber={paginationNumber}
                pageText={paginationText}
                fontColor="white"
            />
            <ContentGrid columns="40% 50%" gap="10%" margin="22vh 0 15vh">
                <ImageDesktopOnly
                    objecFit="contain"
                    fluid={fluidImage}
                    alt={altText}
                />
                <div>
                    <Title textTransform="uppercase" marginBottom="6rem">
                        {mainHeadline}
                    </Title>
                    {/* TODO textsize */}
                    <BigText fontColor="white">
                        <Wysiwyg text={text} />
                    </BigText>
                </div>
                {/* TODO alt */}
                {/* TODO Imagefont */}
            </ContentGrid>
        </SectionBackgroundDesktopOnly>
    )
}
