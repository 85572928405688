import styled from 'styled-components'
import { fontSizes, colors } from '../Theme'

const { h4Size } = fontSizes
const { white, mainFontColor } = colors

export const BigText = styled.div`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${(props) =>
            props.fontColor === 'white' ? white : mainFontColor};
        li {
            font-size: ${h4Size};
        }
    }
    li {
        font-weight: 400;
        line-height: 1.6em;

        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        a {
            color: ${(props) =>
                props.fontColor === 'white' ? white : 'inherit'};
        }
    }
`
