import React, { createContext, useState, useEffect, useCallback } from 'react'
import { createCartItem, priceByID } from './CheckoutContext/price'
import hardCodedData from './hardCodedData'

export { formatPrice } from './CheckoutContext/price'

/**
 * @typedef checkoutState
 * @property {Number} activeStep
 * @property {'price' | 'feature'} activeTab
 * @property {'person' | 'device'} activeLicenseType
 * @property {Boolean} mobileCartVisible
 * @property {Array} cart
 * @property {'full' | 'smart'} sideBarMode
 * @property {Object} total
 * @property {Object} pricingData
 * @property {Object} priceSelection
 */
const initialState = {
    activeStep: 0, // 0-4, die verschiedenen Checkoutsteps
    activeTab: 'feature', // bzw. 'price'
    activeMobileLicenseTab: 'device', // oder 'person'
    mobileCartVisible: false,
    cart: [], // s.u.
    sideBarMode: 'full', // 'smart' oder 'full'
    // TODO maybe rename to cartSum, cartTotal
    total: {
        unit: ' €',
        netto: 0, // generiert durch Berechnung aller Warenkorbelemente (s.u.)
        brutto: 0, // generiert durch Berechnung aller Warenkorbelemente (s.u.)
        // ... weitere Felder, die Stripe liefert. z.B. Höhe der USt. in % und in €
    },
    pricingData: {
        person: undefined,
        device: undefined,
    },
    priceSelection: {
        person: {
            maximum: 500,
            interval: 'month',
            quantity: 1,
        },
        device: {
            // for marketing purposes, we use a lower value for device licenses
            maximum: 100,
            interval: 'year',
            quantity: 1,
        },
    },
}

const priceIDsPerson = [
    'price_1JhDrFAq9nrUIRDW0JCrwTgM',
    'price_1JhDwkAq9nrUIRDWVHWP3FXr',
]
// ,
// priceIDsDevices = ['price_1L1YVCAq9nrUIRDWyxQskdYv'] // commented because it is an unused variable

/* const priceIDsPerson = process.env.GATSBY_USE_CUSTOMER_PORTAL
    ? ['price_1JDUKbAq9nrUIRDW0pm5VjiC', 'price_1JP7aBAq9nrUIRDWqjLg0FcO']
    : ['price_1JhDrFAq9nrUIRDW0JCrwTgM', 'price_1JhDwkAq9nrUIRDWVHWP3FXr']

const priceIDsDevices = process.env.GATSBY_USE_CUSTOMER_PORTAL
    ? ['price_1LFzI4Aq9nrUIRDWMnZ8XPKB']
    : ['price_1L1YVCAq9nrUIRDWyxQskdYv'] */

export const CheckoutContext = createContext()
// console.log(hardCodedData)
export const CheckoutProvider = ({ children, langCode }) => {
    const [checkoutState, setCheckoutState] = useState(initialState)
    // we could generalize this if more licenses are added
    // const { data: priceData, loading } = useQuery(AllPriceInfosQuery, {
    //     variables: { langCode: langCode.toLowerCase() },
    // })

    // insert price info into state once it is loaded by the query
    useEffect(() => {
        /*  HACK
            https://aidminutes.atlassian.net/browse/UN-135
            Use me in in case we can't deploy customer portal.

            These hardcoded prices are used because the latest rescue customer portal couldn't be deployed.
            They probably won't hurt, but they could become different as the prices in the customer portal.
        */
        const deviceLicensesBackupPlan = {
            allPriceInfos: [
                {
                    name: 'aidminutes.rescue (Volumen)',
                    unitLabel: 'Lizenzen',
                    features: [
                        'Lizenz für professionellen Einsatz',
                        'Je Lizenz nutzbar auf bis zu 3 Devices',
                        'Gesprächsprotokoll und Export-Feature',
                        'Offline-Fähigkeit',
                        'ab 5 Lizenzen: übersichtliche Nutzer:innen-Verwaltung',
                    ],
                    id: 'price_1LFzI4Aq9nrUIRDWMnZ8XPKB',
                    description: 'Im Notfall nicht sprachlos',
                    interval: 'year',
                    tiers: [
                        {
                            max: null,
                            min: 1,
                            unitAmount: 8640,
                        },
                    ],
                },
            ],
        }
        if (
            hardCodedData &&
            Object.values(checkoutState.pricingData).every(
                (e) => e === undefined
            )
        ) {
            setCheckoutState({
                ...checkoutState,
                pricingData: {
                    ...checkoutState.pricingData,
                    person: priceByID(hardCodedData, priceIDsPerson),
                    device: deviceLicensesBackupPlan, // TODO `priceByID(priceData, priceIDsDevices)` can be inserted here – see above.
                },
            })
        }
    }, [checkoutState])

    function setActiveTabFeature() {
        setCheckoutState({
            ...checkoutState,
            activeTab: 'feature',
        })
    }

    function setActiveTabPrice() {
        setCheckoutState({
            ...checkoutState,
            activeTab: 'price',
        })
    }

    function setActiveMobileLicenseTabDevice() {
        setCheckoutState({
            ...checkoutState,
            activeMobileLicenseTab: 'device',
        })
    }

    function setActiveMobileLicenseTabPerson() {
        setCheckoutState({
            ...checkoutState,
            activeMobileLicenseTab: 'person',
        })
    }

    const hideMobileCartVisible = useCallback(() => {
        setCheckoutState({
            ...checkoutState,
            mobileCartVisible: false,
        })
    }, [checkoutState])

    function showMobileCartVisible() {
        setCheckoutState({
            ...checkoutState,
            mobileCartVisible: true,
        })
    }

    // once the checkoutState changes, total price is recalculated
    const updateTotalPrice = useCallback(() => {
        const { total } = checkoutState
        const { netto, brutto } = total

        const newNetto = checkoutState.cart.reduce(
            (acc, cur) => acc + cur.netto,
            0
        )
        const newBrutto = checkoutState.cart.reduce(
            (acc, cur) => acc + cur.brutto,
            0
        )

        if (netto !== newNetto || brutto !== newBrutto) {
            setCheckoutState({
                ...checkoutState,
                total: {
                    ...checkoutState.total,
                    netto: newNetto,
                    brutto: newBrutto,
                },
            })
        }
    }, [checkoutState, setCheckoutState])

    // once the cart changes, activeStep is increased
    const updateActiveStep = useCallback(() => {
        const { activeStep } = checkoutState
        const nextActiveStep = checkoutState.cart.length > 0 ? 1 : 0

        if (activeStep !== nextActiveStep) {
            setCheckoutState({
                ...checkoutState,
                activeStep: nextActiveStep,
            })
        }
    }, [checkoutState, setCheckoutState])

    function addToCart({
        quantity,
        interval,
        currentPrice,
        licenseType,
        forceOnlyOneCartItem,
    }) {
        const cartItem = createCartItem({
            quantity,
            interval,
            currentPrice,
            licenseType,
        })

        if (forceOnlyOneCartItem) {
            setCheckoutState({
                ...checkoutState,
                mobileCartVisible: true,
                cart: [cartItem],
                activeMobileLicenseTab: licenseType,
            })
        } else {
            if (isCartItemUnique(cartItem))
                setCheckoutState({
                    ...checkoutState,
                    mobileCartVisible: true,
                    cart: [...checkoutState.cart, cartItem],
                })
        }
    }

    function removeFromCart(index) {
        setCheckoutState({
            ...checkoutState,
            cart: checkoutState.cart.filter((_, i) => i !== index),
        })
    }

    function isCartItemUnique(cartItem) {
        return checkoutState.cart.every(
            (item) =>
                item.interval !== cartItem.interval ||
                item.licenseType !== cartItem.licenseType
        )
    }

    useEffect(() => {
        if (
            checkoutState.cart.length === 0 &&
            checkoutState.mobileCartVisible === true
        ) {
            hideMobileCartVisible()
        }
    }, [hideMobileCartVisible, checkoutState])

    useEffect(() => {
        updateActiveStep()
    }, [checkoutState.cart, updateActiveStep])

    useEffect(() => {
        updateTotalPrice()
    }, [checkoutState.cart, updateTotalPrice])

    return (
        <CheckoutContext.Provider
            value={{
                checkoutState,
                addToCart,
                removeFromCart,
                setActiveMobileLicenseTabDevice,
                setActiveMobileLicenseTabPerson,
                isCartItemUnique,
                setCheckoutState,
                hideMobileCartVisible,
                showMobileCartVisible,
                setActiveTabFeature,
                setActiveTabPrice,
            }}
        >
            {children}
        </CheckoutContext.Provider>
    )
}
