import theme from 'styled-theming'
import { aidminutesBasicFontSizes } from './aidminutesBasicFontSizes'

const {
    basicFontSize,
    h1FontSize,
    h2FontSize,
    h3FontSize,
    h4FontSize,
    h5FontSize,
    smallFontSize,
    xsmallFontSize,
    subHeadlineSmallFontSize,
    technicalFontSize,
    minimalSmaller,
    minimalBigger,
} = aidminutesBasicFontSizes

const baseFontSize = theme('size', {
    normal: basicFontSize,
    bigger: '18px',
})

const h1Size = theme('size', {
    normal: h1FontSize,
    bigger: h1FontSize,
})

const h2Size = theme('size', {
    normal: h2FontSize,
    bigger: h2FontSize,
})
const h3Size = theme('size', {
    normal: h3FontSize,
    bigger: h3FontSize,
})
const h4Size = theme('size', {
    normal: h4FontSize,
    bigger: h4FontSize,
})
const h5Size = theme('size', {
    normal: h5FontSize,
    bigger: h5FontSize,
})
const techSize = theme('size', {
    normal: technicalFontSize,
    bigger: technicalFontSize,
})

const subHeadlineSize = theme('size', {
    normal: subHeadlineSmallFontSize,
    bigger: subHeadlineSmallFontSize,
})

const xSmallSize = theme('size', {
    normal: xsmallFontSize,
    bigger: xsmallFontSize,
})
const smallSize = theme('size', {
    normal: smallFontSize,
    bigger: smallFontSize,
})
const minimalSmallerSize = theme('size', {
    normal: minimalSmaller,
    bigger: minimalSmaller,
})
const minimalBiggerSize = theme('size', {
    normal: minimalBigger,
    bigger: minimalBigger,
})

export const fontSizes = {
    baseFontSize: baseFontSize,
    h1Size: h1Size,
    h2Size: h2Size,
    h3Size: h3Size,
    h4Size: h4Size,
    h5Size: h5Size,
    techSize: techSize,
    subHeadlineSize: subHeadlineSize,
    xSmallSize: xSmallSize,
    smallSize: smallSize,
    minimalSmallerSize: minimalSmallerSize,
    minimalBiggerSize: minimalBiggerSize,
}
