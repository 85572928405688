import React from 'react'
import AnamnesisAppIcon from '../../images/icon_anamnesis_app.png'
import { Icon, LogoWrapper, LogoText } from './AnamnesisLogo.styled'
import { useTheme } from '../Theme'

export const AnamnesisLogo = () => {
    const themeContext = useTheme()
    return (
        <LogoWrapper>
            <Icon src={AnamnesisAppIcon} alt="Anamnesis app icon" />
            <LogoText darkMode={themeContext.mode === 'dark'}>
                <span>aid</span>minutes.<span>anamnesis</span>
            </LogoText>
        </LogoWrapper>
    )
}
