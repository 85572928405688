import React from 'react'
import { SitePagination } from '../../../SitePagination'
import { PartnerContainer } from './Partner.styled'
import {
    Section,
    SectionTitle,
    FeatureWrap,
    BigSubHeadlineParagraph,
} from '../../../basic'
import { ContactFlag } from '../../../ContactFlag'
import { MobileHeadline } from '../../../MobileHeadline'
import { getDrupalData } from '../../../../utils'
import { PartnerLogos } from '../../../PartnerLogos'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '../../../Theme'

export const Partner = ({ data, translations }) => {
    const { mainHeadline, paginationNumber, paginationText, subHeadline } =
        getDrupalData(data)

    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    /**
     * Filters out logo variations, `filter` probably being `_white`. This is used for the dark mode to put out either white or black logos.
     * @param {Array} logos
     * @param {String} filter
     * @param {Boolean} include
     */
    const getLogoVariation = (logos, filter = '_white', include = false) =>
        logos.filter((logo) =>
            include
                ? logo?.imageFile?.childImageSharp?.fluid?.src?.indexOf(
                      filter
                  ) > 0
                : logo?.imageFile?.childImageSharp?.fluid?.src?.indexOf(
                      filter
                  ) < 0
        )

    const themeContext = useTheme()

    // TODO: warum funktioniert das auf EN nicht
    // TODO. Conditional Chaining
    let logos = (data && data.fieldPartnerLogos) || ''
    logos = getLogoVariation(logos, '_white', themeContext.mode === 'dark')

    return (
        <Section id="partner">
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={mainHeadline}
            />
            <SitePagination
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <FeatureWrap margin="22vh 0 10vh">
                <PartnerContainer ref={ref} className="not-in-view">
                    <SectionTitle displayMobile="none">
                        {mainHeadline}
                    </SectionTitle>
                    <BigSubHeadlineParagraph
                        width="80%"
                        textTransformDesktop="uppercase"
                        marginTop="0"
                    >
                        {subHeadline}
                    </BigSubHeadlineParagraph>
                    <PartnerLogos logos={logos} />
                </PartnerContainer>
            </FeatureWrap>
            <ContactFlag translations={translations} />
        </Section>
    )
}
