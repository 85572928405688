import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
    FooterRow,
    FooterMenuList,
    FooterMenuListItem,
    FooterInner,
    FooterMenuListHeadline,
    FooterLink,
    FooterMenuListContainer,
    FooterExternalLink,
    FooterShortcutsContainer,
    FooterProductsContainer,
    FooterLegalContainer,
    FooterSocialContainer,
} from './Footer.styled'
import Logo from '../../../svg/aidminutes_logo_white.inline.svg'
import { getTranslations } from '../../../utils'

export const Footer = ({ data, prefix, translations }) => {
    const footerdata = useStaticQuery(graphql`
        query footerDataQuery {
            site {
                siteMetadata {
                    socialLinks {
                        title
                        link
                    }
                }
            }
        }
    `)

    const { social } = getTranslations(translations)
    const footermenues = data ? data?.fieldMenues || data : null

    const menuHeadline = footermenues[0]?.entity.fieldMainHeadline?.value //menu
    const menu = footermenues[0]?.entity.fieldMenuitem
    const productsHeadline = footermenues[1]?.entity.fieldMainHeadline?.value //products
    const products = footermenues[1]?.entity.fieldMenuitem
    const legalHeadline = footermenues[2]?.entity.fieldMainHeadline?.value //legal
    const legals = footermenues[2]?.entity.fieldMenuitem
    const socialMenu = footerdata?.site?.siteMetadata?.socialLinks ?? [] //social
    const footerID = data?.fieldId ?? ''
    const footermenuescount = footermenues?.length || 0

    return (
        <footer id={footerID || ''}>
            <FooterRow padding="60px 5vw" background="darkblue">
                <FooterInner count={footermenuescount}>
                    <div>
                        <div>
                            <Logo width="180" />
                        </div>
                    </div>
                    <FooterMenuListContainer>
                        <FooterShortcutsContainer>
                            <FooterMenuListHeadline>
                                {menuHeadline}
                            </FooterMenuListHeadline>
                            <FooterMenuList>
                                {menu?.map((item, index) => {
                                    return prefix !== item.entity?.prefix ? (
                                        <FooterMenuListItem key={index}>
                                            <FooterLink
                                                to={`${
                                                    prefix === '/en' &&
                                                    !item.entity.dontPrefixIt
                                                        ? '/en'
                                                        : ''
                                                }${item.entity.fieldPath}`}
                                            >
                                                {item?.entity.title}
                                            </FooterLink>
                                        </FooterMenuListItem>
                                    ) : (
                                        ''
                                    )
                                })}
                            </FooterMenuList>
                        </FooterShortcutsContainer>
                        <FooterProductsContainer>
                            <FooterMenuListHeadline className="language-dependent-font">
                                {productsHeadline}
                            </FooterMenuListHeadline>
                            <FooterMenuList>
                                {products?.map((item, index) => {
                                    return prefix !== item.entity?.prefix ? (
                                        <FooterMenuListItem key={index}>
                                            <FooterLink
                                                className="language-dependent-font"
                                                // TODO: Wenn mehr Sprachen implementiert sind, anpassen
                                                to={`${
                                                    prefix === '/en' &&
                                                    !item.entity.dontPrefixIt
                                                        ? '/en'
                                                        : ''
                                                }${item.entity.fieldPath}`}
                                            >
                                                {item?.entity.title}
                                            </FooterLink>
                                        </FooterMenuListItem>
                                    ) : (
                                        ''
                                    )
                                })}
                            </FooterMenuList>
                        </FooterProductsContainer>
                        <FooterLegalContainer>
                            <FooterMenuListHeadline className="language-dependent-font">
                                {legalHeadline}
                            </FooterMenuListHeadline>
                            <FooterMenuList>
                                {legals?.map((item, index) => {
                                    return prefix !== item.entity?.prefix ? (
                                        <FooterMenuListItem key={index}>
                                            {item.entity?.isExternalLink ===
                                            true ? (
                                                <FooterExternalLink
                                                    className="language-dependent-font"
                                                    href={`${
                                                        prefix === '/en' &&
                                                        !item.entity
                                                            .dontPrefixIt
                                                            ? '/en'
                                                            : ''
                                                    }${item.entity.fieldPath}`}
                                                    target="_blank"
                                                >
                                                    {item?.entity.title}
                                                </FooterExternalLink>
                                            ) : (
                                                <FooterLink
                                                    className="language-dependent-font"
                                                    to={`${
                                                        prefix === '/en' &&
                                                        !item.entity
                                                            .dontPrefixIt
                                                            ? '/en'
                                                            : ''
                                                    }${item.entity.fieldPath}`}
                                                >
                                                    {item?.entity.title}
                                                </FooterLink>
                                            )}
                                        </FooterMenuListItem>
                                    ) : (
                                        ''
                                    )
                                })}
                            </FooterMenuList>
                        </FooterLegalContainer>
                        <FooterSocialContainer>
                            <FooterMenuListHeadline>
                                {social}
                            </FooterMenuListHeadline>
                            <FooterMenuList>
                                {socialMenu.map((item, index) => {
                                    return (
                                        <FooterMenuListItem key={index}>
                                            <FooterExternalLink
                                                target="_blank"
                                                href={item.link}
                                            >
                                                {item.title}
                                            </FooterExternalLink>
                                        </FooterMenuListItem>
                                    )
                                })}
                            </FooterMenuList>
                        </FooterSocialContainer>
                    </FooterMenuListContainer>
                </FooterInner>
            </FooterRow>
            <FooterRow padding="30px 5vw" />
        </footer>
    )
}
