import styled from 'styled-components'
import { SectionTitle } from '../../../basic'
import { colors, fontSizes } from '../../../Theme'

const { mainFontColor, brightBgColor } = colors
const { h4Size } = fontSizes

export const RescueTextTitle = styled(SectionTitle)`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-column: span 2;
        width: ${(props) => props.width || '100%'};
    }
`

export const RightColumnParagraph = styled.div`
    color: ${(props) => (props.darkMode ? 'white' : mainFontColor)};
    font-size: ${h4Size};
    font-weight: 700;
    line-height: 1.7em;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        background-color: ${(props) => (props.darkMode ? '' : brightBgColor)};
        border-left: ${(props) => (props.darkMode ? '1px solid white' : '')};
        margin-top: 0;
        padding: 3vh 2vw;
        line-height: 2.4em;
        text-align: center;
    }
`

export const RightColumnContainer = styled.div``
