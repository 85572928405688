import styled from 'styled-components'
import { black } from '../Theme/ThemeDefinitions/themeMode'

export const ClosingBlock = styled.div`
    display: block;
    background-color: ${black};
    height: 3rem;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: none;
    }
`
