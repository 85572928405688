import React from 'react'
import { Layout, Seo, CancellationSection } from '../../components'
import {
    getSubmenuData,
    getTopmenuData,
    getPageData,
    useFooterData,
} from '../../utils'
import seo_home_de_fb from './../../images/seo_home_de_fb.png'
import seo_home_en_fb from './../../images/seo_home_en_fb.png'

export const Cancellation = ({ data, location, langCode, pageContext }) => {
    const prefix = pageContext?.prefix ?? ''
    const cancellationdata = getPageData(data, 'cancellationQuery')
    const { footerdata } = useFooterData(
        data,
        'footerQuery',
        'entities',
        prefix
    )
    const topmenu = getTopmenuData(data)
    const submenu = getSubmenuData(data)
    // TODO drupal
    const translations = data?.allTranslationsJson ?? ''
    const seoTitle =
        langCode === 'DE'
            ? 'Widerrufsbelehrung' ?? pageContext?.seoTitle
            : 'Cancellation Policy' ?? pageContext?.seoTitle
    const seoDescription =
        'Sofern Sie eines unserer Softwareprodukte (Download) erworben haben, beachten Sie bitte folgende Widerrufsbelehrung'
    const seoImage =
        langCode === 'DE' ? `${seo_home_de_fb}` : `${seo_home_en_fb}`
    const seoImageAlt = pageContext?.seoTitle
    const seoType = 'website'
    const seoUri =
        langCode === 'DE'
            ? 'https://www.aidminutes.com/cancellation-policy'
            : 'https://www.aidminutes.com/en/cancellation-policy'

    return (
        <Layout
            path={location.pathname}
            location={location}
            langCode={langCode}
            translations={translations}
            pageContext={pageContext}
            topmenu={topmenu}
            submenu={submenu}
            footerdata={footerdata}
        >
            <Seo
                lang={langCode}
                title={seoTitle}
                type={seoType}
                url={seoUri}
                image={seoImage}
                alt={seoImageAlt}
                description={seoDescription}
            />
            <CancellationSection data={cancellationdata} />
        </Layout>
    )
}
