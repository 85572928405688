import styled from 'styled-components'
import {
    HoneyCombHeight,
    HoneyCombWidth,
    HoneyCombHeightMobile,
    HoneyCombWidthMobile,
} from './honeyComb.config'

export const HoneyCombGrid = styled.div`
    display: grid;
    grid-gap: 3px;
    grid-template-columns: repeat(6, ${HoneyCombWidthMobile / 2}px);
    grid-template-rows: repeat(8, ${HoneyCombHeightMobile / 2}px);
    margin: 50px auto;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-template-columns: repeat(10, ${HoneyCombWidth / 2}px);
        grid-template-rows: repeat(6, ${HoneyCombHeight / 2}px);
    }
    @media (min-width: ${({ theme }) => theme.desktop}) {
        grid-template-columns: repeat(16, ${HoneyCombWidth / 2}px);
        grid-template-rows: repeat(4, ${HoneyCombHeight / 2}px);
    }
`
