import { aidminutesColors, aidminuteWebColors } from './aidminutesStyle'

const {
    aidminutesGrey,
    aidminutesDarker,
    aidminutesGrannygreen,
    aidminutesLight,
    aidminutesLighter,
    aidminutesNormal,
    aidminutesRedLighter,
    aidminutesBlue,
    aidminutesCoralRed,
    aidminutesRescueGreen,
} = aidminutesColors

const { aidminutesWebRed, aidminutesWebDarkGreyBlue } = aidminuteWebColors

export const theme = {
    // Media Queries
    mobileSmall: '332px', //20.75rem
    mobile: '32em', //576px
    tabletXS: '768px', //48em eg. iPad Mini
    tablet: '801px', //50.0625 eg. iPad
    tabletXL: '1030px', //64em eg. iPadPro
    desktopXS: '1023px', //63rem
    destopSmall: '63.889em', //1150px
    desktop: '1190px', //1200px
    desktopXL: '1400px',
    desktopXXL: '1600px', // rescue license page

    // font sizes
    basicFontSize: '14px',
    h1FontSize: '3.428rem', //48px
    h2FontSize: '2.571rem',
    h3FontSize: '2rem', // 36px
    h4FontSize: '1.7142rem', // 24px
    smallFontSize: '0.889rem', // 16px
    xsmallFontSize: '0.77777rem', // 14px
    technicalFontSize: '0.667rem', //12px
    subHeadlineSmallFontSize: '1.7142rem',

    // spacing

    mobilePadding: '3rem 1rem',

    // gradients
    startGradient: 'linear-gradient(135deg, #94c11a, #00963e 72%)',
    backgroundGradient:
        'radial-gradient(90.1% 284.77% at 15.89% 29.44%, rgba(254, 254, 255, 0.392) 0%, rgba(223, 229, 237, 0.348831) 9.57%, rgba(86, 104, 126, 0.197562) 47.38%, rgba(73, 89, 109, 0.157482) 59.8%, rgba(160, 192, 235, 0.0644889) 94.19%, rgba(64, 91, 126, 0) 100%)',

    black: '#000',
    white: '#FFF',

    primaryColor: aidminutesGrannygreen,
    secondaryColor: aidminutesBlue,

    attentionColor: aidminutesWebRed,
    attentionColorInactive: aidminutesRedLighter,

    inactiveItemsColor: aidminutesGrey,

    mainFontColor: aidminutesGrey,
    brightFontColor: aidminutesNormal,
    darkFontColor: aidminutesDarker,

    brightMenuLinkColor: aidminutesLight,

    brightBgColor: aidminutesLighter,
    darkBgColor: aidminutesDarker,
    greyishBgColor: aidminutesNormal,
    darkBlueishBgColor: aidminutesWebDarkGreyBlue,
    coralRedBgColor: aidminutesCoralRed,
    rescueGreen: aidminutesRescueGreen,
}

export const highContrastTheme = {
    basicFontSize: '18px',
    mainFontColor: '#252325',
}

export const darkTheme = {
    startGradient: 'linear-gradient(135deg, #6B3EE5, #FF69C1 72%)',
    backgroundGradient:
        'radial-gradient(90.1% 284.77% at 15.89% 29.44%, rgba(0, 1, 0, 0.392) 0%, rgba(32, 26, 18, 0.348831) 9.57%, rgba(169, 151, 129, 0.197562) 47.38%, rgba(182, 166, 146, 0.157482) 59.8%, rgba(95, 63, 20, 0.0644889) 94.19%, rgba(191, 164, 129, 0) 100%)',

    black: '#fff',
    white: '#000',

    primaryColor: '#7834AC',

    attentionColorInactive: '#00716F',
    attentionColor: '#3FAFB2',

    mainFontColor: '#97979A',
    brightFontColor: '#5F6165',
    darkFontColor: '#B2B2B4',

    brightMenuLinkColor: '#36383E',

    brightBgColor: '#0C1016',
    darkBgColor: '#B2B2B4',
    greyishBgColor: '#5F6165',
    darkBlueishBgColor: '#17161B',
    coralRedBgColor: aidminutesCoralRed,
}
