import styled from 'styled-components'

import { colors } from '../Theme'

const { darkFontColor, darkFontColorOnLightBackground } = colors

export const BannerContainer = styled.div`
    display: flex;
    width: 100%;
    min-height: 4rem;
    margin-bottom: 1rem;
`

export const Icon = styled.div`
    background: url(${(props) => props.image}) no-repeat;
    background-size: contain;
    width: 4rem;
    height: 4rem;
`

export const Description = styled.div`
    font-size: 1rem;
    color: ${(props) =>
        props.darkOnMobileView
            ? darkFontColorOnLightBackground
            : darkFontColor};
    letter-spacing: 0.07rem;
    line-height: 1.5rem;
    margin-top: 0;
    font-weight: 200;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: 1.25rem;
        color: ${(props) =>
            props.dark ? darkFontColorOnLightBackground : darkFontColor};
    }
`
