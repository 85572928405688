import React from 'react'
import { graphql } from 'gatsby'

import { Home as HomeTemplate } from '../pageTemplates'

const Home = ({ data, location, pageContext }) => {
    return (
        <>
            <HomeTemplate
                data={data}
                location={location}
                langCode={pageContext.language}
                pageContext={pageContext}
            />
        </>
    )
}

export const query = graphql`
    query homequery($language: Drupal_LanguageId!, $langCode: String!) {
        drupal {
            homeQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "home" } }
            ) {
                home: entities(language: $language) {
                    ... on Drupal_NodeHome {
                        fieldSubtext
                        fieldMainHeadline {
                            value
                        }
                        fieldSvgentities {
                            entity {
                                ... on Drupal_NodeSvgTexts {
                                    fieldSvgid
                                    fieldSvgtext
                                }
                            }
                        }
                        title
                    }
                }
            }
            partnerQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "partner" } }
            ) {
                partner: entities(language: $language) {
                    ... on Drupal_NodePartner {
                        fieldSubHeadline
                        title
                        fieldMainHeadline {
                            value
                        }
                        fieldPagination {
                            entity {
                                ... on Drupal_NodePagination {
                                    fieldPaginationText
                                    fieldPaginationNummer
                                }
                            }
                        }
                        fieldPartnerLogos {
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 300, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            url
                            alt
                        }
                    }
                }
            }
            contactQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "kontakt" } }
            ) {
                contact: entities(language: $language) {
                    ... on Drupal_NodeKontakt {
                        title
                        fieldEMail
                        fieldEMailLink
                        fieldContacticon
                        fieldMobile
                        fieldMobileLink
                        fieldPhone
                        fieldPhoneLink
                        fieldLocations {
                            location: entity {
                                ... on Drupal_NodeLocation {
                                    title
                                    fieldCity
                                    fieldLatitude
                                    fieldLongitude
                                    fieldLocationNumber
                                    fieldStreet
                                    fieldName
                                }
                            }
                        }
                    }
                }
            }
            downloadQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "download" } }
            ) {
                downloads: entities(language: $language) {
                    ... on Drupal_NodeDownload {
                        title
                        fieldMainHeadline {
                            value
                        }
                        fieldText
                        fieldPagination {
                            entity {
                                ... on Drupal_NodePagination {
                                    fieldPaginationNummer
                                    fieldPaginationText
                                    title
                                }
                            }
                        }
                        downloadItems: fieldDownloadItems {
                            downloadItem: entity {
                                ... on Drupal_NodeDownloadItem {
                                    title
                                    fieldAdditionalInfo
                                    fieldDownloadFile {
                                        entity {
                                            url
                                        }
                                        downloadFile {
                                            publicURL
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            whoWeAreQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "who_we_are" } }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeWhoWeAre {
                        fieldSubHeadline
                        fieldWysiwyg {
                            value
                        }
                        title
                        fieldMainHeadline {
                            value
                        }
                        fieldPagination {
                            entity {
                                ... on Drupal_NodePagination {
                                    fieldPaginationNummer
                                    fieldPaginationText
                                }
                            }
                        }
                        fieldContentImage {
                            alt
                            url
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 300, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
            dialectQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "languages" } }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeLanguages {
                        title
                        fieldMainHeadline {
                            value
                        }
                        fieldContacticon
                        fieldTextLong
                        fieldPagination {
                            entity {
                                ... on Drupal_NodePagination {
                                    fieldPaginationText
                                    fieldPaginationNummer
                                }
                            }
                        }
                        fieldContentImage {
                            url
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 800, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
            appsQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "apps" } }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeApps {
                        fieldPagination {
                            entity {
                                ... on Drupal_NodePagination {
                                    fieldPaginationNummer
                                    fieldPaginationText
                                }
                            }
                        }
                        title
                        fieldTextBanner {
                            entity {
                                ... on Drupal_NodeTextBanner {
                                    fieldWysiwyg {
                                        value
                                    }
                                    fieldIcon {
                                        url
                                        alt
                                        imageFile {
                                            publicURL
                                        }
                                    }
                                }
                            }
                        }
                        fieldBgImage {
                            url
                            imageFile {
                                childImageSharp {
                                    original {
                                        src
                                    }
                                }
                            }
                        }
                        fieldMainHeadline {
                            value
                        }
                        fieldContacticon
                        fieldHeadlineAnamnesis
                        fieldHeadlineRescue
                        fieldTextAnamnesis {
                            value
                        }
                        fieldTextRescue {
                            value
                        }
                        fieldImageRightCorner {
                            url
                            imageFile {
                                publicURL
                            }
                        }
                        fieldImageRescue {
                            url
                            imageFile {
                                publicURL
                            }
                        }
                        fieldImageAnamnesis {
                            url
                            imageFile {
                                publicURL
                            }
                        }
                        path {
                            alias
                        }
                    }
                }
            }
            safetyQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "sicherheit" } }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeSicherheit {
                        title
                        fieldMainHeadline {
                            value
                        }
                        fieldWysiwyg {
                            value
                        }
                        fieldSubHeadline
                        fieldBottomText {
                            value
                        }
                        fieldItems {
                            entity {
                                ... on Drupal_NodeSafetyItems {
                                    title
                                    fieldWysiwyg {
                                        value
                                    }
                                    fieldLogo {
                                        __typename
                                        url
                                        imageFile {
                                            publicURL
                                        }
                                    }
                                    fieldLogoOnly
                                }
                            }
                        }
                    }
                }
            }
            mobileBannerQuery: nodeQuery(
                filter: {
                    conditions: { field: "type", value: "__mobile_banner" }
                }
            ) {
                mobileBanner: entities(language: $language) {
                    ... on Drupal_NodeMobileBanner {
                        fieldText
                        fieldPath
                        fieldWysiwyg {
                            value
                        }
                        fieldIsExternalLink
                    }
                }
            }
            topMenuQuery: nodeQuery(
                filter: {
                    conditions: { field: "type", value: "__menu" }
                    conjunction: AND
                    groups: { conditions: { field: "title", value: "Topmenu" } }
                }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeMenu {
                        title
                        fieldAdditionalHeadline {
                            value
                        }
                        fieldMainHeadline {
                            value
                        }
                        fieldMenuitem {
                            entity {
                                ... on Drupal_NodeMenuitem {
                                    fieldPath
                                    fieldMainHeadline {
                                        value
                                    }
                                    fieldOpenNewTab
                                    fieldIsExternalLink
                                    fieldWysiwyg {
                                        value
                                    }
                                    title
                                }
                            }
                        }
                    }
                }
            }
            submenuQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "product_menu" } }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeProductMenu {
                        fieldMenues {
                            entity {
                                ... on Drupal_NodeMenu {
                                    fieldPath
                                    fieldMainHeadline {
                                        value
                                    }
                                    fieldAdditionalHeadline {
                                        value
                                    }
                                    title
                                    fieldIcon {
                                        url
                                        alt
                                        imageFile {
                                            childImageSharp {
                                                fluid(
                                                    maxWidth: 64
                                                    quality: 80
                                                ) {
                                                    ...GatsbyImageSharpFluid
                                                    src
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            footerQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "footer" } }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeFooter {
                        title
                        fieldTextRescue {
                            value
                        }
                        fieldMenues {
                            entity {
                                ... on Drupal_NodeMenu {
                                    title
                                    fieldAdditionalHeadline {
                                        value
                                    }
                                    fieldMainHeadline {
                                        value
                                    }
                                    fieldMenuitem {
                                        entity {
                                            ... on Drupal_NodeMenuitem {
                                                title
                                                vid
                                                fieldPath
                                                fieldMainHeadline {
                                                    value
                                                }
                                                fieldWysiwyg {
                                                    value
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allTranslationsJson(filter: { langCode: { eq: $langCode } }) {
            edges {
                node {
                    contact
                    language
                    langCode
                    products
                    social
                    accessibility
                }
            }
        }
    }
`

export default Home
