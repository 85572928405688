import styled from 'styled-components'
import { colors } from '../../Theme'

const { mainFontColor, white } = colors
// with preheader
const topmargin = '105px'
// const topmargin = '65px'

export const Container = styled.div`
    background-color: ${white};
    box-shadow: 18px 18px 45px 19px rgba(79, 94, 120, 0.1);
    transition:
        left 0.5s,
        right 0.5s,
        width 0.5s;
    z-index: 1;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        height: ${`calc(100% - ${topmargin})`};
        margin-top: ${topmargin};
        overflow-x: hidden;
        position: fixed;
        right: ${(props) => (props.direction === 'ltr' ? '0' : 'unset')};
        left: ${(props) => (props.direction === 'rtl' ? '0' : 'unset')};
        top: 0;
        width: ${(props) => (props.status === true ? '200px' : '0')};
        z-index: 2001;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        color: ${mainFontColor};
        height: ${(props) => (props.status === true ? '100%' : '0')};
        position: relative;
        opacity: ${(props) => (props.status === true ? '1' : '0')};
        width: 100%;
    }
`

export const ContentContainer = styled.div`
    overflow-y: scroll;
    padding: ${(props) => (props.status === true ? '1rem' : '0')};
    transition:
        height 0.2s,
        opacity 0.2s;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: flex;
        flex-wrap: wrap;
        height: ${(props) => (props.status === true ? '100%' : '0')};
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        opacity: ${(props) => (props.status === true ? '1' : '0')};
    }
`
