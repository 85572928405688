import styled from 'styled-components'
import GreenCamera from '../../../../images/green_camera.svg'
import ExternalLink from '../../../../svg/icons/external-link.svg'

export const VideoGridContainer = styled.div`
    padding: 0 0 3rem 0;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: grid;
        grid-template-rows: 50% 50%;
        gap: 2rem;
    }
`

export const VideoSectionRightContainer = styled.div`
    flex-direction: column;
    height: fit-content;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: flex-start;
        display: flex;
        padding: 0 0.5rem 0 2rem;
        margin-right: 1rem;
        height: auto;
    }
`

export const VideoWrap = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    margin-top: 0.75em;
`

export const VideoIFrame = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`

export const AwardsList = styled.ul`
    list-style-type: '- ';
`
export const AwardsListItemTitle = styled.span`
    font-weight: bolder;
`

export const ExternalLinkIcon = styled.div`
    height: 1rem;
    width: 1rem;
    background: url(${ExternalLink}) no-repeat center;
    background-size: contain;
    margin: 1rem 0.5rem 0 0;
`
export const VimeoLink = styled.a`
    font-weight: bold;
    margin: 1rem 2rem 0 0;
`
export const Camera = styled.div`
    min-height: 2rem;
    min-width: 4rem;
    background: url(${GreenCamera}) no-repeat center;
    background-size: contain;
    margin: 1rem 0 0 0;
`

export const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
`
