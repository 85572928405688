import React from 'react'
import {
    ContentGrid,
    SectionTitle,
    SectionBackgroundDesktopOnly,
} from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import { ContactFlag } from '../../../ContactFlag'
import {
    VideoGridContainer,
    VideoSectionRightContainer,
    VideoWrap,
    VideoIFrame,
    AwardsList,
    AwardsListItemTitle,
    VimeoLink,
    Camera,
    ExternalLinkIcon,
    LinkContainer,
} from './RescueAppVideo.styled'
import { MobileHeadline } from '../../../MobileHeadline'
import { useInView } from 'react-intersection-observer'
import { getRescueString } from '../rescueStrings'
import { useTheme } from '../../../Theme'
import { getTranslations } from '../../../../utils'

const AWARDTITLE_1 = 'MSD-Gesundheitspreis 2021'
const AWARDTITLE_2 = 'E-Health Gesundheitspreis Niedersachsen 2020'

export const RescueAppVideo = ({ translations }) => {
    //TODO add data to Drupal and fetch it
    const [ref, inView, entry] = useInView()

    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    const themeContext = useTheme()

    const { langCode } = getTranslations(translations)

    return (
        <SectionBackgroundDesktopOnly
            id="rescue-video"
            darkMode={themeContext.mode === 'dark'}
            background={
                themeContext.mode === 'dark'
                    ? 'linear-gradient(90deg, #0E0D0F 59%, #1C1C1C 50%)'
                    : 'linear-gradient(90deg, #F4F4F4 59%, #F9F9F9 50%)'
            }
        >
            <MobileHeadline
                paginationNumber="05"
                text={getRescueString('awards', langCode)}
            />
            <SitePagination
                pageNumber="05"
                pageText={getRescueString('awards', langCode)}
            />
            <ContentGrid
                columns="50% 50%"
                margin="22vh 0 15vh"
                gap="10%"
                className="not-in-view"
                ref={ref}
            >
                <VideoGridContainer>
                    <VideoWrap>
                        <VideoIFrame
                            src="https://player.vimeo.com/video/652068486?h=c74554c705"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                        ></VideoIFrame>
                    </VideoWrap>
                    <VideoWrap>
                        <VideoIFrame
                            src="https://player.vimeo.com/video/485492975?h=3d9174bdfc"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                        ></VideoIFrame>
                    </VideoWrap>
                </VideoGridContainer>
                <VideoSectionRightContainer>
                    <SectionTitle dark displayMobile="none">
                        {getRescueString('videoSectionTitle', langCode)}
                    </SectionTitle>
                    <p>{getRescueString('videoSectionInfo', langCode)}</p>
                    <p>{getRescueString('videoSectionAwards', langCode)}</p>
                    <AwardsList>
                        <li>
                            {
                                getRescueString(
                                    'videoSectionAward_1',
                                    langCode
                                ).split('{{prize_name}}')[0]
                            }
                            <AwardsListItemTitle>
                                {AWARDTITLE_1}
                            </AwardsListItemTitle>
                            {
                                getRescueString(
                                    'videoSectionAward_1',
                                    langCode
                                ).split('{{prize_name}}')[1]
                            }
                        </li>
                        <li>
                            {
                                getRescueString(
                                    'videoSectionAward_2',
                                    langCode
                                ).split('{{prize_name}}')[0]
                            }
                            <AwardsListItemTitle>
                                {AWARDTITLE_2}
                            </AwardsListItemTitle>
                        </li>
                    </AwardsList>
                    <p>
                        {getRescueString(
                            'videoSectionMoreVideosOnVimeo',
                            langCode
                        )}
                    </p>
                    <LinkContainer>
                        <ExternalLinkIcon />
                        <VimeoLink href="https://vimeo.com/aidminutes">
                            https://vimeo.com/aidminutes
                        </VimeoLink>
                        <Camera />
                    </LinkContainer>
                </VideoSectionRightContainer>
            </ContentGrid>
            <ContactFlag translations={translations} />
        </SectionBackgroundDesktopOnly>
    )
}
