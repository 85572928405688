import styled from 'styled-components'
import { NavLink } from '../../Menu.styled'

export const ProductMobileMenuNavLink = styled(NavLink)`
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: none;
    }
`

export const ProductMobileMenuContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: none;
    }
`
