import styled from 'styled-components'

export const ButtonText = styled.span`
    text-decoration: underline;
`

export const AlternativeDownloadButton = styled.button`
    background: transparent;
    border: none;
    margin-block-end: 1rem;
    padding: 0;
    text-align: left;
    direction: ltr;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin-block-end: 3rem;
    }
`
export const AlternativeDownloadButtonNew = styled.button`
    background: transparent;
    border: none;
    padding: 0;
    text-align: left;
    direction: ltr;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin-block-end: 0rem;
    }
`
