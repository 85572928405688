import React from 'react'
import { useInView } from 'react-intersection-observer'
import {
    TextContainer,
    HeadlineContainer,
    FeatureWrapper,
    FeatureTitle,
    FeatureTitleFlag,
    FeatureDescription,
    FeatureImage,
    FeatureImageGroup,
    ContentWrapper,
} from './HediBenefits.styled'
import { SitePagination } from '../../../SitePagination'
import { MobileHeadline } from '../../../MobileHeadline'
import {
    FlowContentGrid,
    SectionTitle,
    SectionBackground,
    SubHeadline,
} from '../../../basic'
import healthCareProfessionalsGroup from '../../../../images/hedi_03_healthcare_professionals_group.png'
import healthCareProfessionalsImage from '../../../../images/hedi_03_healthcare_professionals.png'
import withPediatricianImage from '../../../../images/hedi_03_pediatrician.png'
import pediatricianImage from '../../../../images/hedi_03_with_pediatrician.png'
import documentsImage from '../../../../images/hedi_03_documents.png'
import { hediSoftLightBlueBgColor } from '../../../Theme/ThemeDefinitions/themeMode'

export const HediBenefits = ({ data, langCode, translations }) => {
    const [ref, inView, entry] = useInView()

    const [
        healthCareProfessionalsTitle,
        pediatricianTitle,
        withPediatricianTitle,
        documentsTitle,
    ] = data?.fieldHcprofessionalsTitle

    const [
        healthCareProfessionalsBody,
        pediatricianBody,
        withPediatricianBody,
        documentsBody,
    ] = data?.fieldHcProfessionalsBody

    const hediAppFeatureTitleFlag = '>'
    const hediAppFeatures = [
        {
            imageSrc: healthCareProfessionalsImage,
            imageAlt: 'health care professionals',
            title: healthCareProfessionalsTitle,
            description: healthCareProfessionalsBody?.value,
        },
        {
            imageSrc: withPediatricianImage,
            imageAlt: 'with pediatrician',
            title: withPediatricianTitle,
            description: withPediatricianBody?.value,
        },
        {
            imageSrc: pediatricianImage,
            imageAlt: 'pediatrician',
            title: pediatricianTitle,
            description: pediatricianBody?.value,
        },
        {
            imageSrc: documentsImage,
            imageAlt: 'documents',
            title: documentsTitle,
            description: documentsBody?.value,
        },
    ]

    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    return (
        <SectionBackground
            id="hedi-advantages-and-benefits"
            background={hediSoftLightBlueBgColor}
        >
            <MobileHeadline
                paginationNumber={
                    data?.fieldPagination?.entity?.fieldPaginationNummer
                }
                text={data?.fieldMainHeadline?.value}
                isHedi
            />
            <SitePagination
                pageNumber={
                    data?.fieldPagination?.entity?.fieldPaginationNummer
                }
                pageText={data?.fieldPagination?.entity?.fieldPaginationText}
                textTransform="initial"
            />
            <ContentWrapper>
                <FlowContentGrid
                    ref={ref}
                    className="not-in-view"
                    mobilePadding="0"
                    rows="auto"
                >
                    <HeadlineContainer>
                        <SectionTitle
                            textTransform="uppercase"
                            displayMobile="none"
                        >
                            {data?.fieldMainHeadline?.value}
                        </SectionTitle>
                        <SubHeadline
                            displayMobile
                            displayDesktop
                            margin="0 0 6rem 0"
                        >
                            {data?.fieldPreHeadline}
                        </SubHeadline>
                    </HeadlineContainer>
                    <TextContainer>
                        {hediAppFeatures.map((feature, index) => {
                            return (
                                <FeatureWrapper key={index}>
                                    <FeatureImage
                                        alt={feature.imageAlt}
                                        src={feature.imageSrc}
                                    />
                                    <FeatureTitle key={index}>
                                        <FeatureTitleFlag>
                                            {hediAppFeatureTitleFlag}
                                        </FeatureTitleFlag>
                                        {feature.title}
                                    </FeatureTitle>
                                    <FeatureDescription>
                                        {feature.description}
                                    </FeatureDescription>
                                </FeatureWrapper>
                            )
                        })}
                    </TextContainer>
                </FlowContentGrid>
                <FeatureImageGroup
                    src={healthCareProfessionalsGroup}
                    alt="health care professionals group"
                />
            </ContentWrapper>
        </SectionBackground>
    )
}
