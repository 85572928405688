import styled from 'styled-components'

export const RescueAppVideoContentContainer = styled.div`
    display: flex;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: block;
        position: relative;
    }
    @media (width: 801px) {
        display: block;
        position: relative;
    }
`
export const SectionWrap = styled.div`
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 110%;
    }
`

export const RescueAppVideoTextContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        float: left;
        max-width: 50%;
    }
    @media (min-width: ${({ theme }) => theme.desktop}) and (max-width: ${({
            theme,
        }) => theme.desktopXL}) {
        max-width: 50%;
    }
    @media (min-width: ${({ theme }) => theme.desktopXL}) {
        max-width: 40%;
    }
`
