import React from 'react'
import {
    BlockQuote,
    EmoTextContainer,
    PhotoCreditsContainer,
    ShareText,
    SocialIcons,
    BottomTextContainer,
} from './RescueCovid19HelpingHands.styled'
import {
    FeatureWrapNoFlex,
    SectionBackgroundDesktopOnly,
    PageImageMobileOnly,
    Wysiwyg,
} from '../../../basic'
import { MobileHeadline } from '../../../MobileHeadline'
import { SitePagination } from '../../../SitePagination'
import { getDrupalData } from '../../../../utils'
import bgImage from '../../../../images/bg_rescue_hands_2.jpg'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '../../../Theme'

export const RescueCovid19HelpingHands = ({ data }) => {
    const {
        paginationNumber,
        paginationText,
        headline,
        addInfo,
        text,
        quote,
        quoteSrc,
        quoteCite,
        bottomText,
    } = getDrupalData(data)

    const photocredit =
        '<p>Photo by <a href="https://unsplash.com/@brandaohh?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noopener noreferrer">Branimir Balogović</a> on <a href="https://unsplash.com/@brandaohh?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noopener noreferrer">unsplash</a>'

    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }
    const themeContext = useTheme()
    return (
        <SectionBackgroundDesktopOnly
            id="helping-hands"
            background={`url(${bgImage}) no-repeat #b2b6b7`}
            backgroundSize="contain"
            backgroundPostionY="5vh"
        >
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={headline}
            />
            <PageImageMobileOnly src={bgImage} alt={headline} />
            <SitePagination
                darkMode={themeContext.mode === 'dark'}
                pageNumber={paginationNumber}
                pageText={paginationText}
                fontColor="white"
            />
            <FeatureWrapNoFlex
                className="not-in-view"
                ref={ref}
                margin="15vh 0 10vh "
            >
                <BlockQuote
                    darkMode={themeContext.mode === 'dark'}
                    cite={quoteCite}
                >
                    <Wysiwyg text={quote} />
                    <footer>
                        <cite>{quoteSrc}</cite>
                    </footer>
                </BlockQuote>

                <EmoTextContainer>
                    <Wysiwyg fontdependable text={text} />
                </EmoTextContainer>
                <ShareText>{addInfo}</ShareText>
                <SocialIcons />
                <BottomTextContainer>
                    <Wysiwyg
                        colorOnBreakpointTablet={
                            themeContext.mode === 'dark' ? 'black' : ''
                        }
                        text={bottomText}
                    />
                </BottomTextContainer>
                <PhotoCreditsContainer>
                    <Wysiwyg text={photocredit} />
                </PhotoCreditsContainer>
            </FeatureWrapNoFlex>
        </SectionBackgroundDesktopOnly>
    )
}
