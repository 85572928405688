import React from 'react'

import { ContentGrid, Section, Wysiwyg } from '../../../basic'
import {
    RescueTextTitle,
    RightColumnContainer,
    RightColumnParagraph,
} from './RescueText.styled'
import { SitePagination } from '../../../SitePagination'
import { ContactFlag } from '../../../ContactFlag'
import { MobileHeadline } from '../../../MobileHeadline'
import { getDrupalData } from '../../../../utils'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '../../../Theme'
import { TextBanner } from '../../../TextBanner'

export const RescueAppText = ({ data, translations }) => {
    const { headline, text, paginationText, textBannerImage, textBannerText } =
        getDrupalData(data) // TODO add 'paginationNumber' to fetch pagination data number from Drupal (now it is hardcoded)
    // TODO CC
    const rightcolumntext = (data && data.fieldAdditionalInfo) || ''
    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }
    const themeContext = useTheme()

    return (
        <Section id="understanding">
            <MobileHeadline
                paginationNumber="07" //TODO: modify on drupal and replace it with {paginationNumber}
                text={headline}
            />
            <SitePagination
                pageNumber="07" //TODO: modify on drupal and replace it with {paginationNumber}
                pageText={paginationText}
            />
            <ContentGrid
                columns="65% 30%"
                margin="22vh 0 15vh"
                gap="5%"
                className="not-in-view"
                ref={ref}
            >
                <TextBanner text={textBannerText} image={textBannerImage} />
                <RescueTextTitle
                    width="65%"
                    dark
                    textTransform="uppercase"
                    displayMobile="none"
                >
                    {headline}
                </RescueTextTitle>
                <div>
                    <Wysiwyg text={text} />
                </div>
                <RightColumnContainer>
                    <RightColumnParagraph
                        darkMode={themeContext.mode === 'dark'}
                    >
                        {rightcolumntext}
                    </RightColumnParagraph>
                </RightColumnContainer>
            </ContentGrid>
            <ContactFlag translations={translations} />
        </Section>
    )
}
