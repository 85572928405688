import styled from 'styled-components'
import { colors } from '../Theme/'

const { brightBgColor } = colors

export const PreheaderContainer = styled.div`
    align-items: center;
    background-color: ${brightBgColor};
    display: none;
    height: auto;
    justify-content: flex-end;
    top: 0;
    transition: height 0.5s;
    width: 100%;
    padding: 10px 0;

    &.hidden {
        height: 0;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) and (min-width: 802px) {
        padding: 10px 5vw;
        display: flex;
    }
`

export const PreheaderNavBar = styled.ul`
    display: flex;
    list-style: none;
    margin: 0;
`

export const PreheaderNavbarItem = styled.li`
    &:not(::last-child) {
        margin: 0 1rem;
    }
    padding: 0;
    border: 0;
    margin-left: 1rem;
`
