import React from 'react'
import {
    ButtonText,
    AlternativeDownloadButton,
} from './AltrnativeApkDownload.styled'
export const AlternativeApkDownload = ({ langCode, setVisible }) => {
    const linkText = {
        de: 'Alternativer Download',
        rest: 'Alternative Download',
    }

    const handleClick = () => setVisible(true)

    return (
        <AlternativeDownloadButton onClick={() => handleClick()}>
            <ButtonText>
                {langCode === 'de' ? linkText.de : linkText.rest}
            </ButtonText>{' '}
            (apk)
        </AlternativeDownloadButton>
    )
}
