export { isset } from './functions'
export {
    getDrupalData,
    softwareDownloadData,
    moreinfoData,
    downloadButtonData,
    useFooterData,
    getTranslations,
    getPageData,
    getSubmenuData,
    getTopmenuData,
} from './Drupal'
