import React from 'react'
import { getTranslations } from '../../utils'
import { ThemeToggle, useTheme } from '../Theme'
import { SlideDownMenu } from '../SlideDownMenu'

export const AccessibilityMenu = ({ translations, pageContext }) => {
    const { accessibility } = getTranslations(translations)
    // !TODO insert translation for "Accessibility: More Contrast" & "Dark Mode"
    const menuID = 'accessibility-menu'
    const themeContext = useTheme()
    const { direction } = pageContext || 'ltr'

    return (
        <div>
            <SlideDownMenu
                darkMode={themeContext.mode === 'dark'}
                buttonText={accessibility}
                direction={direction}
                menuID={menuID}
                iconType="a11y"
            >
                <ThemeToggle
                    clickHandle={themeContext.highContrastToggle}
                    iconType="textsize"
                    text={themeContext.size !== 'normal' ? 'weniger' : 'mehr'}
                    itemWidth="auto"
                    iconWidth="24px"
                    // !TODO insert translation for "Accessibility: More Contrast" & "Dark Mode"
                    title={`${accessibility}: Contrast`}
                />
                <ThemeToggle
                    clickHandle={themeContext.darkModeToggle}
                    iconType={themeContext.mode === 'dark' ? 'sun' : 'moon'}
                    text={themeContext.mode === 'dark' ? 'light' : 'dark'}
                    itemWidth="auto"
                    iconWidth="24px"
                    // !TODO insert translation for "Accessibility: More Contrast" & "Dark Mode"
                    title={`${accessibility}: Dark Mode`}
                />
            </SlideDownMenu>
        </div>
    )
}
