import React from 'react'
import {
    BadgeContainer,
    StoreLinkContainerNew as Container,
} from './StoreLink.styled'
import { Wysiwyg } from '../basic'

export const BadgeLink = ({ link, title, afterTitle, direction, badge }) => {
    const { extension, publicURL } = badge.imageFile
    return (
        <Container>
            {title ? <Wysiwyg text={title} direction={direction} /> : null}
            <a href={link} target="_blank" rel="noopener noreferrer">
                <BadgeContainer extension={extension}>
                    <img src={publicURL} alt={badge.alt} />
                </BadgeContainer>
            </a>
            {afterTitle ? (
                <Wysiwyg text={afterTitle} direction={direction} />
            ) : null}
        </Container>
    )
}
