import React from 'react'
import {
    Layout,
    Seo,
    AnamnesisStartNew,
    AppTargetGroups,
    ClinicalStudiesNew,
} from '../../components'
import seo_anamnesis_de_fb from './../../images/seo_anamnesis_de_fb.png'
import seo_anamnesis_en_fb from './../../images/seo_anamnesis_en_fb.png'
import {
    getPageData,
    getSubmenuData,
    getTopmenuData,
    useFooterData,
} from '../../utils'
// import { FrequentlyAskedQuestions } from '../../components/PageSections/Anamnesis/FrequentlyAskedQuestions'
// import { AnamnesisLicense } from '../../components/PageSections/Anamnesis/AnamnesisLicense'

// let's go :-)

// TODO add to drupal -> https://aidminutes.atlassian.net/browse/UN-170
// TODO translation done via deepl -> https://aidminutes.atlassian.net/browse/UN-156
const contactButtonTexts = {
    de: {
        subject:
            'Anfrage aidminutes.anamnesis / Digitale Anamnese für die Arztpraxis',
        body: 'ich interessiere mich für die von Ihnen entwickelte Digitale Anamnese für die Arztpraxis. Bitte informieren Sie mich, sobald Lizenzen erhältlich sind.',
    },
    en: {
        subject:
            'Request aidminutes.anamnesis / Digital anamnesis for medical practices',
        body: 'I am interested in the Digital Anamnesis for the Medical Practice developed by you. Please inform me as soon as licenses are available.',
    },
}

// TODO remove me || relocate me
export const contactButtonHref = (langCode, skipMailTo = false) =>
    `${
        skipMailTo ? '' : 'mailto:'
    }business.anamnesis@aidminutes.com?subject=${encodeURIComponent(
        (contactButtonTexts[langCode] || contactButtonTexts['en']).subject
    )}&body=${encodeURIComponent(
        (contactButtonTexts[langCode] || contactButtonTexts['en']).body
    )}`

export const Anamnesis = ({ data, location, langCode, pageContext }) => {
    const prefix = pageContext?.prefix ?? ''
    const anamnesisProductMenuData = getPageData(data, 'productMenuQuery')
    const { footerdata } = useFooterData(
        data,
        'footerQuery',
        'entities',
        prefix
    )
    const topmenu = getTopmenuData(data)
    const submenu = getSubmenuData(data)
    // TODO drupal
    const translations = data?.allTranslationsJson ?? ''
    const seoTitle = pageContext?.seoTitle ?? 'app.anamnesis'
    const seoDescription =
        langCode === 'DE'
            ? 'Die beste mehrsprachige Anamnese-Lösung vor der Behandlung'
            : 'The multilingual medical history solution before the examination'
    const seoImageUrl =
        langCode === 'DE' ? `${seo_anamnesis_de_fb}` : `${seo_anamnesis_en_fb}`

    const seoImageAlt = pageContext?.seoTitle
    const seoType = 'website'
    const seoUri =
        langCode === 'DE'
            ? 'https://www.aidminutes.com/product-anamnesis'
            : 'https://www.aidminutes.com/en/product-anamnesis'

    return (
        <Layout
            path={location.pathname}
            location={location}
            langCode={langCode}
            productmenuData={anamnesisProductMenuData}
            translations={translations}
            pageContext={pageContext}
            topmenu={topmenu}
            submenu={submenu}
            footerdata={footerdata}
        >
            <Seo
                lang={langCode}
                title={seoTitle}
                type={seoType}
                url={seoUri}
                image={seoImageUrl}
                alt={seoImageAlt}
                description={seoDescription}
                //twitter:card properties directly imported
            />
            <AnamnesisStartNew />
            <AppTargetGroups />
            <ClinicalStudiesNew />
        </Layout>
    )
}
