import styled from 'styled-components'
import { MdPhone, MdEmail } from 'react-icons/md'
import { colors, fontSizes } from '../../../Theme'

const { mainFontColor } = colors
const { h4Size } = fontSizes

export const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 900px) {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        width: 100vw;
        height: 100%;
        justify-items: start;
    }
    @media (min-width: ${({ theme }) => theme.desktopXL}) {
        justify-items: stretch;
        max-height: 100%;
    }
`

export const ContactLocationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    order: 1;
    overflow-x: hidden;
    padding: 0;
    align-items: center;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: block;
        padding: 5vh 5vw 5vh 5vw;
    }
    @media (min-width: ${({ theme }) => theme.desktop}) {
        padding: 5vh 5vw 5vh 10vw;
    }
`

export const ContactBlockReachability = styled.div`
    margin: 1rem 0;
    address {
        display: flex;
        align-items: baseline;
        flex-direction: column;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: initial;
    }
`
const ContactReachabilityContainer = styled.div`
    align-items: center;
    color: ${mainFontColor};
    display: flex;
    > a {
        color: ${(props) => (props.darkMode ? 'white' : mainFontColor)};
        text-decoration: none;
    }

    &:nth-child(3) {
        margin-top: 1rem;
    }
`
export const ContactPhone = styled(ContactReachabilityContainer)`
    font-size: ${h4Size};
    font-weight: 700;
    line-height: 1.5em;
    a {
        unicode-bidi: embed;
        direction: ltr;
    }
`
export const ContactEmail = styled(ContactReachabilityContainer)`
    justify-content: center;
    font-size: ${h4Size};
    font-weight: 700;
    line-height: 1.5em;
`
export const SupportEmail = styled(ContactReachabilityContainer)`
    justify-content: center;
    a {
        font-weight: 700;
    }
`

export const PhoneIcon = styled(MdPhone)`
    margin: 0 0.5rem 0 0;
`
export const EmailIcon = styled(MdEmail)`
    margin: 0 0.5rem 0 0;
`

export const ContactAddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 3rem 0;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: 100px 0;
    }
`
