import styled from 'styled-components'
import { ContentGrid, SectionTitle } from '../../../index'

export const Grid = styled(ContentGrid)`
    grid-template-areas:
        'headline'
        'content'
        'aside';
    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-template-areas:
            'headline headline'
            'content aside';
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        grid-template-areas:
            'headline'
            'content'
            'aside';
    }
`
export const ContentContainer = styled.div`
    grid-area: content;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        max-width: 920px;
    }
`
export const Title = styled(SectionTitle)`
    grid-area: headline;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        max-width: 920px;
    }
`
export const AsideContainer = styled.div`
    grid-area: aside;
`
