import styled from 'styled-components'
import { colors, fontSizes } from '../../../Theme'

const { mainFontColor, brightMenuLinkColor } = colors
const { h3Size, h5Size } = fontSizes

export const Subheadline = styled.p`
    /* font-size: 1.5rem; */
    font-size: ${h5Size};
    color: ${mainFontColor};
    font-weight: 700;
    line-height: 1.4em;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h3Size};
        margin-bottom: ${(props) => props.marginBottom || 'inherit'};
    }
`

export const TextContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        max-width: 50vw;
        margin-bottom: ${(props) => props.marginBottom || 'inherit'};
    }
`

// temporarily removed from page
export const LogoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 45%);
    margin-bottom: 6rem;
    max-width: calc(400px + 6rem);
    padding: 0 2rem;
    grid-column-gap: 10%;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-column-gap: 6rem;
        margin-bottom: 3rem;
        padding: 0;
    }
    > * {
        border-top: 1px solid ${brightMenuLinkColor};
        padding-top: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    img {
        max-width: 100%;
        @media (min-width: ${({ theme }) => theme.tablet}) {
            max-width: 200px;
        }
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        > div:first-child img {
            max-width: 180px;
        }
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-template-columns: repeat(2, 1fr);
        > * {
            border-top: none;
        }
    }
`
export const SideImageDesktopOnly = styled.div`
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100vw;
    overflow: hidden;
    img {
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
    }
    @media (min-width: 1150px) {
        display: block;
        img {
            margin-right: -26%;
            margin-inline-end: -26%;
        }
    }
    @media (min-width: 1300px) {
        img {
            margin-right: -25%;
            margin-inline-end: -25%;
        }
    }
    @media (min-width: 1450px) {
        img {
            margin-right: -15%;
            margin-inline-end: -15%;
        }
    }
    @media (min-width: 1600px) {
        img {
            margin-right: -5%;
            margin-inline-end: -5%;
        }
    }
    @media (min-width: 1700px) {
        img {
            margin-right: 0;
            margin-inline-end: 0;
        }
    }
`
