import React, { useRef } from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks'
import { getTranslations } from '../../utils'
import { colors } from '../Theme'

const { darkBlueishBgColor, white, modalOuterBg } = colors

const ModalOuter = styled.div`
    background: ${modalOuterBg};
    display: ${(props) => (props.show ? 'block' : 'none')};
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
`

const ModalInner = styled.div`
    background: ${white};
    border-radius: 8px;
    height: auto;
    max-width: 800px;
    position: fixed;
    padding: 30px;
    top: 10%;
    width: 100;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        padding: 50px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
    }
`

const ModalCloseContainer = styled.div`
    display: none;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: flex;
        justify-content: center;
    }
`
const ModalClose = styled.button`
    padding: 10px 20px;
    background-color: ${darkBlueishBgColor};
    color: ${white};
    font-weight: 700;
    border: none;
    border-radius: 8px;
`
export const Modal = ({ children, show, handleClose, translations }) => {
    const { close } = getTranslations(translations)

    const ref = useRef()
    useOnClickOutside(ref, () => handleClose())
    return (
        <ModalOuter show={show}>
            <ModalInner ref={ref}>
                {children}
                <ModalCloseContainer>
                    <ModalClose onClick={handleClose}>{close}</ModalClose>
                </ModalCloseContainer>
            </ModalInner>
        </ModalOuter>
    )
}
