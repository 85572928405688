import React from 'react'
import { Container, Headline, AddInfo } from './AsideDownload.styled'
import { useTheme } from '../Theme'
import { StoreLinks } from '../StoreLink'
import { AlternativeDownload } from '../AlternativeApkDownload'
import { LegalTexts } from './LegalTexts'

export const AsideDownload = ({
    headline,
    storelinks,
    setVisible,
    apksHeadline,
    androidlegal,
    addInfo,
}) => {
    const themeContext = useTheme()

    // const androidFiles = storelinks.filter(link => {
    //     if (link.entity.fieldApk) return link.entity.fieldSystemname
    // })
    const { systemNames, legalTexts } = transformApkData(
        storelinks,
        androidlegal
    )

    return (
        <Container darkMode={themeContext.mode === 'dark'}>
            <Headline darkMode={themeContext.mode === 'dark'}>
                {headline}
                {addInfo && <AddInfo>{addInfo}</AddInfo>}
            </Headline>
            <StoreLinks stores={storelinks} />

            <AlternativeDownload
                headline={apksHeadline}
                systems={systemNames}
                setVisible={setVisible}
            />
            <LegalTexts texts={legalTexts} />
        </Container>
    )
}

function transformApkData(array, androidlegal) {
    const legalTexts = []
    legalTexts.push(androidlegal)
    const systemNames = []
    array.forEach((element) => {
        if (element.entity.fieldApk) {
            systemNames.push(element.entity.fieldSystemname)
            if (element.entity.fieldAdditionalInfo)
                legalTexts.push(element.entity.fieldAdditionalInfo)
        }
    })

    return { systemNames, legalTexts }
}
