import React from 'react'
import styled from 'styled-components'
import { IoLogoFacebook, IoLogoTwitter, IoLogoLinkedin } from 'react-icons/io'
import { colors } from '../Theme'

const { mainFontColor, white } = colors

const SocialIconsContainer = styled.div`
    opacity: ${(props) => props.opacity || '1'};
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
    a {
        margin: 0 35px 0 0;
        /* &:first-child {
            margin: 0 35px 0 0;
        } */
        &:last-child {
            /* margin: 0 0 0 35px; */
            margin: 0;
        }
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-top: 1.5rem;
        max-width: 320px;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        justify-content: center;
    }
`

const SocialIconsWrap = styled.div`
    display: flex;
    justify-content: flex-start;

    svg {
        color: ${(props) => props.iconColor || mainFontColor};
        /* SOCIAL ICONS. feste Größe */
        font-size: 35px;

        @media (min-width: ${({ theme }) => theme.tablet}) {
            color: ${(props) => props.iconColor || white};
        }
    }
`

export const SocialIcons = ({ iconColor, opacity }) => {
    return (
        <SocialIconsWrap iconColor={iconColor}>
            <SocialIconsContainer opacity={opacity}>
                <a
                    href="https://twitter.com/aidminutes"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <IoLogoTwitter />
                </a>
                <a
                    href="https://facebook.com/aidminutes"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <IoLogoFacebook />
                </a>
                <a
                    href="https://www.linkedin.com/company/aidminutes"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <IoLogoLinkedin />
                </a>
            </SocialIconsContainer>
        </SocialIconsWrap>
    )
}
