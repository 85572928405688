import React from 'react'
import {
    ButtonStyled,
    ContentContainer,
    IconContainer,
    ButtonText,
} from './Button.styled'
import { AiOutlineCloudDownload } from 'react-icons/ai'
export const Button = ({
    text,
    buttonWidth,
    color,
    bgColor,
    onClick,
    margin,
    enabled,
}) => {
    return (
        <ButtonStyled
            buttonWidth={buttonWidth}
            color={color}
            bgColor={bgColor}
            onClick={onClick}
            margin={margin}
            enabled={enabled}
        >
            <ContentContainer>
                <div>
                    <ButtonText className="language-dependent-font">
                        {text}
                    </ButtonText>
                </div>
                <IconContainer>
                    <AiOutlineCloudDownload />
                </IconContainer>
            </ContentContainer>
        </ButtonStyled>
    )
}
