import styled from 'styled-components'
import { FeatureWrapNoFlex, ContentGrid, Wysiwyg } from '../../../basic'
import { colors, fontSizes } from '../../../Theme'

const { mainFontColor, brightFontColor } = colors
const { h2Size, h4Size } = fontSizes

export const SafetySubheadline = styled.h3`
    color: ${brightFontColor};
    font-size: ${h4Size};
    font-weight: bold;
    line-height: 1.375em;
    margin: 0 0 1em;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h2Size};
        font-weight: 400;
        grid-column: span 4;
        text-align: center;
    }
`

export const SafetyTextContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
`

export const SafetyText = styled.div`
    color: ${mainFontColor};

    p {
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        width: 80%;
        p {
            font-size: ${h4Size};
            line-height: 1.375em;
            text-align: center;
        }
    }
`

export const SafetyWrap = styled(FeatureWrapNoFlex)`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const SafetyGrid = styled(ContentGrid)`
    padding: 0;
`

// deprecated
export const BottomText = styled(Wysiwyg)`
    text-align: center;
    margin: 1rem 0;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: initial;
    }
`
