import React from 'react'
import {
    SectionBackgroundDesktopOnly,
    Wysiwyg,
    PageImageMobileOnly,
    SectionTitleH1,
    FeatureWrapNoFlex,
} from '../../../basic'
import { getDrupalData } from '../../../../utils'
import {
    Subheadline,
    TextContainer,
    SideImageDesktopOnly,
} from './VaccinateStart.styled'

export const VaccinateStart = ({ data, pageContext }) => {
    const { mainHeadline, subHeadline, text, bgImage, imageSrc, altImgSrc } =
        getDrupalData(data) // TODO add 'partnerLogos' to fetch partner logos data from Drupal (it had to be deleted because of BMG funding)
    return (
        <SectionBackgroundDesktopOnly
            id="vaccinate-start"
            background={`url(${bgImage}) no-repeat`}
            backgroundSize="cover"
            backgroundPostionY="center"
            backgroundPostionX="center"
            backgroundAttachment="fixed"
        >
            <PageImageMobileOnly src={altImgSrc} alt={mainHeadline} />

            <FeatureWrapNoFlex margin="10vh 0 5vh" maxWidth="920px">
                <SectionTitleH1 marginBottom="6rem" marginMobile="1.5em 0">
                    {mainHeadline}
                </SectionTitleH1>
                <Subheadline
                    marginBottom="6rem"
                    textTransform="uppercase"
                    marginTop="0"
                >
                    {subHeadline}
                </Subheadline>
                <TextContainer marginBotto="6rem">
                    <Wysiwyg mobileMargin="5rem 0" text={text} />
                </TextContainer>
                <SideImageDesktopOnly>
                    <img src={imageSrc} alt="" />
                </SideImageDesktopOnly>
            </FeatureWrapNoFlex>
        </SectionBackgroundDesktopOnly>
    )
}
