import React, { useEffect, useRef } from 'react'
import {
    BackgroundVideoPlayer,
    BackgroundVideoTransparentOverlay,
} from './BackgroundVideo.styled'
import { useIsVisible } from 'react-is-visible'

export const BackgroundVideo = ({
    data,
    poster,
    darkMode,
    displayMobile,
    overlayPosition,
    gradientBackground,
    zIndex,
    hasFixedWidth,
    playbackRate,
}) => {
    const nodeRef = useRef()
    const isVisible = useIsVisible(nodeRef)

    const videoPoster = poster || ''

    const videos = data.map((video, index) => {
        // TODO drupal
        return (
            <source
                key={index}
                src={video.downloadFile.publicURL}
                type={video.entity.filemime}
            />
        )
    })

    useEffect(() => {
        const startVideo = (element) => {
            element.currentTime = 0
            element.load()

            element.playbackRate = playbackRate || 1
        }
        if (nodeRef.current) {
            isVisible ? startVideo(nodeRef.current) : nodeRef.current.pause()
        }
    }, [isVisible, playbackRate])

    return (
        <BackgroundVideoTransparentOverlay
            overlayPosition={overlayPosition}
            gradientBackground={gradientBackground}
            darkMode={darkMode}
        >
            <BackgroundVideoPlayer
                muted
                autoPlay={true}
                poster={videoPoster}
                ref={nodeRef}
                displayMobile={displayMobile}
                zIndex={zIndex}
                hasFixedWidth={hasFixedWidth}
            >
                {videos}
            </BackgroundVideoPlayer>
        </BackgroundVideoTransparentOverlay>
    )
}
