import React from 'react'
import styled from 'styled-components'
import { IoLogoFacebook, IoLogoTwitter } from 'react-icons/io'
import { colors, fontSizes } from '../../../Theme'

const { mainFontColor, white, darkFontColor } = colors
const { h2Size, h4Size, minimalBiggerSize } = fontSizes

export const BlockQuote = styled.blockquote`
    direction: ltr;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin: 0;
    }
    p {
        @media (min-width: ${({ theme }) => theme.tablet}) {
            color: ${white};
            font-size: ${h2Size};
        }
        font-size: ${h4Size};
        font-weight: 700;
        line-height: 1.3em;
    }

    cite {
        color: ${(props) => (props.darkMode ? 'black' : mainFontColor)};
    }
`

export const EmoTextContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${white};
        font-size: ${h2Size};
        margin-top: 10vw;
    }
    font-size: ${minimalBiggerSize};
    font-weight: 700;
    line-height: 1.3em;
    margin-top: 2rem;
    text-align: center;
`

export const PhotoCreditsContainer = styled.div`
    margin-top: 2rem;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        * {
            color: ${white};
        }
        position: absolute;
        bottom: 0;
        left: 5vw;
        margin-top: 0;
    }
`

export const ShareText = styled.p`
    text-align: center;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${white};
    }
`
export const ThankUContainer = styled.div`
    text-align: center;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${white};
    }
`
const SocialIconsWrap = styled.div`
    display: flex;
    justify-content: center;

    svg {
        color: ${mainFontColor};
        /* SOCIAL ICONS. feste Größe */
        font-size: 35px;
        margin: 0 35px;

        @media (min-width: ${({ theme }) => theme.tablet}) {
            color: ${white};
        }
    }
`

export const DonateContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        max-width: 80%;
        margin: 0 auto;
    }
`

export const DonateContainerText = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;

    p {
        margin: 0;
        margin-bottom: 1.2rem;
        @media (min-width: ${({ theme }) => theme.tablet}) {
            color: ${white};
        }
    }
`

export const BottomTextContainer = styled.div`
    color: ${darkFontColor};
    font-size: ${h4Size};
    line-height: 1.3em;
    text-align: center;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        max-width: 80%;
        margin: 0 auto;
        margin-top: 2rem;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin-top: 2rem;
        font-size: ${minimalBiggerSize};
    }
`

const SocialIconsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        max-width: 320px;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
    }
`

export const SocialIcons = () => {
    return (
        <SocialIconsWrap>
            <SocialIconsContainer>
                <a
                    href="https://facebook.com/aidminutes"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <IoLogoFacebook />
                </a>
                <a
                    href="https://twitter.com/aidminutes"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <IoLogoTwitter />
                </a>
            </SocialIconsContainer>
        </SocialIconsWrap>
    )
}
