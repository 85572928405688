/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

export const Seo = ({
    description,
    lang,
    meta,
    title,
    image,
    alt,
    type,
    url,
}) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `)

    const metaDescription = description || site.siteMetadata.description

    /**
     * Convert the HTML `lang` attribute to ISO conform string
     * @param {String} langCode
     * @example
     * // 'ku-CKB'
     * getISOUpperCaseLanguageCode('KU_CKB')
     */
    const getISOUpperCaseLanguageCode = (langCode) =>
        langCode
            .split(/[-_]/)
            .map(
                (elem, i) =>
                    (elem = i === 0 ? elem.toLowerCase() : elem.toUpperCase())
            )
            .join('-')

    lang = getISOUpperCaseLanguageCode(lang)

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:type`,
                    content: type,
                },
                {
                    property: `og:url`,
                    content: url,
                },
                {
                    property: `og:image`,
                    content: image,
                },
                {
                    property: `og:image:alt`,
                    content: alt,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:site_name`,
                    content: site.siteMetadata.author,
                },
                {
                    property: `og:locale`,
                    content: `de_DE`,
                },
                {
                    property: `og:locale:alternate`,
                    content: `en_US`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:image`,
                    content: image,
                },

                // {
                //   name: `robots`,
                //   content: `noindex`,
                // },
            ].concat(meta)}
        />
    )
}

Seo.defaultProps = {
    lang: `de`,
    meta: [],
    description: ``,
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
}
