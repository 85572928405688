import React from 'react'
import { useInView } from 'react-intersection-observer'
import {
    TextContainer,
    ImageContainer,
    ImageContainerWrapper,
    ExternalLinkWrapper,
    ExternalLinkIcon,
} from './HediCommunication.styled'
import {
    Section,
    FlowContentGrid,
    SectionTitle,
    SubHeadline,
    Wysiwyg,
} from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import { MobileHeadline } from '../../../MobileHeadline'
import beschwerden from '../../../../images/hedi_02_beschwerden_blue.png'

import { colors } from '../../../Theme'

export const HediCommunication = ({ data, translations, langCode }) => {
    const [ref, inView, entry] = useInView()

    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    return (
        <Section id="hedi-features">
            <MobileHeadline
                paginationNumber={
                    data?.fieldPagination?.entity?.fieldPaginationNummer
                }
                text={data?.fieldMainHeadline?.value}
                isHedi
            />
            <SitePagination
                pageNumber={
                    data?.fieldPagination?.entity?.fieldPaginationNummer
                }
                pageText={data?.fieldPagination?.entity?.fieldPaginationText}
                textTransform="initial"
            />
            <FlowContentGrid
                columns="50% 40%"
                gap="10%"
                mobilePadding="0"
                padding="0 8vw 0 4vw"
            >
                <SubHeadline displayMobile>
                    {data?.fieldPreHeadline}
                </SubHeadline>
                <TextContainer className="not-in-view" ref={ref}>
                    <SectionTitle
                        textTransform="uppercase"
                        displayMobile="none"
                    >
                        {data?.fieldMainHeadline?.value}
                    </SectionTitle>
                    <SubHeadline displayDesktop margin="0 0 5rem 0">
                        {data?.fieldPreHeadline}
                    </SubHeadline>
                    <Wysiwyg text={data?.fieldWysiwyg?.value}>
                        {data?.fieldWysiwyg?.value}
                    </Wysiwyg>
                </TextContainer>
                <ImageContainerWrapper>
                    <ImageContainer background={`url(${beschwerden})`} />
                    <ExternalLinkWrapper>
                        <Wysiwyg
                            text={data?.fieldPreButtonText?.value}
                            darkMode={colors.hediBlueFontColor}
                            colorOnBreakpointMobile={colors.hediBlueFontColor}
                            colorOnBreakpointTablet={colors.hediBlueFontColor}
                            colorOnBreakpointDesktop={colors.hediBlueFontColor}
                        />
                        <ExternalLinkIcon />
                    </ExternalLinkWrapper>
                </ImageContainerWrapper>
            </FlowContentGrid>
        </Section>
    )
}
