import styled from 'styled-components'
import ClinicalStudiesImage from '../../../../images/anamnesis_03_clinical_studies.svg'
import {
    darkestFontColor,
    black,
    white,
} from '../../../Theme/ThemeDefinitions/themeMode'

export const Grid = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: grid;
        grid-template-columns: 50% 35%;
        grid-template-rows: 1fr;
        gap: 5%;
        margin: 0 1rem;
        padding: 10vh 1rem 7.5vh 2vw;
    }
`
export const Header = styled.div`
    display: flex;
    flex-direction: column;
    p {
        margin: 0;
        color: ${black};
        line-height: 1rem;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        padding: 0;
        p {
            color: black;
        }
    }
`
export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2.5rem 0 0;
    padding: 0 5vw 0;
    p {
        color: ${(props) => (props.darkMode ? black : darkestFontColor)};
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: flex-start;
        padding: 0;
        margin: 0;
        p {
            color: ${(props) => (props.darkMode ? white : darkestFontColor)};
        }
    }
`
export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 5%;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        justify-content: space-between;
    }
`
export const Image = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    min-width: 100%;
    background: url(${ClinicalStudiesImage}) no-repeat center;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-color: transparent;
    background-size: contain;
    @media (min-width: ${({ theme }) => theme.mobile}) {
        min-height: 75vh;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        justify-content: center;
        align-items: center;
        gap: 5%;
        height: 100%;
    }
`
export const Description = styled.p`
    font-size: 1rem;
    line-height: 2rem;
    white-space: pre-wrap;
    margin: ${(props) => props.margin || '1rem 0'};
    span {
        font-weight: 700;
    }
`
export const ButtonWrap = styled.div`
    margin: 1rem;
    display: flex;
    min-width: 100%;
    @media (min-width: ${({ theme }) => theme.mobile}) {
        margin: 0;
        width: 100%;
        justify-content: center;
    }
`
export const StudyInfoWrapper = styled.div`
    margin: 1rem 0;
    p,
    li {
        color: ${(props) => (props.darkMode ? black : darkestFontColor)};
        @media (min-width: ${({ theme }) => theme.tablet}) {
            color: ${(props) => (props.darkMode ? white : darkestFontColor)};
        }
    }
`
export const StudyInfoList = styled.ul`
    margin: 0 0.5rem;
`
export const StudyInfoListedItem = styled.li``
