import styled from 'styled-components'
import { colors } from '../Theme'
import { Wysiwyg } from '../basic'

const { inactiveItemsColor, primaryColor } = colors

export const NoteWYSIWYG = styled(Wysiwyg)`
    .wysiwyg p {
        text-align: center !important;
    }
`

export const Checkbox = styled.input`
    display: none;

    & + label::before {
        min-width: 15px;
        width: 15px;
        height: 15px;
        border: 2px solid ${inactiveItemsColor};
        background-color: ${inactiveItemsColor};
        display: block;
        content: '';
        float: left;
        margin-inline-end: 5px;
        margin-top: 5px;

        @media (max-width: ${({ theme }) => theme.tablet}) {
            min-width: 30px;
            width: 30px;
            height: 30px;
            margin-inline-end: 20px;
        }
    }
    &:checked + label::before {
        box-shadow: inset 0px 0px 0px 3px ${inactiveItemsColor};
        background-color: ${primaryColor};
    }
`

export const Label = styled.label`
    display: flex;
    line-height: 1.6em;
`

export const CheckboxContainer = styled.div`
    margin-bottom: 1rem;
`

export const CheckboxWrap = styled.div``

export const ConditionalDownloadWrap = styled.div`
    display: flex;
    flex-direction: column;
`

export const DownloadForm = styled.form`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
`

export const TextContainer = styled.div`
    margin: 1rem 0;
    text-align: center;
    p {
        margin: 0;
    }
`
