import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Wysiwyg } from '../../basic'
import { SubmenuIcon } from '../Submenu/Submenu.styled'

import IconAnamnese from '../../../images/icon_anamnesis_app.png'
import IconRescue from '../../../images/icon_rescue_app.png'
import { colors, useTheme } from '../../Theme'

const { darkBgColor, white } = colors

const aidminutesText = '<strong>HOME aid</strong>minutes'

const MobileMenuHeaderContainer = styled.div`
    background-color: ${darkBgColor};
    color: ${white};
    display: block;
    line-height: 1.778rem;

    padding: 25px 15px;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: none;
    }
`

const MobileMenuHeaderLink = styled(Link)`
    align-items: center;
    direction: ltr;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
`

export const MobileMenuHeader = ({ productText, product }) => {
    const themeContext = useTheme()
    let linkDestination
    // TODO: was ist hier mit Covid 19 und Multilanguage?
    switch (product) {
        case 'rescue.app':
            linkDestination = '/product-rescue'
            break
        case 'anamnesis.app':
            linkDestination = '/product-anamnesis'
            break
        default:
            linkDestination = '/'
            break
    }

    const text = productText || aidminutesText
    let icon = ''

    if (product === 'anamnese') {
        icon = <SubmenuIcon src={IconAnamnese} alt="Icon Anamnese" />
    } else if (product === 'rescue') {
        icon = <SubmenuIcon src={IconRescue} alt="Icon Resuce" />
    }
    return (
        <MobileMenuHeaderContainer>
            <MobileMenuHeaderLink to={linkDestination}>
                <Wysiwyg darkMode={themeContext.mode === 'dark'} text={text} />
                {icon}
            </MobileMenuHeaderLink>
        </MobileMenuHeaderContainer>
    )
}
