import React from 'react'
import { getDrupalData } from '../../../../utils'
import { SubHeadline } from './VaccinateSignlanguage.styled'
import {
    Wysiwyg,
    MobileHeadline,
    SitePagination,
    ContentGrid,
    Section,
    ContentImageMobileOnly,
    ContentImageDesktopOnly,
    H2Wysiwyg,
    BigText,
} from '../../../index'

export const VaccinateSignlanguage = ({ data }) => {
    const {
        mainHeadline,
        subHeadline,
        text,
        fluidImage,
        alternativeMobileImage,
        paginationNumber,
        paginationText,
        altText,
    } = getDrupalData(data)
    return (
        <Section id="vaccinate-multilingual">
            <MobileHeadline
                primary
                paginationNumber={paginationNumber}
                text={paginationText}
            />
            <ContentImageMobileOnly
                image={alternativeMobileImage}
                alt={altText}
            />
            <SitePagination
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <ContentGrid columns="60% 35%" gap="5%" margin="22vh 0 15vh">
                <div>
                    <H2Wysiwyg
                        fontWeight="700"
                        textTransform="uppercase"
                        color="normal"
                        marginBottom="6rem"
                    >
                        {mainHeadline}
                    </H2Wysiwyg>
                    <SubHeadline margin="6rem 0">{subHeadline}</SubHeadline>
                    <BigText>
                        <Wysiwyg text={text} />
                    </BigText>
                </div>
                <ContentImageDesktopOnly alt={altText} fluid={fluidImage} />
            </ContentGrid>
        </Section>
    )
}
