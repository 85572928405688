import { useEffect, useState } from 'react'

export const getPageData = (data, item, entities = 'entities') => {
    return data?.drupal?.[item]?.[entities][0] ?? ''
}

export const useFooterData = (data, item, entities = 'entities', prefix) => {
    const [footerdata, setFooterdata] = useState([])
    useEffect(() => {
        const footermenues = data?.drupal?.[item]?.[entities][0].fieldMenues
        // TODO: temporary way to hardcode footer menu entries
        const legalLinksIndex = footermenues[2] ? 2 : 0

        const legals = (footermenues[legalLinksIndex].entity = {
            ...footermenues[legalLinksIndex].entity,
            fieldMenuitem: [
                ...footermenues[legalLinksIndex].entity.fieldMenuitem,
            ],
        })
        setFooterdata(footermenues)
        const legalsArray = legals.fieldMenuitem
        const legalsArrayLength = legals.fieldMenuitem.length
        return () => {
            legalsArrayLength === 5 && legalsArray.pop()
        }
    }, [data?.drupal, entities, item, prefix])

    return { footerdata }
}

export const getTopmenuData = (data) => {
    return getPageData(data, 'topMenuQuery').fieldMenuitem
}

export const getSubmenuData = (data) =>
    getPageData(data, 'submenuQuery').fieldMenues
