import styled from 'styled-components'
import { colors, fontSizes } from '../../../Theme'
import { BigSubHeadlineParagraph } from '../../../basic'

const { mainFontColor, brightFontColor, attentionColor } = colors
const { h3Size, h4Size, minimalBiggerSize } = fontSizes

export const BigParagraph = styled(BigSubHeadlineParagraph)`
    margin-top: ${(props) => props.marginTop || 'inherit'};
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin-top: 1.5rem;
    }
`
export const BigContainer = styled.div`
    color: ${(props) => props.color || mainFontColor};
    font-size: ${h3Size};
    font-weight: 700;
    line-height: 1.3em;
    margin: 0;
    margin-top: ${(props) => props.marginTop || 'inherit'};
    text-transform: ${(props) => props.textTransform || 'inherit'};
    word-break: break-word;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        font-size: ${minimalBiggerSize};
    }
    .wysiwyg {
        ul {
            position: relative;
            list-style: '... ';
        }
    }
`

export const SubParagraph = styled.div`
    font-size: ${h4Size};
    line-height: 1.3em;
    margin-top: ${(props) => props.marginTop || 'inherit'};
    @media (max-width: ${({ theme }) => theme.tablet}) {
        font-size: ${minimalBiggerSize};
    }
`

export const HeadlineParagraph = styled(BigSubHeadlineParagraph)`
    color: ${attentionColor};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-top: 0;
    }
`

export const AsideContainer = styled.aside`
    color: ${(props) => (props.darkMode ? brightFontColor : mainFontColor)};
    position: relative;
    button {
    }
`

export const AsideHeadline = styled.p`
    color: ${mainFontColor};
    direction: ltr;
    font-weight: 700;
    margin: 0 0 2rem 0;
`
export const BadgeContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        max-width: 320px;
        > svg {
            flex-grow: 0.5;
        }
        > div {
            flex-grow: 2;
        }
    }
`

export const GoogleTrademarkText = styled.p`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        bottom: 0;
        /* padding-inline-end: 4rem; */
        position: relative;
        margin: 3rem 0 1rem;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin: 1rem 0;
        position: relative;
    }
`
export const StoreLinksContainer = styled.div`
    @media (max-width: ${({ theme }) => theme.tablet}) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
    }
`
