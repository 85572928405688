import hardCodedData from '../hardCodedData'

export const applyInterval = ({ allPriceInfos, interval }) =>
    allPriceInfos?.find((p) => p.interval === interval)

// find a better variable name then currentPrice
export const getPrice = ({ currentPrice, quantity }) =>
    currentPrice?.tiers.find((t) => {
        return t.min <= quantity && (t.max >= quantity || t.max === null)
    })?.unitAmount || 0

export const calculateSingleCartItemPrice = ({ foundPrice, interval }) =>
    foundPrice / (100 * (interval === 'year' ? 12 : 1))

export const findTotalPrice = ({ currentPrice, quantity }) =>
    totalPrice({ foundPrice: getPrice({ currentPrice, quantity }), quantity })

export const totalPrice = ({ foundPrice, quantity }) =>
    (foundPrice / 100) * quantity || 0

export const addTax = (price) => price * 1.19

export const formatPrice = (price, langCode) =>
    langCode === 'en'
        ? `${price.toFixed(2) + ' €'}`
        : `${price.toFixed(2).replace('.', ',') + ' €'}`

export const priceByID = ({}, pricesToPick) => {
    // console.log(allPriceInfos)
    return {
        allPriceInfos: hardCodedData.allPricesInfo.filter(({ id }) =>
            pricesToPick.includes(id)
        ),
    }
}

export const createCartItem = ({
    quantity,
    interval,
    currentPrice,
    licenseType,
}) => {
    const totalPrice = findTotalPrice({ currentPrice, quantity })

    return {
        quantity,
        interval,
        licenseType,
        netto: totalPrice,
        brutto: addTax(totalPrice),
        priceID: currentPrice.id,
    }
}
