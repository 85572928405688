export { Home } from './home'
export { Anamnesis } from './anamnesis'
export { Rescue } from './rescue'
export { Impress } from './impress'
export { Privacy } from './privacy'
export { Tac } from './tac'
export { Error404Page } from './errorPages'
export { Cancellation } from './cancellation'
export { RescueCovid19 } from './rescue-covid19'
export * from './vaccinate'
export { HediPage } from './hedi'
