import React from 'react'
import { Error404Page } from '../pageTemplates'

// TODO: noch an Rest anpassen
const NotFoundPage = ({ data, location, pageContext }) =>
    !data ? null : (
        <>
            {/* TODO: multilanguage */}
            <Error404Page location={location} langCode="de" />
        </>
    )

export default NotFoundPage
