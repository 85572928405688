import styled from 'styled-components'
import { colors, fontSizes } from '../Theme'

const { brightFontColor, white } = colors
const { xSmallSize, h1Size } = fontSizes

export const Centerblock = styled.div`
    display: none;
    color: ${(props) =>
        props.darkMode
            ? 'black'
            : props.fontColor === 'white'
            ? white
            : brightFontColor};
    direction: ltr !important;
    /* padding-inline-start: 5vw; */
    padding-top: 10vh;
    width: 10vw;

    @media (min-width: ${(props) => props.breakpoint || '802px'}) {
        display: block;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        display: none;
    }
`

export const PageNumber = styled.div`
    position: relative;
    font-size: ${h1Size};
    font-weight: 700;
    line-height: 2em;
    margin: 0;
    text-align: center;
    width: 75px;
`

// TODO anamnesis page has a new standard about textTransform => make a new basic component
export const HorizontalPageText = styled.div`
    display: grid;
    font-size: ${xSmallSize};
    font-weight: 700;
    grid-template-columns: 1fr auto;
    grid-column-gap: 10px;
    height: 75px;
    margin-top: -85px;
    overflow: hidden;
    text-transform: ${(props) => props.textTransform || 'uppercase'};
    transform: rotate(90deg);
    transform-origin: bottom left;
    width: 70vh;
    span {
        align-self: center;
        letter-spacing: 0.167rem;
        transform-origin: center center;
        transform: rotate(180deg);
    }
`

export const PaginationStrokeContainer = styled.div`
    align-self: center;
    padding: 8px 0;
`

export const PaginationStroke = styled.div`
    background-color: ${(props) =>
        props.darkMode
            ? 'black'
            : props.fontColor === 'white'
            ? white
            : brightFontColor};
    content: '';
    height: 1px;
`
