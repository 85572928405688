import React from 'react'
import { Container, ContentContainer } from './OffCanvasContainer.styled'

export const OffCanvasContainer = ({
    children,
    status,
    direction,
    labelledby,
}) => {
    return (
        <Container
            aria-labelledby={labelledby}
            direction={direction}
            status={status}
        >
            <ContentContainer status={status}>{children}</ContentContainer>
        </Container>
    )
}
