import React from 'react'
import { BannerContainer, Icon, Description } from './TextBanner.styled'
import { Wysiwyg } from '../basic'

export const TextBanner = ({ text, image, dark, darkOnMobileView }) => {
    return (
        <BannerContainer>
            <Icon image={image} />
            <Description dark={dark} darkOnMobileView={darkOnMobileView}>
                <Wysiwyg text={text} />
            </Description>
        </BannerContainer>
    )
}
