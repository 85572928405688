import styled from 'styled-components'
import { SubmenuTriggerIcon } from '../../Menu/Submenu/Submenu.styled'
import { colors, fontSizes } from '../../Theme'

const { black, brightMenuLinkColor } = colors
const { minimalSmallerSize, h3Size, baseFontSize } = fontSizes

export const TriggerButton = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    /* ICON */
    font-size: ${minimalSmallerSize};
    font-weight: 400;
    text-transform: uppercase;
    span {
        align-items: center;
        display: flex;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        background: ${black};
        justify-content: space-between;
        padding: 15px;
        width: 100%;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${(props) => (props.darkMode ? 'white' : black)};
        padding-left: 0;
        padding-right: 0;
        padding-inline-start: ${(props) =>
            props.preheader === false ? '20px' : '0'};
        &:hover {
            /* color: ${brightMenuLinkColor}; */
        }
    }
    svg {
        font-size: ${baseFontSize};
        margin-inline-start: 0.5rem;
    }
`

export const OffCanvasMobileTriggerIcon = styled(SubmenuTriggerIcon)`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: none;
    }
    font-size: ${(props) =>
        props.open === true ? minimalSmallerSize : h3Size};
`
