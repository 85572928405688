import React from 'react'
import {
    DownloadSection,
    DownloadText,
    DonwloadWrapGrid,
    DownloadTitle,
    DownloadContentContainer,
} from './Downloads.styled'
import { SitePagination } from '../../../SitePagination'
import {
    HoneyCombGrid,
    HoneyCombImage,
    HoneyCombContent,
    HoneyComb,
} from './HoneyComb'
import aidminutesLogo from '../../../../images/aidminutes_logo_white.svg'
import { MobileHeadline } from '../../../MobileHeadline'
import { getDrupalData } from '../../../../utils'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '../../../Theme'

export const Downloads = ({ data }) => {
    const {
        // paginationNumber,  // TODO update pagination number in drupal
        paginationText,
        headline,
        mainHeadline,
    } = getDrupalData(data)

    const downloaditems = (data && data.downloadItems) || ''
    const text = (data && data.fieldText) || ''

    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    const themeContext = useTheme()
    return (
        <DownloadSection
            background={({ theme }) => theme.backgroundGradient}
            id="downloads"
        >
            <MobileHeadline paginationNumber="06" text={mainHeadline} />
            <SitePagination pageNumber="06" pageText={paginationText} />
            <DonwloadWrapGrid ref={ref} className="not-in-view">
                <DownloadContentContainer>
                    <DownloadTitle displayMobile="none">
                        {headline}
                    </DownloadTitle>
                    <DownloadText
                        darkMode={themeContext.mode === 'dark' ? 'white' : ''}
                    >
                        {text}
                    </DownloadText>
                    <HoneyCombGrid>
                        {downloaditems.map((item, index) => {
                            return (
                                <HoneyComb key={index} id={index + 1}>
                                    <HoneyCombContent
                                        content={item.downloadItem}
                                    />
                                </HoneyComb>
                            )
                        })}
                        <HoneyComb id={99}>
                            <HoneyCombImage
                                src={aidminutesLogo}
                                alt="aidminutes logo"
                            />
                        </HoneyComb>
                    </HoneyCombGrid>
                </DownloadContentContainer>
            </DonwloadWrapGrid>
        </DownloadSection>
    )
}
