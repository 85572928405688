import React from 'react'
import sanitize from 'sanitize-html'
import styled from 'styled-components'

const WysiwygContainer = styled.div`
    p:first-child {
        margin-top: ${(props) => props.noMargin || 0};
        margin-bottom: ${(props) => props.noMargin || 0};
    }
    direction: ${(props) => props.direction || 'inherit'};
    color: ${(props) => (props.darkMode ? 'black' : '')};
    font-size: ${(props) => props.fontSize || 'inherit'};
    line-height: ${(props) => props.lineHeight || '1.6em'};
    /* Ergänzend zu dem bisherigen Weg, im darkMode text einfach schwarz zu machen, kann man nun auch eine konkrete Farbe übergeben */
    color: ${(props) =>
        typeof props.darkMode !== Boolean && props.darkMode !== undefined
            ? props.darkMode
            : ''};
    color: ${(props) => props.colorOnBreakpointMobile};
    a {
        color: ${(props) => props.colorOnBreakpointMobile};
    }
    /* Setzt die Farbe ab Tablet-Größe */
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${(props) => props.colorOnBreakpointTablet};
        a {
            color: ${(props) => props.colorOnBreakpointTablet};
        }
    }
    /* Setzt die Farbe ab Desktop-Größe */
    @media (min-width: ${({ theme }) => theme.desktop}) {
        color: ${(props) => props.colorOnBreakpointDesktop};
        a {
            color: ${(props) => props.colorOnBreakpointDesktop};
        }
    }
    /* Möglichkeit das Margin mobil anzupassen */
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin: ${(props) => props.mobileMargin || 'inherit'};
    }
`

export const Wysiwyg = ({
    text,
    direction,
    fontdependable,
    darkMode,
    colorOnBreakpointMobile,
    colorOnBreakpointTablet,
    colorOnBreakpointDesktop,
    fontSize,
    mobileMargin,
    noMargin,
    lineHeight,
}) => {
    return (
        <WysiwygContainer
            noMargin={noMargin}
            mobileMargin={mobileMargin}
            fontSize={fontSize}
            darkMode={darkMode}
            colorOnBreakpointMobile={colorOnBreakpointMobile}
            colorOnBreakpointTablet={colorOnBreakpointTablet}
            colorOnBreakpointDesktop={colorOnBreakpointDesktop}
            lineHeight={lineHeight}
            className={`wysiwyg ${
                fontdependable === true ? ' language-dependent-font' : ''
            }`}
            direction={direction}
            dangerouslySetInnerHTML={{
                __html: sanitize(text, {
                    allowedClasses: {
                        a: ['download'],
                        span: ['test'],
                    },
                    allowedAttributes: {
                        a: ['href', 'target', 'rel'],
                    },
                }),
            }}
        ></WysiwygContainer>
    )
}
