import styled from 'styled-components'
import { colors } from '../../../Theme'

const { primaryColor } = colors

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        max-width: 85vw;
    }
`
export const TextContainer = styled.div`
    display: flex;
    padding: 0;
    flex-direction: column;
    height: fit-content;
    @media (min-width: ${({ theme }) => theme.mobileSmall}) {
        padding: 1rem;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: grid;
        padding: 3rem 1rem 0 0;
        height: auto;
        letter-spacing: 1.5px;
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        column-gap: 5rem;
        row-gap: 1rem;
    }
    @media (min-width: ${({ theme }) => theme.desktopXL}) {
        max-width: 75vw;
    }
`
export const Description = styled.p`
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
    margin: 0 0 2rem 0;
`
export const FeatureWrapper = styled.div``
export const FeatureTitle = styled.h3`
    font-weight: 700;
`
export const FeatureTitleFlag = styled.span`
    margin-right: 0.5rem;
    color: ${primaryColor};
`
export const FeatureDescription = styled.p`
    margin: 0 0 2.5rem 0;
`
export const FeatureImage = styled.img`
    display: block;
    min-height: 7.5rem;
    min-width: 7.5rem;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: none;
    }
`
export const FeatureImageGroup = styled.img`
    display: none;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: block;
        width: 100%; // safari
    }
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        max-width: 90vw;
    }
`
export const HeadlineContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        max-width: 65vw;
    }
`
