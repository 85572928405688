import styled from 'styled-components'
import { Paragraph } from '../../../basic'
import { colors, fontSizes } from '../../../Theme'

const { mainFontColor, brightFontColor } = colors
const { h4Size } = fontSizes

export const RescupeAppsH3 = styled.h2`
    color: ${(props) => (props.darkMode ? 'white' : mainFontColor)};
    /* !TODO !DRY Diese Komponente auslagern */
    /* Setzt die Farbe ab Tablet-Größe */
    @media (min-width: ${({ theme }) => theme.tablet}) {
        color: ${(props) => props.colorOnBreakpointTablet};
        a {
            color: ${(props) => props.colorOnBreakpointTablet};
        }
    }
    /* Setzt die Farbe ab Desktop-Größe */
    @media (min-width: ${({ theme }) => theme.desktop}) {
        color: ${(props) => props.colorOnBreakpointDesktop};
        a {
            color: ${(props) => props.colorOnBreakpointDesktop};
        }
    }
    font-size: ${h4Size};
    line-height: 1.337em;
    margin-top: 0;
`

export const RescueAppDownloadParagraph = styled(Paragraph)`
    border-top: 1px solid ${brightFontColor};
    font-weight: bold;
    letter-spacing: 0.333rem;
    line-height: 2em;
    padding-top: 2rem;
    text-align: center;
    text-transform: uppercase;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: 60px 0 30px;
        max-width: 500px;
        text-align: left;
    }
`
export const GetLicenseContainer = styled.div`
    width: 100%;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

export const TextContainer = styled.div`
    word-break: break-word;
    flex-direction: column;
    height: fit-content;
    padding: 5vw;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: flex;
        align-items: flex-start;
        margin: 22vh 0 15vh;
        padding: 0 1rem;
    }
`

export const VideoContainer = styled.div`
    flex-direction: column;
    height: fit-content;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: flex-start;
        display: flex;
        height: auto;
    }
`
