import styled from 'styled-components'
import externalLinkArrowIcon from '../../../../images/hedi_02_follow_link_arrow.png'
import { colors } from '../../../Theme'

const { hediBlueFontColor } = colors

export const TextContainer = styled.div`
    padding: 0;
    flex-direction: column;
    height: fit-content;
    order: 1;
    @media (min-width: ${({ theme }) => theme.mobileSmall}) {
        padding: 1rem;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: flex-start;
        display: flex;
        padding: 3rem 1rem;
        height: auto;
        justify-content: flex-start;
        letter-spacing: 1.5px;
        order: 0;
    }
`
export const ImageContainerWrapper = styled.div`
    display: grid;
    justify-items: center;
    grid-auto-flow: row;
    margin: 1rem 2rem;
    gap: 2rem;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: 2rem 0;
    }
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        grid-template-rows: 2fr 1fr;
        align-items: flex-start;
        gap: 0;
    }
    @media (min-width: ${({ theme }) => theme.desktopXL}) {
        margin-top: 6rem;
    }
`
export const ImageContainer = styled.div`
    display: flex;
    order: 0;
    justify-content: center;
    align-items: center;
    min-height: 30vh;
    min-width: 100%;
    background: ${(props) => props.background};
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-color: transparent;
    background-size: contain;
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        display: block;
        order: 0;
        padding: 1rem;
        min-height: 100%;
    }
`
export const ExternalLinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${hediBlueFontColor};
    gap: 16px;
    font-size: 5vw;
    font-weight: 700;
    line-height: 3rem;
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        font-size: 2.25rem;
    }
`
export const ExternalLinkIcon = styled.div`
    height: 1.625rem;
    width: 1.625rem;
    background: url(${externalLinkArrowIcon}) no-repeat center;
    background-size: contain;
`
