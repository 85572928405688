import styled from 'styled-components'
import { colors, fontSizes } from '../../../../Theme'

const { mainFontColor } = colors
const { h1Size, h3Size } = fontSizes

export const LocationContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-top: 50px;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
`

export const LocationTextContainer = styled.address`
    color: ${(props) => (props.darkMode ? 'white' : mainFontColor)};
    font-weight: 400;
`

export const LocationNumber = styled.p`
    color: ${mainFontColor};
    font-size: ${h1Size};
    font-weight: 700;
    line-height: 1.337em;
    margin: 0;
    margin-inline-end: 1rem;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h3Size};
        line-height: 1rem;
    }
`

export const LocationHead = styled.div`
    align-items: baseline;
    display: flex;
    margin-bottom: 1rem;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin-bottom: 1rem;
    }
`
