import styled from 'styled-components'

const FeatureWrapBasic = styled.div`
    padding: ${(props) => props.padding || '0 10vw 0 5vw'};
    margin: ${(props) => props.margin || '15vh 0 5vh'};
    width: ${(props) => props.width || '90vw'};
    max-width: ${(props) => props.maxWidth || 'unset'};

    @media (max-width: ${({ theme }) => theme.tablet}) {
        padding: 1rem;
        margin: 0;
        width: 100%;
    }
`

export const FeatureWrap = styled(FeatureWrapBasic)`
    display: flex;
    justify-content: ${(props) => props.justify || 'unset'};
    align-items: ${(props) => props.alignItems || 'unset'};
    @media (max-width: ${(props) => props.breakpoint || '800px'}) {
        margin: 1rem 0;
        padding: 0 1rem;
        justify-content: ${(props) => props.justifyMobile || 'flex-start'};
    }
`
export const FeatureWrapNoFlex = styled(FeatureWrapBasic)`
    @media (max-width: ${({ theme }) => theme.tablet}) {
        padding: 5vw;
        margin: 0;
    }
`

export const FeatureWrapNew = styled.div`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: ${(props) => props.padding || '0 10vw 0 5vw'};
        margin: ${(props) => props.margin || '15vh 0 5vh'};
        max-width: ${({ maxWidth }) => maxWidth || '100%'};
    }

    padding: 1rem;
    margin: 0;
`
