import React from 'react'
import { AnamnesisLogo } from '../../../AnamnesisLogo/AnamnesisLogo'
import {
    ContentGrid,
    SectionBackgroundDesktopOnly,
    SectionTitleAnamnesis,
    Wysiwyg,
} from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import { MobileHeadline } from '../../../MobileHeadline'
import {
    Description,
    Container,
    ContainerTopDecoration,
    ContainersSection,
    Title,
    ContainerDescription,
    FeaturesList,
    Feature,
    FeatureCheck,
    FeatureDescription,
} from './AppTargetGroups.styled'
import { useInView } from 'react-intersection-observer'
import GreyCircleCheck from '../../../../svg/icons/icon_check_circle.svg'
import { darkerFontColor } from '../../../Theme/ThemeDefinitions/themeMode'
import { RoundedButtonAnamnesisMailToLink } from '../../../RoundedButtonAnamnesis'

const hcPaginationNumber = '02'
const hcPaginationText = 'Anamnesis'
const hcHeadline = 'Unterstützung für Hausarztpraxen'
const hcSubline =
    'als Arztpraxis können Sie direkt von der Anamnese-App profitieren'
const hcDescription =
    'Sie vertreten eine Klinik oder Versicherung und möchten ebenfalls die Vorteile von aidminutes.anamnesis nutzen? Nehmen Sie Kontakt mit uns auf! Wir gehen auf Ihre spezifischen Anforderungen ein und entwickeln maßgeschneiderte Lösungen für Ihr Unternehmen.'
const containers = [
    {
        title: 'praxis',
        description:
            'Entlasten Sie Ihr Team und erhalten Sie eine valide Grundlage für Ihre Behandlung ',
        isLicensePurchasable: true,
        features: [
            'Effizientere Patientenaufnahme',
            'Leichtere Verständigung mit anderssprachigen Patient:innen',
            'Zeitsparend',
            'Anamnese direkt in PVS/AIS übertragbar',
            'DSGVO-konform',
            'Praxis-WLAN erforderlich',
            'kostenloser Testzeitraum: 30 Tage',
        ],
    },
    {
        title: 'kliniken',
        description:
            'Optimieren Sie die Arbeitsabläufe in der ambulanten und stationären Versorgung',
        isLicensePurchasable: false,
        features: [
            'Gezieltere Lenkung des Personaleinsatzes',
            'Schnellere Differenzierung zwischen eindeutigen und komplexeren Krankheitsbildern',
            'Geringeres Fehlerrisiko',
            'Steigerung der Versorgungsqualität',
            'Einsparung von Kosten',
        ],
    },
    {
        title: 'versicherungen',
        description:
            'Reduzieren Sie Sekundärkomplikationen und unnötige Therapien',
        isLicensePurchasable: false,
        features: [
            ' Automatische Dokumentation und Integration in die ePA',
            'Transparenz für alle Versorgungsebenen',
            'Steigerung der Versorgungsqualität',
            'Verringerung von Mehrfachanamnesen',
        ],
    },
]
export const AppTargetGroups = () => {
    const [ref, inView, entry] = useInView()

    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    // TODO import from Drupal
    return (
        <SectionBackgroundDesktopOnly id="anamnesis-app-target-groups">
            <MobileHeadline
                paginationNumber={hcPaginationNumber}
                text={hcHeadline}
                primary
                textToUppercase
            />
            <SitePagination
                pageNumber={hcPaginationNumber}
                pageText={hcPaginationText}
                textTransform="capitalize"
            />
            <ContentGrid mobilePadding="3rem 5vw" margin="10vh 0 5vh">
                <AnamnesisLogo />
                <SectionTitleAnamnesis fontColorDesktop={darkerFontColor}>
                    {hcHeadline}
                </SectionTitleAnamnesis>
                <Description toUppercase>{hcSubline}</Description>
                <Wysiwyg
                    text={`
                    <p>
                        aidminutes.anamnesis ermöglicht eine 
                        <ul>
                            <li>strukturierte Abfrage, die sich spezifisch an das Beschwerdebild anpasst</li>
                            <li>aussagekräftige Zusammenfassung aller maßgeblichen Patienteninformationen</li>
                            <li>einfache Übertragung der Anamnese in das Praxisverwaltungssystem</li>
                        </ul>
                    </p>
                    `}
                />
                <Description>{hcDescription}</Description>
                <ContainersSection ref={ref}>
                    {containers.map((targetGroup, index) => {
                        return (
                            <Container
                                key={index}
                                bgColor={
                                    targetGroup.isLicensePurchasable &&
                                    '#F5F2ED'
                                }
                            >
                                <ContainerTopDecoration />
                                <Title>{targetGroup.title}</Title>
                                <ContainerDescription>
                                    {targetGroup.description}
                                </ContainerDescription>
                                <RoundedButtonAnamnesisMailToLink />
                                <FeaturesList>
                                    {targetGroup.features.map(
                                        (feature, index) => {
                                            return (
                                                <Feature key={index}>
                                                    <FeatureCheck
                                                        image={GreyCircleCheck}
                                                    />
                                                    <FeatureDescription>
                                                        {feature}
                                                    </FeatureDescription>
                                                </Feature>
                                            )
                                        }
                                    )}
                                </FeaturesList>
                            </Container>
                        )
                    })}
                </ContainersSection>
            </ContentGrid>
        </SectionBackgroundDesktopOnly>
    )
}
