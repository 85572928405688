import React from 'react'
import {
    ContentImageMobileOnlyRcpAsImgTag,
    FeatureWrapNoFlex,
    HighSectionBackground,
    SectionTitleClinical,
    StudyNumber,
    Wysiwyg,
} from '../../../basic'
import {
    RescueAppVideoContentContainer,
    RescueAppVideoTextContainer,
    SectionWrap,
} from './RescueAppInsurances.styled'
import { SitePagination } from '../../../SitePagination'
import { MobileHeadline } from '../../../MobileHeadline'
import { getDrupalData } from '../../../../utils'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '../../../Theme'

export const RescueAppInsurances = ({ data, translations }) => {
    const { mainHeadline, text, paginationText, bgImage, mobileImage } =
        getDrupalData(data) // TODO add 'paginationNumber' to fetch pagination data number from Drupal (now it is hardcoded)
    const studiesNumber = (data && data.fieldPreHeadline) || ''
    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }
    const themeContext = useTheme()

    //console.log('mobileImage', mobileImage);
    const { src, srcSet, sizes } = mobileImage

    return (
        <SectionWrap>
            <HighSectionBackground
                background={`url(${bgImage}) no-repeat`}
                backgroundSize="cover"
                id="clinical-studies"
                backgroundHeight="55rem" //image height
            >
                <MobileHeadline paginationNumber="06" text={mainHeadline} />
                <picture>
                    <source srcSet={srcSet} sizes={sizes}></source>
                    <ContentImageMobileOnlyRcpAsImgTag
                        src={src}
                        srcSet={srcSet}
                        sizes={sizes}
                        alt={mainHeadline}
                    />
                </picture>

                <RescueAppVideoContentContainer>
                    <SitePagination
                        darkMode={themeContext.mode === 'dark'}
                        pageNumber="06"
                        pageText={paginationText}
                    />
                    <FeatureWrapNoFlex
                        padding="0vh 0vw 10% 5vw"
                        margin="22vh 0 5vh"
                        className="not-in-view"
                        ref={ref}
                    >
                        <RescueAppVideoTextContainer>
                            <SectionTitleClinical
                                displayMobile="none"
                                dark
                                textTransform="uppercase"
                            >
                                <Wysiwyg
                                    darkMode={themeContext.mode === 'dark'}
                                    text={mainHeadline}
                                />
                            </SectionTitleClinical>
                            <StudyNumber
                                darkMode={themeContext.mode === 'dark'}
                            >
                                {studiesNumber}
                            </StudyNumber>
                            <div>
                                <Wysiwyg
                                    colorOnBreakpointTablet={
                                        themeContext.mode === 'dark'
                                            ? 'black'
                                            : ''
                                    }
                                    text={text}
                                />
                            </div>
                        </RescueAppVideoTextContainer>
                    </FeatureWrapNoFlex>
                </RescueAppVideoContentContainer>
            </HighSectionBackground>
        </SectionWrap>
    )
}
