import { createGlobalStyle } from 'styled-components'
import downloadCloud from '../../images/downloadCloud.png'
import { keyFrameFadeIn, keyFrameTranslate, keyFramesRipple } from './KeyFrames'
import { fontSizes, colors } from '../Theme'

const { baseFontSize } = fontSizes
const { darkFontColor, white } = colors

export const GlobalStyles = createGlobalStyle`

* {
    box-sizing: border-box;
}

$content: ${(props) => props.langCode};

html {
    height: 100%;
    font-size: ${baseFontSize};
    line-height: 1.6em;
    direction: ${(props) => props.direction || 'unset'};
    font-family: Open Sans, sans-serif;
    &:lang(ku-CKB) {
        .language-dependent-font {
            font-family: 'Scheherazade', serif;
        }
    }
}

body {
    * {
        transition: color .5s ease;
        background: .5s ease;
    }
}
p, li {
    letter-spacing: 0.5px;
}
ul {
    padding: 0;
    padding-left: 1.3rem;
}

li {
    line-height: 1.6em;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        /* line-height: 2em; */
    }
}

address {
    font-style: normal;
    font-weight: unset;
    line-height: 1.6em;
}

body {
    background: ${white};
    color: ${darkFontColor};
}

.wysiwyg{
    p {
        line-height: 1.75em;
    }

    li {
        margin-bottom: 0.5em;
    }

    ul {
        margin: 3rem 0;
    }

}

a {
    color: ${darkFontColor};
    position: relative;

    &.download:after {
        content: "";
        width: 30px;
        height: 30px;
        background: url(${downloadCloud}) no-repeat;
        display: inline-block;
        position: absolute;
        margin-inline-start: 5px;
        top: 0px;
    }
}

section {
    max-width: 100vw;
    overflow: hidden;
}

.language-dependent-font {
    /* font-family: ${(props) =>
        props.langCode === 'ar'
            ? `'Scheherazade', serif`
            : `'Open Sans', sans-serif`}; */
}

.direction-dependent-padding {
    &__centerblock {
  
      padding-left: ${(props) => (props.direction === 'ltr' ? '5vw' : 'unset')};
      padding-right: ${(props) =>
          props.direction === 'ltr' ? 'unset' : '5vw'};

    }

}

.not-in-view {
    opacity: 0;
}

.fadeInUp {
    animation: ${keyFrameFadeIn} 2s ease-in-out 0s, ${keyFrameTranslate} 1s ease-in-out 0s;
} 

.ripple {
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: currentColor;
        visibility: hidden;
        z-index: 2;
    }

    &:not(:active):before {
        animation: ${keyFramesRipple} 0.4s cubic-bezier(0, 0, 0.2, 1);
        transition: visibility .4s step-end;
    }
    &:active:before {
        visibility: visible;
    }
}

`
