const getPreheaderConfig = () => {
    return true
}

export const getConfig = () => {
    const preheader = getPreheaderConfig()

    return {
        preheader: preheader,
    }
}
