import styled from 'styled-components'
import { colors } from '../ThemeDefinitions'

const { mainFontColor } = colors

export const ToggleContainer = styled.a`
    display: block;
    direction: ${(props) => props.direction};
    width: ${(props) => props.itemWidth || '100%'};
    padding: 1rem;
    cursor: pointer;
    color: ${colors.black}!important;
    text-decoration: none;
    text-transform: uppercase;

    * {
        transition: none;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        /* border-block-end: 1px dotted ${mainFontColor}; */
        display: flex;
        align-items: center;
        padding: 0;
    }
`
