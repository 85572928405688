import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../Theme'
import { TriggerButton } from '../OffCanvasMenu/OffCanvasTrigger/OffCanvasTrigger.styled'
import { CustomIcon } from '../CustomIcon'

const { brightBgColor, white } = colors
const SlideDownContent = styled.div`
    background-color: ${brightBgColor};
    display: flex;
    height: ${(props) => (props.open === true ? '1.5rem' : '0')};
    justify-content: flex-end;
    margin-top: ${(props) => (props.open === true ? '10px' : '0')};
    overflow: hidden;
    transition-property: height;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    @media (max-width: ${({ theme }) => theme.tablet}) {
        justify-content: start;
        background-color: ${white};
        margin-top: 0;
        transition:
            height 0.2s,
            opacity 0.2s;
        box-shadow: 18px 18px 45px 19px rgba(79, 94, 120, 0.1);
        height: ${(props) => (props.open === true ? 'auto' : '0')};
        > div {
            margin: 10px 0;
        }
    }
`

export const SlideDownMenu = ({
    darkMode,
    children,
    buttonText,
    iconType = 'a11y',
    menuID = '',
    direction,
}) => {
    const [open, setOpen] = useState(() => false)
    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <>
            <TriggerButton
                darkMode={darkMode}
                iconType={iconType}
                id={menuID}
                onClick={() => handleClick()}
                aria-label="accessibility-menu"
                aria-expanded={open}
                className="language-dependent-font"
            >
                <span>
                    <CustomIcon type={iconType} />
                    {buttonText}
                </span>
            </TriggerButton>
            <SlideDownContent
                aria-labelledby={menuID}
                open={open}
                direction={direction}
                elements={children.length}
            >
                {children}
            </SlideDownContent>
        </>
    )
}
