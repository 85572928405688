import React, { useState } from 'react'
import { SectionTitle } from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import { MobileHeadline } from '../../../MobileHeadline'
import { getDrupalData } from '../../../../utils'
import { useInView } from 'react-intersection-observer'
import { ContentContainer } from './RescueAppBenefits.styled'
import {
    SectionWrapper,
    ToggleContainer,
    FeatureImage,
    Toggle,
} from './RescueAppBenefits.styled'
import { useTheme } from '../../../Theme'

export const RescueAppBenefits = ({ data }) => {
    const {
        featureSlider,
        featureSliderSectionTitle,
        paginationNumber,
        paginationText,
    } = getDrupalData(data)

    const [ref, inView, entry] = useInView()

    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    const themeContext = useTheme()
    const activeFeatureStyle = {
        color: themeContext.mode === 'dark' ? '#A09E9A' : '#4D4D4B',
        borderBottom: '8px solid #87CB53',
    }
    const inactiveFeatureStyle = {
        color: themeContext.mode === 'dark' ? '#4D4D4B' : '#A09E9A',
        borderBottom: '8px solid transparent',
    }

    const [activeFeature, setActiveFeature] = useState(0)

    return (
        <SectionWrapper id="rescue-benefits">
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={featureSliderSectionTitle}
            />
            <SitePagination
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <ContentContainer ref={ref}>
                <SectionTitle dark displayMobile="none">
                    {featureSliderSectionTitle}
                </SectionTitle>
                <ToggleContainer>
                    {featureSlider.map((feature, index) => (
                        <Toggle
                            key={`feature-no-${index}`}
                            onClick={() => setActiveFeature(index)}
                            darkMode={themeContext.mode === 'dark'}
                            style={
                                index === activeFeature
                                    ? activeFeatureStyle
                                    : inactiveFeatureStyle
                            }
                        >
                            {feature.alt}
                        </Toggle>
                    ))}
                </ToggleContainer>
                <FeatureImage
                    src={
                        featureSlider[activeFeature].imageFile.childImageSharp
                            .fluid.src
                    }
                    alt={featureSlider[activeFeature].alt}
                />
            </ContentContainer>
        </SectionWrapper>
    )
}
