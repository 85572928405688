import styled from 'styled-components'
import { colors, fontSizes } from '../../../Theme'
import { aidminutesColors } from '../../../Theme/ThemeDefinitions/aidminutesStyle'

const { semiTransparentBlack, primaryColor, mainFontColor, white } = colors
const { baseFontSize, h4Size } = fontSizes

export const Description = styled.p`
    font-weight: 700;
    font-size: ${baseFontSize};
    line-height: calc(${h4Size} * 1.2);
    letter-spacing: 0.5px;
    text-transform: ${(props) => props.toUppercase && 'uppercase'};
    color: ${semiTransparentBlack};
    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-weight: 400;
        max-width: 70%;
    }
`
export const ContainersSection = styled.div`
    display: flex;
    width: 100%;
    gap: 3rem;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 2rem 0;
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        gap: 5%;
        flex-direction: row;
    }
`
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 2.83291px 2.83291px rgba(0, 0, 0, 0.14))
        drop-shadow(0px 2.83291px 3.77722px rgba(0, 0, 0, 0.12))
        drop-shadow(0px 0.944304px 7.55443px rgba(0, 0, 0, 0.2));
    background-color: ${(props) => props.bgColor || '#fff'};
    border-radius: 23px;
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        max-width: 30%;
    }
`
export const ContainerTopDecoration = styled.div`
    display: flex;
    border-radius: 25px 25px 0 0;
    height: 2rem;
    width: 100%;
    align-items: flex-start;
    background-color: ${primaryColor};
`
export const Title = styled.h4`
    font-size: ${h4Size};
    font-weight: 700;
    line-height: calc(${h4Size} * 1.4);
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
    margin: 2rem 0 1rem;
`
export const ContainerDescription = styled.p`
    font-weight: 400;
    font-size: 1rem;
    line-height: calc(${h4Size} * 1.2);
    letter-spacing: 0.5px;
    color: ${aidminutesColors.aidminutesDark};
    text-align: center;
    margin: 0 1.5rem 3rem;
`
export const FeaturesList = styled.ul`
    list-style: none;
    margin: 3rem 0.5rem 1.5rem;
`
export const Feature = styled.li`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0.5rem 0;
`
export const FeatureCheck = styled.div`
    background: url(${(props) => props.image}) no-repeat;
    height: 20px;
    width: 20px;
    margin-right: 0.5rem;
    flex-shrink: 0;
    background-position-x: center;
    background-position-y: center;
`
export const FeatureDescription = styled.p`
    font-weight: 400;
    font-size: ${baseFontSize};
    line-height: 20px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => (theme.mode === 'dark' ? white : mainFontColor)};
    margin: 0 0.3rem;
`
