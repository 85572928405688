import React from 'react'
import { useInView } from 'react-intersection-observer'
import {
    TextContainer,
    ImageContainer,
    Description,
    ImageContainerWrapper,
    SocialIcon,
    SocialMediaIconsWrapper,
} from './HediContact.styled'
import {
    Section,
    FlowContentGrid,
    SectionTitle,
    SubHeadline,
    Wysiwyg,
} from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import { MobileHeadline } from '../../../MobileHeadline'
import parentWithChild from '../../../../images/hedi_04_parent_with_child.png'
import twitterIcon from '../../../../images/hedi_04_twitter_icon.png'
import facebookIcon from '../../../../images/hedi_04_facebook_icon.png'

export const HediContact = ({ data, translations, langCode }) => {
    const [ref, inView, entry] = useInView()

    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    return (
        <Section id="hedi-feedback" style={{ marginBottom: '3rem' }}>
            <MobileHeadline
                paginationNumber={
                    data?.fieldPagination?.entity?.fieldPaginationNummer
                }
                text={data?.fieldMainHeadline?.value}
                isHedi
            />
            <SitePagination
                pageNumber={
                    data?.fieldPagination?.entity?.fieldPaginationNummer
                }
                pageText={data?.fieldPagination?.entity?.fieldPaginationText}
                textTransform="initial"
            />
            <FlowContentGrid
                columns="45% 55%"
                padding="0 4vw 0 1vw"
                mobilePadding="0"
            >
                <SubHeadline displayMobile>
                    {data?.fieldPreHeadline}
                </SubHeadline>
                <ImageContainerWrapper>
                    <ImageContainer background={`url(${parentWithChild})`} />
                </ImageContainerWrapper>
                <TextContainer className="not-in-view" ref={ref}>
                    <SectionTitle
                        textTransform="uppercase"
                        displayMobile="none"
                    >
                        {data?.fieldMainHeadline?.value}
                    </SectionTitle>
                    <SubHeadline displayDesktop margin="0 0 6rem 0">
                        {data?.fieldPreHeadline}
                    </SubHeadline>
                    <Description>
                        <Wysiwyg text={data?.fieldWysiwyg?.value}></Wysiwyg>
                    </Description>
                    <SocialMediaIconsWrapper>
                        <a
                            href="https://twitter.com/HediApp"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Twitter"
                        >
                            <SocialIcon background={`url(${twitterIcon})`} />
                        </a>
                        <a
                            href="https://www.facebook.com/HalloHedi"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Facebook"
                        >
                            <SocialIcon background={`url(${facebookIcon})`} />
                        </a>
                    </SocialMediaIconsWrapper>
                </TextContainer>
            </FlowContentGrid>
        </Section>
    )
}
