import styled from 'styled-components'
import { Paragraph, SectionBackground, SectionTitle } from '../../../basic'
import { HoneyCombWidth } from './HoneyComb/honeyComb.config'
import { colors } from '../../../Theme'

const { mainFontColor, black } = colors

export const DownloadSection = styled(SectionBackground)`
    height: auto;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        height: initial;
    }
`

export const DownloadContentContainer = styled.div`
    margin: 0 auto;
`

export const DownloadText = styled(Paragraph)`
    color: ${(props) => (props.darkMode ? 'black' : '')};
    color: ${(props) => (props.darkMode === 'white' ? 'white' : '')};

    @media (min-width: ${({ theme }) => theme.tablet}) {
        border-top: 1px solid ${black};
        display: inline-block;
        margin-inline-start: ${HoneyCombWidth / 2}px;
        margin-top: 0;
        padding-top: 1rem;
        width: 50%;
    }
`

export const DownloadTitle = styled(SectionTitle)`
    color: ${mainFontColor};
    margin-inline-start: ${HoneyCombWidth / 2}px;
`

export const DonwloadWrapGrid = styled.div`
    width: 100vw;
    margin: 5vh 0 0;
    padding: 0 10vw 0 5vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 5px;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin: 22vh 0 5vh;
        width: 90vw;
    }
`
