import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors, fontSizes } from '../../Theme'
import { brightFontColor } from '../../Theme/ThemeDefinitions/themeMode'

const { brightMenuLinkColor, darkBlueishBgColor, greyishBgColor } = colors
const { smallSize, minimalSmallerSize } = fontSizes

export const FooterRow = styled.div`
    max-width: 100%;
    padding: ${(props) => props.padding};
    background: ${(props) =>
        props.background === 'darkblue' ? darkBlueishBgColor : greyishBgColor};
    @media (max-width: ${({ theme }) => theme.tablet}) {
        padding: 2rem 1rem;
    }
`

export const FooterInner = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: ${({ theme }) => theme.mobile}) and (max-width: ${({
            theme,
        }) => theme.tablet}) {
        flex-direction: column;
        align-items: space-between;
    }
    @media (min-width: 1200px) {
        grid-template-columns: 25% auto;
    }
`

export const FooterMenuListItem = styled.li`
    list-style-type: none;
    padding-bottom: 12px;
    color: ${brightMenuLinkColor};
    font-size: ${minimalSmallerSize};
    @media (max-width: ${({ theme }) => theme.tablet}) {
        padding-bottom: 0;
        padding: 15px 0 0;
    }

    svg {
        margin-inline-end: 1rem;
    }
`
export const FooterMenuList = styled.ul`
    margin-top: 25px;
    text-transform: uppercase;
    padding-left: 0;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-top: 20px;
        margin-bottom: inherit;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 0 0 55px;
    }
`

export const FooterMenuListHeadline = styled.span`
    color: ${(props) => (props.darkMode ? brightFontColor : 'white')};
    font-family: 'Montserat', sans-serif;
    font-size: ${smallSize};
    text-transform: uppercase;
`

export const FooterLink = styled(Link)`
    color: ${brightMenuLinkColor};
    font-weight: 300;
    text-decoration: none;
`
export const FooterExternalLink = styled.a`
    color: ${brightMenuLinkColor};
    font-weight: 300;
    text-decoration: none;
`

export const FooterShortcutsContainer = styled.div``
export const FooterProductsContainer = styled.div``
export const FooterLegalContainer = styled.div``
export const FooterSocialContainer = styled.div``

export const FooterMenuListContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        display: flex;
        > div:not(:last-child) {
            margin-right: 6rem;
        }
    }
    @media (min-width: ${({ theme }) => theme.tablet}) and (max-width: 950px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-row-gap: 3rem;
    }
    ${FooterShortcutsContainer} {
        display: none;
        @media (min-width: ${({ theme }) => theme.tablet}) {
            display: block;
        }
    }
    ${FooterProductsContainer} {
        display: none;
        @media (min-width: ${({ theme }) => theme.tablet}) {
            display: block;
        }
    }
`
