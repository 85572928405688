import React, { useRef } from 'react'
import {
    StartContainer,
    StartHeading,
    StartSection,
    HeroSVG,
    Wrap,
} from './Start.styled'
import { ScrollDown } from '../../../Animations'
import { SmallSubHeadlineParagraph } from '../../../basic'
import { getDrupalData } from '../../../../utils'
import Svg from '../../../../svg/aidminutes_home_animation3.inline.svg'
import { useInView } from 'react-intersection-observer'

export const Start = ({ data, direction }) => {
    const ref = useRef()

    const { mainHeadline, subText, svgentities } = getDrupalData(data)

    const changeSVGtext = () => {
        if (typeof svgentities !== 'undefined' && svgentities !== '') {
            ref.current.firstElementChild.children.practice.innerHTML =
                svgentities.practice
            ref.current.firstElementChild.children.home.innerHTML =
                svgentities.home
            ref.current.firstElementChild.children.pharmacy.innerHTML =
                svgentities.pharmacy
            ref.current.firstElementChild.children.insurance.innerHTML =
                svgentities.insurance
            ref.current.firstElementChild.children.rescuestadion.innerHTML =
                svgentities.rescuestation
            ref.current.firstElementChild.children.emergencydepartment.innerHTML =
                svgentities.emergencyroom
        }
    }

    const [inViewRef, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }
    return (
        <StartSection id="start">
            <Wrap ref={inViewRef} className="not-in-view">
                <StartContainer>
                    <StartHeading>{mainHeadline}</StartHeading>

                    <SmallSubHeadlineParagraph
                        color="white"
                        textTransform="uppercase"
                        marginTop="1em"
                    >
                        {subText}
                    </SmallSubHeadlineParagraph>
                </StartContainer>
                <HeroSVG ref={ref} direction={direction}>
                    <Svg onLoad={changeSVGtext} />
                </HeroSVG>
                <ScrollDown />
            </Wrap>
        </StartSection>
    )
}
