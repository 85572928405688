import React from 'react'
import styled from 'styled-components'
import { H3, Paragraph } from '../../../../basic'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { fontSizes } from '../../../../Theme'

const { xSmallSize, baseFontSize, techSize } = fontSizes

const HoneyCombContentContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    width: 115px;
    * {
        margin: 0;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
        width: 60%;
    }
`

const HoneyCombContentLink = styled.a`
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    text-decoration: none;
    width: 100%;
    max-width: 210px;
`

const HoneyCombContentHeadline = styled(H3)`
    color: ${({ theme }) => theme.white};
    font-size: ${xSmallSize};
    letter-spacing: 1px;
    line-height: 1.8rem;
    margin: 0;
    text-transform: uppercase;
    word-break: break-word;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: ${baseFontSize};
        letter-spacing: 2.5px;
    }
`

const HoneyCombContentInfo = styled(Paragraph)`
    color: ${({ theme }) => theme.white};
    font-size: ${techSize};
    margin: 0;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: inherit;
    }
`

const CloudIcon = styled(AiOutlineCloudDownload)`
    color: ${({ theme }) => theme.white};
    height: 40px;
    width: 40px;
`

export const HoneyCombImage = styled.img`
    max-width: 150px;
    position: absolute;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        max-width: 80%;
    }
`

export const HoneyCombContent = ({ content }) => {
    let title = (content && content.title) || ''
    let info = (content && content.fieldAdditionalInfo) || ''
    let link =
        (content &&
            content.fieldDownloadFile[0] &&
            content.fieldDownloadFile[0].downloadFile &&
            content.fieldDownloadFile[0].downloadFile.publicURL) ||
        ''

    return (
        <HoneyCombContentLink href={link} target="_blank">
            <HoneyCombContentContainer>
                <CloudIcon />
                <HoneyCombContentHeadline>{title}</HoneyCombContentHeadline>
                <HoneyCombContentInfo>{info}</HoneyCombContentInfo>
            </HoneyCombContentContainer>
        </HoneyCombContentLink>
    )
}
