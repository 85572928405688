export const aidminutesColors = {
    aidminutesGrannygreen: '#87CB53',
    aidminutesSatinpearl: '#f5f2eb',
    aidminutesLightest: '#F9F7F4',
    aidminutesLighter: '#F3EFE9',
    aidminutesLight: '#C9C7C1',
    aidminutesNormal: '#A09E9A',
    aidminutesDark: '#767672',
    aidminutesGrey: '#686865',
    aidminutesDarker: '#4D4D4B',
    aidminutesDarkest: '#232523',
    aidminutesDarkestGrey: '#1B1B1B',
    aidminutesRedLighter: '#ff8e90',
    aidminutesBlue: '#7ba1ce',
    aidminutesCoralRed: '#FE6669',
    aidminutesRescueGreen: '#92B976',
    hediBlue: '#4F81BD',
    mailToLinkBlue: '#3F6797',
    aidminutesMapGrey: '#E9EEF3',
}

export const aidminuteWebColors = {
    aidminutesWebRed: '#C0504D',
    aidminutesWebDarkGreyBlue: '#3C444C',
}
