export { getDrupalData } from './drupaldata'
export { softwareDownloadData } from './softwareDownloads'
export { moreinfoData } from './moreinfo'
export { downloadButtonData } from './downloadButton'
export { getTranslations } from './translations'
export {
    useFooterData,
    getPageData,
    getTopmenuData,
    getSubmenuData,
} from './pageData'
