import React from 'react'
import {
    RoundedButtonWrapper,
    RoundedButtonContainer,
    Icon,
    ButtonText,
} from './RoundedButtonAnamnesis.styled'
import WhiteCheckIcon from '../../images/icon_white_check.svg'
import WhiteMailIcon from '../../images/icon_mail_closed_white.svg'

//TODO refactor + open ticket => merge two components in one?

export const RoundedButtonAnamnesisExternalLink = () => {
    return (
        <RoundedButtonWrapper>
            <RoundedButtonContainer href="https://my.aidminutes.com/buy/anamnesis">
                <Icon src={WhiteCheckIcon} />
                <ButtonText>Lizenz erwerben</ButtonText>
            </RoundedButtonContainer>
        </RoundedButtonWrapper>
    )
}
export const RoundedButtonAnamnesisMailToLink = () => {
    return (
        <RoundedButtonWrapper>
            <RoundedButtonContainer
                mailTo
                href="mailto:business.anamnesis@aidminutes.com"
            >
                <Icon src={WhiteMailIcon} />
                <ButtonText>kontakt aufnehmen</ButtonText>
            </RoundedButtonContainer>
        </RoundedButtonWrapper>
    )
}
