import React from 'react'
import { Link } from 'gatsby'
import { Layout, Seo } from '../../components'
import { Section, SectionTitleH1 } from '../../components/basic'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { colors } from '../../components/Theme'
import seo_home_de_fb from './../../images/seo_home_de_fb.png'
import seo_home_en_fb from './../../images/seo_home_en_fb.png'

import UK from '../../images/english-duo-404.svg'
import DE from '../../images/germany-404.png'

import styled from 'styled-components'

const { mainFontColor, white } = colors

const ErrorSection = styled(Section)`
    background: rgb(233, 238, 242);
    background: -moz-linear-gradient(
        90deg,
        rgba(233, 238, 242, 1) 0%,
        rgba(222, 230, 235, 1) 50%,
        rgba(240, 243, 244, 1) 50%,
        rgba(235, 239, 243, 1) 100%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(233, 238, 242, 1) 0%,
        rgba(222, 230, 235, 1) 50%,
        rgba(240, 243, 244, 1) 50%,
        rgba(235, 239, 243, 1) 100%
    );
    background: linear-gradient(
        90deg,
        rgba(233, 238, 242, 1) 0%,
        rgba(222, 230, 235, 1) 50%,
        rgba(240, 243, 244, 1) 50%,
        rgba(235, 239, 243, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e9eef2",endColorstr="#ebeff3",GradientType=1);

    display: flex;
    align-items: center;
    justify-content: center;
`

const ErrorContentWrap = styled.div`
    background: linear-gradient(
        180deg,
        #909498 0%,
        rgba(144, 148, 152, 0) 132.33%
    );
    border-radius: 46px;
    padding: 50px;
    text-align: center;
    @media (max-width: ${({ theme }) => theme.tablet}) {
        margin: 40px auto;
        padding: 10vw;
    }
`

const ErrorHeadline = styled(SectionTitleH1)`
    color: ${white};
    font-weight: 300;
    margin-top: 3rem;
`

const FLAGBASE = 1.5

const ErrorFlag = styled.img`
    height: ${FLAGBASE * 9}px;
    width: ${FLAGBASE * 16}px;
`
const ErrorParagraph = styled.p`
    color: ${mainFontColor};
    margin-top: 0.5em;
`

const ErrorText = ({ text, flag }) => {
    return (
        <div>
            <ErrorFlag src={flag} alt="" />
            <ErrorParagraph>{text}</ErrorParagraph>
        </div>
    )
}

const ErrorBackToStartLink = styled(Link)`
    align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
    svg {
        font-size: 1.4em;
    }
`
const ErrorTextWrap = styled.div`
    margin: 5rem 0 3rem;
`
export const Error404Page = ({ location, langCode }) => {
    const seoTitle =
        langCode === 'DE'
            ? 'Error 404: Seite nicht gefunden'
            : 'Error 404: Page not Found'
    const seoDescription =
        langCode === 'DE'
            ? 'Seite konnte nicht gefunden werden'
            : 'Page not Found'
    const seoImageUrl =
        langCode === 'DE' ? `${seo_home_de_fb}` : `${seo_home_en_fb}`
    const seoImageAlt = seoTitle
    const seoType = 'website'
    const seoUri = location.pathname
    return (
        <Layout
            path={location.pathname}
            location={location}
            langCode={langCode}
        >
            <Seo
                lang={langCode}
                title={seoTitle}
                type={seoType}
                url={seoUri}
                image={seoImageUrl}
                alt={seoImageAlt}
                description={seoDescription}
                //twitter:card properties directly imported
            />
            <ErrorSection>
                <ErrorContentWrap>
                    <ErrorHeadline textTransform="uppercase">
                        <strong>Error</strong>
                        <br />
                        404
                    </ErrorHeadline>
                    <ErrorTextWrap>
                        <ErrorText text="Page not found" flag={UK} />
                        <ErrorText
                            text="Seite konnte nicht gefunden werden"
                            flag={DE}
                        />
                    </ErrorTextWrap>
                    {/* TODO: multilanguage */}
                    <ErrorBackToStartLink to="/">
                        <IoIosArrowRoundBack />
                        zurück zur Startseite
                    </ErrorBackToStartLink>
                </ErrorContentWrap>
            </ErrorSection>
        </Layout>
    )
}
