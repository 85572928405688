export const aidminutesBasicFontSizes = {
    basicFontSize: '16px',
    minimalSmaller: '0.9rem',
    minimalBigger: '1.2rem',
    h1FontSize: '3rem', //48px
    h2FontSize: '2.625rem', //42px
    h3FontSize: '2.25rem', //36px
    h4FontSize: '1.5rem', // 28px
    h5FontSize: '1.25rem', // 24px
    smallFontSize: '0.7813rem', // 12.5px
    xsmallFontSize: '0.6875rem', // 14px
    technicalFontSize: '0.75rem', //12px
    subHeadlineSmallFontSize: '1.5625rem',
}
