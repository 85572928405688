import React from 'react'
import {
    SectionTitle,
    ContentGrid,
    Wysiwyg,
    PageImage,
    SectionBackground,
    SectionH3Title,
} from '../../../basic'
import { SitePagination } from '../../../SitePagination'
import {
    AppsGridContainer,
    AppsTitleContainer,
    RightColumnContentContainer,
    AppsGridContainerRight,
    RescueAppImage,
} from './Apps.styled'
import { ContactFlag } from '../../../ContactFlag'
import { MobileHeadline } from '../../../MobileHeadline'
import { getDrupalData } from '../../../../utils'
import { useInView } from 'react-intersection-observer'
import { useTheme } from '../../../Theme'
import { TextBanner } from '../../../TextBanner'

export const Apps = ({ data, translations }) => {
    const {
        mainHeadline,
        bgImage,
        paginationNumber,
        paginationText,
        textBannerImage,
        textBannerText,
    } = getDrupalData(data)

    // TODO Conditional Chaining
    const headlineAnamnesis = (data && data.fieldHeadlineAnamnesis) || ''
    const headlineRescue = (data && data.fieldHeadlineRescue) || ''
    const textAnamnesis =
        (data && data.fieldTextAnamnesis && data.fieldTextAnamnesis.value) || ''
    const textRescue =
        (data && data.fieldTextRescue && data.fieldTextRescue.value) || ''
    const imageAnamnesis =
        (data &&
            data.fieldImageAnamnesis &&
            data.fieldImageAnamnesis.imageFile &&
            data.fieldImageAnamnesis.imageFile.publicURL) ||
        ''
    const imageRescue =
        (data &&
            data.fieldImageRescue &&
            data.fieldImageRescue.imageFile &&
            data.fieldImageRescue.imageFile.publicURL) ||
        ''
    const imageRightCorner =
        (data &&
            data.fieldImageRightCorner &&
            data.fieldImageRightCorner.imageFile &&
            data.fieldImageRightCorner.imageFile.publicURL) ||
        ''

    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }
    const themeContext = useTheme()
    return (
        <SectionBackground
            background={`url(${bgImage}) no-repeat`}
            backgroundSize="cover"
        >
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={mainHeadline}
            />
            <SitePagination
                darkMode={themeContext.mode === 'dark'}
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <ContentGrid
                columns="repeat(2, 1fr)"
                gap="5vw"
                margin="22vh 0 15vh"
                ref={ref}
                className="not-in-view"
            >
                <TextBanner
                    text={textBannerText}
                    image={textBannerImage}
                    dark
                    darkOnMobileView
                />
                <AppsTitleContainer>
                    <PageImage
                        float="right"
                        src={imageRightCorner}
                        width="200px"
                        alt=""
                    />
                    <SectionTitle
                        darkMode={themeContext.mode === 'dark'}
                        marginBottom="1em"
                        displayMobile="none"
                    >
                        <Wysiwyg text={mainHeadline} />
                    </SectionTitle>
                </AppsTitleContainer>
                <AppsGridContainer>
                    <RescueAppImage src={imageRescue} alt="rescue.app" />
                    <SectionH3Title
                        dark
                        darkMode={themeContext.mode === 'dark'}
                    >
                        {headlineRescue}
                    </SectionH3Title>
                    <Wysiwyg
                        darkMode={themeContext.mode === 'dark'}
                        text={textRescue}
                    />
                </AppsGridContainer>
                <AppsGridContainerRight>
                    <RightColumnContentContainer>
                        <SectionH3Title
                            marginTop="0"
                            dark
                            darkMode={themeContext.mode === 'dark'}
                        >
                            {headlineAnamnesis}
                        </SectionH3Title>

                        <Wysiwyg
                            darkMode={themeContext.mode === 'dark'}
                            text={textAnamnesis}
                        />
                    </RightColumnContentContainer>

                    <PageImage src={imageAnamnesis} alt="anamnesis.app" />
                </AppsGridContainerRight>
            </ContentGrid>
            <ContactFlag
                darkMode={themeContext.mode === 'dark'}
                translations={translations}
            />
        </SectionBackground>
    )
}
