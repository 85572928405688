import styled from 'styled-components'
import { colors } from '../Theme'
import { Link } from 'gatsby'

const { mainFontColor } = colors

export const LanguageLink = styled(Link)`
    color: ${mainFontColor}!important;
    direction: ${(props) => props.direction};
    display: block;
    padding: 0.7rem;
    text-decoration: none;
    text-transform: uppercase;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        border-block-end: 1px dotted ${mainFontColor};
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
        flex-basis: calc(100% / 3);
        text-align: center;
    }
`
// export const LanguageLink = styled.a`
//     color: ${mainFontColor}!important;
//     direction: ${props => props.direction};
//     display: block;
//     padding: 0.7rem;
//     text-decoration: none;
//     text-transform: uppercase;
//     @media (min-width: ${({ theme }) => theme.tablet}) {
//         border-block-end: 1px dotted ${mainFontColor};
//     }
//     @media (max-width: ${({ theme }) => theme.tablet}) {
//         flex-basis: calc(100% / 3);
//         text-align: center;
//     }
// `
