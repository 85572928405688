import React from 'react'
import {
    ButtonText,
    AlternativeDownloadButtonNew,
} from './AltrnativeApkDownload.styled'
export const AlternativeApkLink = ({ setVisible, systemName }) => {
    const handleClick = () => setVisible(true)
    return (
        <AlternativeDownloadButtonNew onClick={() => handleClick()}>
            <ButtonText>{systemName}</ButtonText> (apk)
        </AlternativeDownloadButtonNew>
    )
}
