import styled from 'styled-components'

export const RescueAppInfoGridContainer = styled.div`
    @media (min-width: ${({ theme }) => theme.tablet}) {
        grid-column: ${(props) => props.span || 'auto'};

        p {
            max-width: 600px;
        }
    }
`

export const RescueAppGridRight = styled(RescueAppInfoGridContainer)`
    svg {
        height: 100%;
        width: 100%;
    }

    flex-direction: column;
    height: fit-content;

    @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: flex-start;
        display: flex;
        padding-left: 2rem;
        height: auto;
    }
`
