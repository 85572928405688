import React from 'react'
import { getDrupalData } from '../../../../utils'
import {
    Wysiwyg,
    ContentGrid,
    SectionTitle,
    MobileHeadline,
    // DownloadButton,
    SitePagination,
    ContentImageDesktopOnly,
    ContentImageMobileOnly,
    SectionBackground,
    SocialIcons,
    BigLink,
} from '../../../index'
import { ImageContainer } from './VaccinateAdditionalInfo.styled'

export const VaccinateAdditionalInfos = ({ data }) => {
    const {
        mainHeadline,
        text,
        fluidImage,
        paginationNumber,
        paginationText,
        addText,
        link,
        altText,
        alternativeMobileImage,
    } = getDrupalData(data)
    // const buttondata = downloadButtonData(data.fieldDownloadButton.entity)
    return (
        <SectionBackground
            background="linear-gradient(106.22deg, rgba(255, 255, 255, 0.12) -6.8%, rgba(223, 229, 237, 0.15) -1.75%, rgba(86, 104, 126, 0.15) 18.01%, rgba(73, 89, 109, 0.09) 66.85%, rgba(160, 192, 235, 0.03) 90.79%, rgba(64, 91, 126, 0.06) 100%)"
            id="vaccinate-latest"
        >
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={paginationText}
                primary
            />
            <SitePagination
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <ContentGrid columns="45% 45%" gap="10%" margin="22vh 0 15vh">
                <ContentImageMobileOnly
                    image={alternativeMobileImage}
                    alt={altText}
                />
                <ImageContainer>
                    <ContentImageDesktopOnly fluid={fluidImage} alt={altText} />
                </ImageContainer>
                <div>
                    <SectionTitle textTransform="uppercase" marginBottom="6rem">
                        {mainHeadline}
                    </SectionTitle>
                    <Wysiwyg text={text} />
                    <BigLink url={link.uri}>{link.title}</BigLink>
                    {/* <DownloadButton data={buttondata} /> */}
                    <Wysiwyg text={addText} />
                    <SocialIcons iconColor="#7ba1ce" opacity="0.5" />
                </div>
            </ContentGrid>
        </SectionBackground>
    )
}
