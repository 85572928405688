import React from 'react'
import {
    SubmenuContainer,
    SubmenuLink,
    SubmenuItem,
    SubmenuText,
    SubmenuHeadline,
    SubmenuDescription,
    SubmenuIconContainer,
    SubmenuIcon,
} from './Submenu.styled'
import { Wysiwyg } from '../../basic'

export const Submenu = ({ visible, collapse, submenu, prefix, darkMode }) => {
    return (
        <SubmenuContainer visible={visible} collapse={collapse ? 1 : 0}>
            {submenu.map((submenu, index) => {
                // TODO CC
                let link = submenu?.entity?.fieldPath ?? ''
                let headline = submenu?.entity?.fieldMainHeadline?.value ?? ''
                let text = submenu?.entity?.fieldAdditionalHeadline?.value ?? ''
                let src =
                    submenu?.entity?.fieldIcon?.imageFile?.childImageSharp
                        ?.fluid?.src ?? ''
                let alt = submenu?.entity?.fieldIcon?.alt ?? ''

                return (
                    <SubmenuLink to={`${prefix || ''}${link}`} key={index}>
                        <SubmenuItem>
                            <SubmenuText>
                                <SubmenuHeadline>
                                    <Wysiwyg
                                        darkMode={darkMode ? 'white' : ''}
                                        text={headline}
                                    />
                                </SubmenuHeadline>
                                <SubmenuDescription>
                                    <Wysiwyg
                                        darkMode={darkMode ? 'white' : ''}
                                        text={text}
                                    />
                                </SubmenuDescription>
                            </SubmenuText>
                            <SubmenuIconContainer>
                                <SubmenuIcon src={src} alt={alt} />
                            </SubmenuIconContainer>
                        </SubmenuItem>
                    </SubmenuLink>
                )
            })}
        </SubmenuContainer>
    )
}
