import React from 'react'
import { SitePagination } from '../../../SitePagination'
import {
    Section,
    ContentGrid,
    Wysiwyg,
    BigSubHeadlineParagraph,
} from '../../../basic'
import { WhoWeAreImage, WhoWeAreSectionTitle } from './WhoWeAre.styled'
import { ContactFlag } from '../../../ContactFlag'
import { MobileHeadline } from '../../../MobileHeadline'
import { getDrupalData } from '../../../../utils'
import { useInView } from 'react-intersection-observer'

export const WhoWeAre = ({ data, translations }) => {
    const {
        subHeadline,
        text,
        fluidImage,
        paginationText,
        paginationNumber,
        mainHeadline,
        altText,
    } = getDrupalData(data)

    const [ref, inView, entry] = useInView({
        /* Optional options */
        // threshold: 0.95,
    })
    if (inView) {
        entry.target.classList.remove('not-in-view')
        entry.target.classList.add('fadeInUp')
    }

    const { src, srcSet, sizes } = fluidImage

    return (
        <Section>
            <MobileHeadline
                paginationNumber={paginationNumber}
                text={mainHeadline}
            />
            <SitePagination
                pageNumber={paginationNumber}
                pageText={paginationText}
            />
            <ContentGrid
                ref={ref}
                columns="70% 22%"
                gap="8%"
                margin="22vh 0 15vh"
                className="not-in-view"
            >
                <WhoWeAreSectionTitle displayMobile="none">
                    {mainHeadline}
                </WhoWeAreSectionTitle>
                <BigSubHeadlineParagraph
                    marginBottom="1rem"
                    textTransform="uppercase"
                    marginTop="0"
                >
                    {subHeadline}
                </BigSubHeadlineParagraph>

                <picture>
                    <source srcSet={srcSet} sizes={sizes}></source>
                    <WhoWeAreImage
                        src={src}
                        srcSet={srcSet}
                        sizes={sizes}
                        alt={altText}
                    />
                </picture>

                {/* <WhoWeAreImage src={fluidImage?.base64} alt={altText} /> */}
                <Wysiwyg text={text} />
            </ContentGrid>
            <ContactFlag translations={translations} />
        </Section>
    )
}
