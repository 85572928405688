import React from 'react'
import { graphql } from 'gatsby'

import { RescueCovid19 as RescueCovid19Template } from '../pageTemplates'

const RescueCovid19 = ({ location, pageContext, data }) => {
    return (
        <>
            <RescueCovid19Template
                location={location}
                langCode={pageContext.language}
                data={data}
                pageContext={pageContext}
            />
        </>
    )
}

export const query = graphql`
    query rescuecovid19query(
        $language: Drupal_LanguageId!
        $langCode: String!
    ) {
        drupal {
            covidDownloadsQuery: nodeQuery(
                filter: {
                    conditions: {
                        field: "type"
                        value: "rescue_covid19_downloads"
                    }
                }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeRescueCovid19Downloads {
                        fieldAdditionalHeadline {
                            value
                        }
                        fieldAdditionalText {
                            value
                        }
                        fieldMainHeadline {
                            value
                        }
                        fieldBottomText {
                            value
                        }
                        fieldPreHeadline
                        fieldWysiwyg {
                            value
                        }
                        fieldDownloadButton {
                            entity {
                                ... on Drupal_NodeDownloadButton {
                                    fieldBtnSrc
                                    fieldBtnDescription
                                    fieldBtnBgColor
                                    fieldBtnIconColor
                                    fieldBtnType
                                    fieldBtnTitle
                                    fieldDownloadFile {
                                        description
                                        entity {
                                            url
                                            filemime
                                        }
                                        downloadFile {
                                            publicURL
                                        }
                                    }
                                }
                            }
                        }
                        fieldPagination {
                            entity {
                                ... on Drupal_NodePagination {
                                    fieldPaginationNummer
                                    fieldPaginationText
                                }
                            }
                        }
                        fieldMore {
                            entity {
                                ... on Drupal_NodeMoreInfos {
                                    fieldText
                                    fieldPath
                                }
                            }
                        }
                        fieldSoftwareDownloads {
                            entity {
                                ... on Drupal_NodeSoftwareDownload {
                                    fieldButtonSubText {
                                        value
                                    }
                                    fieldDownloadButtonText
                                    fieldDownloadLink
                                    fieldModalTriggerButtonText
                                    fieldCounterUrl
                                    fieldPreButtonText {
                                        value
                                    }
                                    fieldTextCancellationPolicy {
                                        value
                                    }
                                    fieldTextEula {
                                        value
                                    }
                                }
                            }
                        }
                        fieldStoreLink {
                            entity {
                                ... on Drupal_NodePlayAppStoreLink {
                                    fieldLogo {
                                        alt
                                        url
                                        imageFile {
                                            extension
                                            publicURL
                                        }
                                    }
                                    fieldAdditionalInfo
                                    fieldPath
                                    fieldPreButtonText {
                                        value
                                    }
                                    fieldApk
                                    fieldSystemname
                                }
                            }
                        }
                    }
                }
            }
            covidStartQuery: nodeQuery(
                filter: {
                    conditions: { field: "type", value: "rescue_covid19_start" }
                }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeRescueCovid19Start {
                        fieldSubHeadline
                        fieldPreHeadline
                        fieldPagination {
                            entity {
                                ... on Drupal_NodePagination {
                                    fieldPaginationNummer
                                    fieldPaginationText
                                }
                            }
                        }
                        fieldMainHeadline {
                            value
                        }
                        fieldVimeoVideoId
                    }
                }
            }
            covidHelpingHandsQuery: nodeQuery(
                filter: {
                    conditions: {
                        field: "type"
                        value: "rescue_covid19_helping_hands"
                    }
                }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeRescueCovid19HelpingHands {
                        fieldAdditionalInfo
                        fieldAdditionalText {
                            value
                        }
                        fieldWysiwyg {
                            value
                        }
                        fieldBottomText {
                            value
                        }
                        fieldPagination {
                            entity {
                                ... on Drupal_NodePagination {
                                    fieldPaginationNummer
                                    fieldPaginationText
                                }
                            }
                        }
                        fieldQuoteEntity {
                            entity {
                                ... on Drupal_NodeQuote {
                                    uuid
                                    fieldCite
                                    fieldSrc
                                    fieldQuote {
                                        value
                                    }
                                }
                            }
                        }
                        title
                    }
                }
            }
            productMenuQuery: nodeQuery(
                filter: {
                    conditions: { field: "type", value: "__menu" }
                    conjunction: AND
                    groups: {
                        conditions: {
                            field: "title"
                            value: "aidminutes.rescue (COVID-19)"
                        }
                    }
                }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeMenu {
                        title
                        fieldPath
                        fieldAdditionalHeadline {
                            value
                        }
                        fieldMainHeadline {
                            value
                        }
                        fieldMenuitem {
                            entity {
                                ... on Drupal_NodeMenuitem {
                                    fieldPath
                                    fieldMainHeadline {
                                        value
                                    }
                                    fieldWysiwyg {
                                        value
                                    }
                                    title
                                }
                            }
                        }
                    }
                }
            }
            topMenuQuery: nodeQuery(
                filter: {
                    conditions: { field: "type", value: "__menu" }
                    conjunction: AND
                    groups: {
                        conditions: {
                            field: "title"
                            value: "Topmenu COVID-19"
                        }
                    }
                }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeMenu {
                        title
                        fieldAdditionalHeadline {
                            value
                        }
                        fieldMainHeadline {
                            value
                        }
                        fieldMenuitem {
                            entity {
                                ... on Drupal_NodeMenuitem {
                                    fieldPath
                                    fieldMainHeadline {
                                        value
                                    }
                                    fieldIsExternalLink
                                    fieldWysiwyg {
                                        value
                                    }
                                    title
                                }
                            }
                        }
                    }
                }
            }
            submenuQuery: nodeQuery(
                filter: { conditions: { field: "type", value: "product_menu" } }
            ) {
                entities(language: $language) {
                    ... on Drupal_NodeProductMenu {
                        fieldMenues {
                            entity {
                                ... on Drupal_NodeMenu {
                                    fieldPath
                                    fieldMainHeadline {
                                        value
                                    }
                                    fieldAdditionalHeadline {
                                        value
                                    }
                                    title
                                    fieldIcon {
                                        url
                                        alt
                                        imageFile {
                                            childImageSharp {
                                                fluid(
                                                    maxWidth: 64
                                                    quality: 80
                                                ) {
                                                    ...GatsbyImageSharpFluid
                                                    src
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            footerQuery: nodeQuery(
                filter: {
                    conditions: { field: "type", value: "footer_covid_19" }
                }
            ) {
                count
                entities(language: $language) {
                    ... on Drupal_NodeFooterCovid19 {
                        title
                        fieldTextRescue {
                            value
                        }
                        fieldMenues {
                            entity {
                                ... on Drupal_NodeMenu {
                                    title
                                    fieldAdditionalHeadline {
                                        value
                                    }
                                    fieldMainHeadline {
                                        value
                                    }
                                    fieldMenuitem {
                                        entity {
                                            ... on Drupal_NodeMenuitem {
                                                title
                                                fieldPath
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        fieldId
                    }
                }
            }
        }
        allTranslationsJson(filter: { langCode: { eq: $langCode } }) {
            edges {
                node {
                    close
                    contact
                    language
                    langCode
                    products
                    social
                    accessibility
                    androidLegal
                    alternativeDownload
                }
            }
        }
    }
`

export default RescueCovid19
