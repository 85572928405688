import { keyframes } from 'styled-components'

export const keyFrameFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const keyFrameTranslate = keyframes`
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }

`
export const keyFramesRipple = keyframes`
  0% {
    width: 0;
    height: 0;
    opacity: .5;
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
`
