import React from 'react'
import {
    MobileHeaderContainer,
    MobileHeaderNumber,
} from './MobileHeadline.styled'
import { Wysiwyg } from '../basic'
import { useTheme, colors } from '../Theme'

export const MobileHeadline = ({
    paginationNumber,
    text,
    h1,
    primary,
    isHedi,
    textToUppercase,
}) => {
    const themeContext = useTheme()

    let headline = (
        <h2>
            <Wysiwyg
                darkMode={
                    themeContext.mode === 'dark' && !primary && !isHedi
                        ? colors.brightFontColor
                        : colors.white
                }
                text={text}
            />
        </h2>
    )

    if (h1) {
        headline = (
            <h1>
                <Wysiwyg
                    darkMode={
                        themeContext.mode === 'dark'
                            ? colors.brightFontColor
                            : ''
                    }
                    text={text}
                />
            </h1>
        )
    }

    return (
        <MobileHeaderContainer
            primary={primary}
            isHedi={isHedi}
            darkMode={themeContext.mode === 'dark'}
            className="language-dependent-font"
            textToUppercase={textToUppercase}
        >
            {headline}
            <MobileHeaderNumber
                primary={primary}
                isHedi={isHedi}
                darkMode={themeContext.mode === 'dark'}
            >
                {paginationNumber}
            </MobileHeaderNumber>
        </MobileHeaderContainer>
    )
}
