import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
import Logo from '../../images/aidminutes_logo_active_grey.svg'
import LogoWhite from '../../images/aidminutes_logo_white.svg'
import {
    Submenu,
    SubmenuTrigger,
    SubmenuTriggerContainer,
    SubmenuTriggerIcon,
} from './Submenu'
import {
    StickyHeader,
    NavInner,
    Nav,
    NavLink,
    NavMenuWrap,
    ExternalNavLink,
} from './Menu.styled'
import { Burger } from './Burger'
import { useOnClickOutside, useOnClickOutsideSubmenuTrigger } from '../../hooks'
import { ProductMenu, ProductMenuMobile } from './ProductMenu'
import { MobileMenuHeader } from './MobileMenuHeader'
import { getTranslations } from '../../utils'
import { LanguageSwitchMenu } from '../LanguageSwitchMenu'
import { AccessibilityMenu } from '../AccessibilityMenu'
import { useTheme } from '../Theme'
import { Breakpoint } from 'react-socks'

export const Menu = ({
    pathname,
    productmenuData,
    translations,
    pageContext,
    topmenu,
    submenu,
}) => {
    const themeContext = useTheme()

    // For Hamburger Menu
    const [open, setOpen] = useState(false)
    //
    const [visible, setVisible] = useState(false)
    const [collapse, setCollapse] = useState(false)
    const [triggerClicked, setTriggerClicked] = useState(false)

    const node = useRef()
    let hasProductmenu = productmenuData === undefined ? false : true

    useOnClickOutside(node, () => setOpen(false))
    useOnClickOutside(node, () => setVisible(false))
    useOnClickOutsideSubmenuTrigger(
        node,
        () => setTriggerClicked(false),
        triggerClicked
    )

    const handleMouseLeave = () => {
        if (!triggerClicked) {
            setVisible(false)
        }
    }

    const handleClick = () => {
        if (visible && !triggerClicked) {
            setVisible(visible)
        } else {
            setVisible(!visible)
        }
        setCollapse(!collapse)
    }

    const handleMenuItemClick = () => {
        if (open) {
            setOpen(false)
        }
    }
    const productmenu = hasProductmenu ? (
        <ProductMenu
            darkMode={themeContext.mode === 'dark'}
            productmenuData={productmenuData}
            pathname={pathname}
        />
    ) : (
        ''
    )

    const productmenuMobile = hasProductmenu ? (
        <ProductMenuMobile
            darkMode={themeContext.mode === 'dark'}
            productmenuData={productmenuData}
            pathname={pathname}
            handleMenuItemClick={handleMenuItemClick}
        />
    ) : (
        ''
    )

    const { products, langCode } = getTranslations(translations)
    const prefix = pageContext?.prefix ?? ''
    const pageId = pageContext?.id ?? ''
    // TODO improve
    const logoLink = langCode === 'de' ? '/' : '/en/'

    return (
        <StickyHeader ref={node}>
            <NavInner>
                {/* TODO: Logo aus Menu in Header */}
                <div role="img" aria-label="aidminutes Logo">
                    <Link
                        style={{ display: 'flex' }}
                        to={`${logoLink}`}
                        title="Link zur Startseite"
                    >
                        <img
                            src={
                                themeContext.mode === 'dark' ? LogoWhite : Logo
                            }
                            width="150"
                            alt="aidminutes Logo"
                        />
                    </Link>
                </div>
                <Burger open={open} setOpen={setOpen} />
                <NavMenuWrap
                    darkMode={themeContext.mode === 'dark'}
                    open={open}
                >
                    {productmenuMobile}
                    <MobileMenuHeader />
                    <Nav role="navigation" aria-label="main">
                        {pageId !== 'rescue-covid19' ? (
                            <SubmenuTriggerContainer>
                                <SubmenuTrigger
                                    darkMode={themeContext.mode === 'dark'}
                                    onClick={() => {
                                        handleClick()
                                        setTriggerClicked(!triggerClicked)
                                    }}
                                    onKeyDown={() => setVisible(true)}
                                    onMouseEnter={() => setVisible(true)}
                                    onMouseLeave={() => handleMouseLeave()}
                                    data-toggle="dropdown"
                                    tabIndex="0"
                                >
                                    {products}
                                    <SubmenuTriggerIcon
                                        collapse={collapse ? 1 : 0}
                                    />
                                </SubmenuTrigger>
                                <Submenu
                                    darkMode={themeContext.mode === 'dark'}
                                    visible={visible}
                                    collapse={collapse ? 1 : 0}
                                    submenu={submenu}
                                    prefix={prefix}
                                />
                            </SubmenuTriggerContainer>
                        ) : (
                            ''
                        )}
                        {topmenu.map((menuitem) => {
                            return menuitem.entity.fieldIsExternalLink ? (
                                <ExternalNavLink
                                    $darkMode={
                                        themeContext.mode === 'dark'
                                            ? true
                                            : undefined
                                    }
                                    className="language-dependent-font"
                                    key={menuitem.entity.title}
                                    onClick={handleMenuItemClick}
                                    target={
                                        menuitem.entity.fieldOpenNewTab &&
                                        '_blank'
                                    }
                                    href={`${
                                        prefix === '/en' &&
                                        !menuitem.entity.fieldOpenNewTab
                                            ? '/en'
                                            : ''
                                    }${menuitem.entity.fieldPath}`}
                                    rel="noopener noreferrer"
                                >
                                    {menuitem.entity.title}
                                </ExternalNavLink>
                            ) : (
                                <NavLink
                                    $darkMode={
                                        themeContext.mode === 'dark'
                                            ? true
                                            : undefined
                                    }
                                    className="language-dependent-font"
                                    key={menuitem.entity.title}
                                    onClick={handleMenuItemClick}
                                    target={
                                        menuitem.entity.fieldOpenNewTab &&
                                        '_blank'
                                    }
                                    to={`${
                                        prefix === '/en' &&
                                        !menuitem.entity.fieldOpenNewTab
                                            ? '/en'
                                            : ''
                                    }${menuitem.entity.fieldPath}`}
                                >
                                    {menuitem.entity.title}
                                </NavLink>
                            )
                        })}
                        {themeContext.preheader ? (
                            <Breakpoint customQuery="(max-width: 768px)">
                                <>
                                    {pageContext?.langSwitch === true ? (
                                        <LanguageSwitchMenu
                                            pathname={pathname}
                                            translations={translations}
                                            pageContext={pageContext}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    <AccessibilityMenu
                                        translations={translations}
                                        pageContext={pageContext}
                                    />
                                </>
                            </Breakpoint>
                        ) : (
                            <>
                                {pageContext?.langSwitch === true ? (
                                    <LanguageSwitchMenu
                                        pathname={pathname}
                                        translations={translations}
                                        pageContext={pageContext}
                                    />
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </Nav>
                </NavMenuWrap>
            </NavInner>
            {productmenu}
        </StickyHeader>
    )
}
