import styled from 'styled-components'
import { fontSizes } from '../../../Theme'

const { h5Size } = fontSizes

export const TextContainer = styled.div`
    flex-direction: column;
    height: fit-content;
    padding: 2rem 1rem 3rem 1rem;
    order: 1;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: flex-start;
        display: flex;
        padding: 0 0.5rem 5rem 2rem;
        margin-right: 1rem;
        height: auto;
        justify-content: space-between;
        order: 0;
        gap: calc(5vh + 2rem);
    }
`

export const TextSubContainer = styled.div``

export const LogoDescription = styled.p`
    letter-spacing: 0.5px;
    line-height: inherit;
    font-size: inherit;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: ${h5Size};
    }
`
export const LogosWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.8rem;
    flex-wrap: wrap;
`
export const Logo = styled.img`
    min-height: 7rem;
    min-width: 7rem;
`

export const ImageContainer = styled.div`
    display: flex;
    order: 0;
    padding: 2rem;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    min-width: 100%;
    background: ${(props) => props.background};
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-color: transparent;
    background-size: contain;
    @media (min-width: ${({ theme }) => theme.tabletXL}) {
        display: block;
        order: 1;
        padding: 1rem;
        min-height: 100%;
        background-position-y: top;
        background-position-x: center;
    }
`

export const MobileImageContainer = styled.div`
    background: radial-gradient(
        44.29% 44.29% at 37.6% 55.71%,
        rgba(247, 247, 247, 0) 23.18%,
        rgba(123, 161, 206, 0.125) 95.05%
    );
    mix-blend-mode: normal;
    @media (min-width: ${({ theme }) => theme.tablet}) {
        background: transparent;
    }
`
